export const speakers = [
  {
    name: "Dinesh Sharma",
    title: "Managing Director & CEO",
    company: "Consumer Bank - Middle East Citi. UAE",
    image: "dinesh.jpg",
    companyImage: "citibank.jpg",
    link: "",
  },
  {
    name: "Ihab Ismail",
    title: "Chief Analytics Officer",
    company: "Abu Dhabi Islamic Bank, UAE",
    image: "ihab.png",
    companyImage: "adib.jpg",
    link: "",
  },
  {
    name: "Dr. Zerak Saleh",
    title: "Chief Operating Officer Finance",
    company: "Saudi British Bank, Saudi Arabia",
    image: "zerak.jpg",
    companyImage: "SABB.jpeg",
    link: "",
  },
  {
    name: "Caroline Bertrand",
    title: "Chief Marketing, Digital and Customer Experience Officer",
    company: "AXA Gulf",
    image: "caroline.jpg",
    link: "",
    text: `Caroline Bertrand began her career in 2000 at AXA Belgium and has held several roles in Communication, Operational and Strategic Marketing. She has also worked at AXA GIE in Paris as Head of Customer Experience and as Global Head of Corporate Brand and Brand Experience, working with multiple teams across several geographies. Caroline joined AXA Mexico in 2014, where she has held different roles including Head of Retail Segment and Corporate Responsibility, Head of Marketing and Communication and Director of Communication, Marketing and E-commerce at AXA Mexico. Caroline joined AXA Gulf in August 2019 as Chief Marketing, Digital and Customer Experience Officer. Caroline holds a degree in Political and Social Science from the University of Antwerp in Belgium and a Global Executive MBA from the IE Business School. `,
    companyImage: "axa.png",
  },

  {
    name: "Tamas Fodor",
    title: "Member of the Board, Retail Banking",
    company: "Sberbank, Hungary",
    image: "fodor.jpg",
    companyImage: "sberbank.jpg",
    link: "https://www.linkedin.com/in/tam%C3%A1s-fodor-a46a4946/?originalSubdomain=hu",
  },
  {
    name: "Radu Topliceanu",
    title: "Deputy CEO and Head, Retail Banking",
    company: "BRD – Groupe Société Générale",
    image: "radu.jpg",
    companyImage: "brd.png",
    link: "https://www.linkedin.com/in/radutopliceanu/?originalSubdomain=ro",
  },
  {
    name: "Jenny Winther",
    title: "Secretary General",
    company: "Nordic Payments Council, Sweden",
    image: "jenny.jpg",
    companyImage: "npc.png",
    link: "https://www.linkedin.com/in/jenny-winther-npc/",
  },
  {
    name: "Antonios Vourakis",
    title: "Chief Digital and Retail Officer",
    company: "Pancreta Bank S A, Greece",
    image: "Antonios.jpg",
    companyImage: "pancreta.png",
    link: "https://www.linkedin.com/in/antonios-vourakis-7639249/?originalSubdomain=gr",
  },
  {
    name: "Dr. Lance Mambondiani",
    title: "Managing Director & CEO",
    company: "BancABC Zimbabwe",
    image: "lance.jpg",
    companyImage: "bancABC.jpg",
    link: "https://www.linkedin.com/in/lance-mambondiani-32452055/",
  },

  {
    name: "Sanjay Rughani",
    title: "Chief Executive Officer",
    company: "Standard Chartered Bank Tanzania",
    image: "sanjay.jpg",
    companyImage: "sc.jpg",
    link: "https://www.linkedin.com/in/sanjayrughani/?originalSubdomain=tz",
  },
  {
    name: "Abeer Khedr",
    title: "Information Security Director",
    company: "National Bank of Egypt",
    image: "abeed.jpg",
    companyImage: "nbe.jpeg",
    link: "https://www.linkedin.com/in/abeerkhedr/",
  },
  {
    name: "Varghese Thambi",
    title: "Managing Director",
    company: "Diamond Trust Bank Uganda Ltd",
    image: "Varghese.jpg",
    companyImage: "dtb.png",
    link: "https://www.linkedin.com/in/varghese-thambi-16191243/",
  },
  {
    name: "James Scott",
    title: "CDO/Managing Director CIB DIGITAL",
    company: "Barclays Africa Group Limited, South Africa",
    image: "james.jpg",
    companyImage: "absa.png",
    link: "https://www.linkedin.com/in/james-scott-79a7594a/?originalSubdomain=za",
  },
  {
    name: "Thapelo Tsheole - MComm, MBA",
    title: "Chief Executive Officer",
    company: "Botswana Stock Exchange, Botswana",
    image: "Thapelo.jpg",
    companyImage: "bse.jpg",
    link: "https://www.linkedin.com/in/thapelo-tsheole-mcomm-mba-0749251a/",
  },
  {
    name: "Dr.Mark Nasila",
    title: "Chief Analytics Officer",
    company: "FNB South Africa, South Africa",
    image: "mark.jpg",
    companyImage: "fnb.png",
    link: "https://www.linkedin.com/in/dr-mark-nasila-294b171b/?originalSubdomain=za ",
  },
  {
    name: "George Menash",
    title: "Group Chief Information Officer",
    company: " Fidelity Bank Ghana",
    image: "menash.png",
    companyImage: "Fidelity.png",
    link: "https://www.linkedin.com/in/mensah-george-2a600030/?originalSubdomain=gh",
  },
  {
    name: "Musa Itopa Jimoh",
    title: "Director of Payement System Department",
    company: "Central Bank of Nigeria",
    image: "Musa.jpg",
    companyImage: "cbn.jpg",
  },
  {
    name: "Luis Teles",
    title: "Chief Executive Officer",
    company: "Standard Bank Angola",
    image: "luis.jpg",
    companyImage: "stanbic.png",
  },

  {
    name: "Evans Munyuki",
    title:
      "Award Winning Chief Digital Officer| Author Of 5 Star rated book: Up For Digital Transformation in the Gig Economy",
    company: "",
    image: "evans.jpg",
    companyImage: "",
  },
  {
    name: "Ndungu Thairu",
    title: "Deputy Managing Director",
    company: "Consolidated Bank Ghana Limited",
    image: "ndungu.jpg",
    companyImage: "consolidated.jpg",
  },
];

export const pastSpeakers = [];

export const whosponsor = [
  {
    id: 1,
    title: "Thought Leadership",
    description: [
      "As a sponsor at Finnosec, your company will gain second-to-none exposure to senior-level decisionmakers at the point in time that they are seeking solutions, information and systems for improving their Oranisations’ strategies. For a select few sponsors, you can build your reputation as a market leader through subject-specific presentations, workshops and focus days. This highly selective sponsorship allows your firm to establish tremendous capability and expertise in your specialty as well as highlight successful work completed with your clients.",
    ],
    location: "left",
    image: "1V78K5ha6gqvDjGfXMLVHzz1RWk-1Z9wY",
  },
  {
    id: 2,
    title: "PREMIUM BRANDING",
    description: [
      "We bring together buyers and suppliers for collaboration just like us 10x Meetings (Find out more - ask your show manager/director), networking and knowledge sharing. Branding is often a major initiative for our clients who are seeking to get the message out about their offerings. Build your company’s brand and visibility in front of senior decision-makers in order to get shortlisted. ",
      "As a sponsor, your company branding will appear alongside the global leaders associated with best practices in this field. Our dedicated marketing team will help you achieve your promotional aims in the months leading up to the conference. Exibex leverages multiple marketing channels including online, direct mail, email, press releases, MEDIA PARTNERShips and social media like how we have built within a year brand like Finnovex Global Series in Middle East and Africa.Find out more about team working on FinnoSec who successfully executed Finnovex Global Series www.finnovex.com",
    ],
    location: "right",
    image: "1A20v1KotoAqslM1EBgwRQx0uYnXTxjM9",
  },
  {
    id: 3,
    title: "FEATURED NETWORKING EVENTS / FACE TIME:",
    description: [
      "Networking and information sharing are two major aspects of our conferences and Exibex builds in many opportunities for sponsors to benefit from meeting industry leaders. Focused and high-level, our summit will provide you with the perfect environment to initiate new business relationships, identify upcoming opportunities and achieve face-to-face contact that overcrowded tradeshows cannot deliver. The exhibition area is designed to be the heart of the event – a place to network and share strategies with key decision makers. Sponsorship opportunities range from exhibition stands to sponsored lunches, cocktail receptions, gala dinners and a host of other branding opportunities.",
      "Additionally, Exibex offers a selection of sponsorship opportunities that enables our clients to increase their opportunity to develop new relationships during our events,we have successfully Introduced more than 100+ Banks,FI meetings with our sponsors find out more on our YouTube Channel.",
    ],
    location: "left",
    image: "1zuMuqVW9O2JXWjXGHSpay6T_kjU15OzJ",
  },
];

export const spotlights = [
  { title: "SECURITY for SMART Financial SERVICES", image: "1.png" },
  {
    title: "Enabling Secure Digital Transformation through Customer Centricity",
    image: "15.png",
  },
  { title: "Creating Secure Connected Experiences", image: "10.png" },
  { title: "Governance and Regulatory Compliance: The Basics", image: "2.png" },
  { title: "Risk Management: Being Proactive", image: "3.png" },
  { title: "Identity and Access management", image: "4.png" },
  { title: "The Insider Threat: Tightening Internal Controls", image: "5.png" },
  {
    title: "Phishing, Fraud: Being Vigilant, Educating Customers",
    image: "6.png",
  },
  { title: "Social Engineering Threats", image: "7.png" },
  { title: "Physical Threats: Protecting Your Infrastructure", image: "8.png" },
  { title: "Incident detection, Response and Recovery", image: "9.png" },
  { title: "Role of AI in Security", image: "10.png" },
  { title: "IEM and Threat Intelligence", image: "11.png" },
  { title: "IoT, Application, Enterprise and Cloud Security", image: "12.png" },
  {
    title: "Vulnerability Management and Penetration Testing",
    image: "13.png",
  },
  { title: "Email, Web, Mobile and Endpoint Security", image: "14.png" },
  { title: "Fraud Prevention and Payment Security", image: "15.png" },
  { title: "Zero Day Exploits and Ransomware Mitigation", image: "16.png" },
];
export const sponsors = [
  {
    name: "expo",
    image: "expo2020.jpeg",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    link: "",
    grid: 3,
  },
  {
    name: "difc",
    image: "difc.jpeg",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    link: "",
    grid: 3,
  },
  {
    name: "EDB Bahrain",
    image: "edb.jpeg",
    link: "",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    grid: 3,
  },
  {
    name: "Central bank of Nigeria",
    image: "cbon.jpeg",
    link: "",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    grid: 3,
  },
  {
    name: "Microsoft",
    image: "microsoft.png",
    type: "LEAD SPONSOR",
    link: "https://www.microsoft.com/en-in/",
    grid: 3,
    text: "",
  },
  {
    name: "RAKBANK",
    image: "rakbank.png",
    type: "LEAD SPONSOR",
    link: "http://www.rakbank.ae/",
    grid: 3,
    a: 1,
    text: `RAKBANK, also known as The National Bank of Ras Al Khaimah (P.S.C), is one of the UAE’s most dynamic financial institutions. Founded in 1976, it underwent a major transformation in 2001 as it rebranded into RAKBANK and shifted its focus from purely corporate to retail and small business banking. In addition to offering a wide range of Personal Banking services, the Bank increased its lending in the traditional SME, Commercial, and corporate segment in recent years. The Bank also offers Islamic Banking solutions, via RAKislamic, throughout its 27 branches and its Telephone and Digital Banking channels. RAKBANK is a public joint stock company headquartered in the emirate of Ras Al Khaimah and listed on the Abu Dhabi Securities Exchange (ADX). For more information, please visit www.rakbank.ae or contact the Call Centre on +9714 213 0000. Alternatively, you can connect with RAKBANK via twitter.com/rakbanklive;linkedin.com/company/rakbank; facebook.com/rakbank andinstagram.com/rakbank    `,
  },
  {
    name: "Smartstream",
    image: "smartstream.png",
    type: "PLATINUM SPONSORS",
    link: "https://www.smartstream-stp.com/",
    grid: 3,
    text: "SmartStream is a recognised leader in financial transaction management solutions that enables firms to improve operational control, reduce costs, build new revenue streams, mitigate risk and comply accurately with regulations. By helping its customers through their transformative digital strategies, SmartStream provides a range of solutions for the transaction lifecycle with artificial intelligence and machine learning technologies embedded – which can also be deployed in the cloud or as managed services. As a result, more than 2,000 clients—including 70 of the world’s top 100 banks, rely on SmartStream Transaction Lifecycle Management (TLM®) solutions to deliver greater efficiency to their operations.",
  },
  {
    name: "Skiply",
    image: "skiply.png",
    type: "PLATINUM SPONSORS",
    link: "https://skiply.ae/",
    grid: 3,
    text: ``,
  },

  //   Recorded Future
  //    Freshworks
  // Darktrace
  //  ManageEngine

  // Fireeye
  // Smart Message
  {
    name: "newgen",
    image: "newgen.jpeg",
    link: "",
    type: "PLATINUM SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "oracle",
    image: "oracle.jpeg",
    link: "",
    type: "STRATEGIC DIGITAL SPONSOR",
    text: false,
    grid: 3,
  },
  {
    name: "netcore",
    image: "netcore.jpeg",
    link: "",
    type: "MARTECH SPONSOR",
    text: false,
    grid: 3,
  },
  {
    name: "Network International Payment Solutions",
    image: "network.jpg",
    type: "GOLD SPONSORS",
    text: `Network International is the leading enabler of digital commerce across the Middle East and Africa region, the world’s most underpenetrated payments markets. We are the only pan-regional provider of digital payments solutions at scale with presence across the entire payments value chain.
    We provide a full suite of technology-enabled payments solutions to merchants and financial institutions delivered through our integrated omni-channel technology platforms, as well as N-GeniusTM, our proprietary state-of-the-art payments operating platform.
    In 2020, we processed $33 billion in total processed volumes (TPV) for more than 80,000 merchants and processed 758 million issuer transactions on 16 million cards for over 200 financial institutions.
    `,
    link: "https://www.network.ae/en",
    grid: 3,
  },
  {
    name: "temenos",
    image: "temenos.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "Smartmessage",
    image: "smartmessage.png",
    type: "GOLD SPONSORS",
    text: `SmartMessage is the omni-channel marketing and customer experience platform that helps leading brands confidently scale communications over millions of customers in real-time. SmartMessage enables the world’s leading brands connect with their customers and deliver real-time relevance in omni-channel experiences.
    Throughout our journey, we’ve explored the challenges that disabled our customers from realizing their full potential and the barriers that complicated their move towards digital transformation. And we’ve come up with a comprehensive suite of products and solutions to overcome these challenges. SmartMessage empowers businesses to deliver seamless omni-channel experiences and build long lasting relationships by providing a reliable, scalable marketing platform.
    SmartMessage still stands true to its founding vision and now, proudly serves more than 2,000 customers – from startups to the most prominent financial institutions across 3 continents with 100+ employees. In the FSI industry, SmartMessage has a strong penetration serving the pioneers of the Middle East, East Europe and Turkey including Saudi National Bank, NBD, Riyad Bank, İsbank, Albaraka and Garanti BBVA Romania.
    SmartMessage also has strong partnership relations with leading companies in technology and telecommunications. The company is a highly recognized Microsoft Gold Partner and a Microsoft Cloud Solution Provider (CSP)
    `,
    link: "https://www.smartmessage.com/",
    grid: 3,
  },
  {
    name: "abaka",
    image: "abaka.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "consdata",
    image: "consdata.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "backbase",
    image: "backbase.png",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "fss",
    image: "fss.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "comarch",
    image: "comarch.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "comviva",
    image: "comviva.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "daon",
    image: "daon.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "onespan",
    image: "onespan.jpeg",
    link: "",
    type: "GOLD SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "avaya",
    image: "avaya.jpeg",
    link: "",
    type: "SILVER SPONSORS",
    text: false,
    grid: 3,
  },

  {
    name: "piotech",
    image: "piotech.jpeg",
    link: "",
    type: "SILVER SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "tripwire",
    image: "tripwire.jpeg",
    link: "",
    type: "SILVER SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "simba",
    image: "simba.jpeg",
    link: "",
    type: "SILVER SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "payoneer",
    image: "payoneer.jpeg",
    link: "",
    type: "SILVER SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "infobip",
    image: "infobip.jpeg",
    link: "",
    type: "SILVER SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "Confluent",
    image: "confluent.jpg",
    type: "SILVER SPONSORS",
    text: `Confluent is pioneering a fundamentally new category of data infrastructure focused on data in motion.  Our cloud-native offering is the foundational platform for data in motion --- designed to be the intelligent connective tissue enabling real-time data, from multiple sources, to constantly stream across the organization.  With Confluent, our customers can meet the new business imperative of delivering rich, digital customer experiences and real-time business operations. Our mission is to set data in motion so any organization can innovate and win in a digital-first world.
    `,
    link: "https://www.confluent.io/",
    grid: 3,
  },
  {
    name: "Appway",
    image: "appway.png",
    type: "SILVER SPONSORS",
    text: `Appway takes care of your complexity so you can take care of your clients.  
    That's why 10 of the top 25 wealth managers have chosen Appway to efficiently onboard and continuously optimize the client lifecycle management of over 1 million relationships each year – from individuals to complex entities.  
    All Appway offerings leverage the know-how and best practices gathered from hundreds of implementations worldwide. With Appway's modular and configurable solutions, wealth businesses achieve rapid time to market, solving the "build or buy" dilemma.   
    Appway solutions cover the entire wealth client lifecycle, from onboarding and KYC to regulatory review and account maintenance – in full compliance for even the most complex client interactions – often resulting in 90% faster onboarding times, 5% incremental customer acquisition, and a 10% increase in margin per customer.   
    Appway's intelligent orchestration connects the systems, the data, and the people beyond organizational boundaries to boost productivity and enable the high-touch services that define exceptional customer experiences and client relationships.   
    Appway experts will solve your CLM challenges of today and position you for success in the ever-shifting financial services landscape of tomorrow.
    `,
    link: "https://www.appway.com/",
    grid: 3,
  },
  {
    name: "IDS Fintech",
    image: "ids.png",
    type: "SILVER SPONSORS",
    text: `IDS Fintech was established in 2019 as a spin-off from Integrated Digital Systems (IDS) which provided financial solutions since 1991 and capital market solutions since 2005. IDS Fintech focuses on wealth management and trading solutions including modeling, benchmarking, rebalancing, index pricing, risk calculators and ad-hoc compliance tools.  
    IDS Fintech aims to cater to the needs of Investment Banks, Funds, Private Banks, Family Offices and Qualified Brokerage Firms, offering them innovative software solutions for efficient management of their core operations and customer services.
    Our vision is to improve the world of investment by making our software solutions available to small, medium, and large investment banks and companies.  
    Our mission is to help our clients achieve maximum growth through effective and affordable automated solutions. This can only be achieved through innovative software which covers the different aspects of the trading and wealth management business taking into consideration the regulations set by Capital Markets Authority (CMA) and other governing bodies.
    `,
    link: "https://www.ids-fintech.com/",
    grid: 3,
  },
  {
    name: "Creatio",
    image: "creatio.png",
    type: "SILVER SPONSORS",
    text: `Creatio is a global software company providing a leading low-code platform for process management and CRM. The company has been highly recognized as a market leader by key industry analysts. Its intelligent products accelerate sales, marketing, service and operations for mid-size and large enterprises. Together with hundreds of partners.
    Creatio operates in 110 countries worldwide. The mission of Creatio is to help companies ACCELERATE!
    `,
    link: "https://www.creatio.com/",
    grid: 3,
  },
  {
    name: "Smart",
    image: "smart.png",
    type: "BRONZE SPONSORS",
    text: `Smart is a global savings and investments technology platform provider. Its mission is to transform retirement, savings and financial well-being around the world.

    Smart partners with governments and financial institutions (including insurers, asset managers, banks, financial advisers) to deliver retirement savings and income solutions that are digital, bespoke and cost efficient. 
    
    In addition to the UK, Smart is operating in the USA, Europe, Australia and the Middle East with close to a million savers entrusting over £1.8 billion in assets on the platform.  
    
    Smart supports its clients with a 550 strong global team and saw 160% growth in assets on its technology platform in 2020.
    
    Chrysalis Investments joins Legal & General, J.P. Morgan, the Link Group, Barclays and Natixis Investment Managers, the strategic investors to date in Smart.`,
    link: "",
    grid: 3,
  },
  {
    name: "Zentity",
    image: "zentity.png",
    type: "BRONZE SPONSORS",
    link: "https://zentity.com/",
    grid: 3,
    text: "",
  },
  {
    name: "Freshworks",
    image: "freshworks.png",
    type: "BRONZE SPONSORS",
    link: "https://www.freshworks.com/",
    grid: 3,
    text: "",
  },
  {
    name: "Sybrin",
    image: "sybrin.png",
    type: "BRONZE SPONSORS",
    link: "https://nitro.sybrin.com/",
    grid: 3,
    text: "",
  },
  {
    name: "infrasoft",
    image: "infrasoft.jpeg",
    link: "",
    type: "BRONZE SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "coforge",
    image: "coforge.jpeg",
    link: "",
    type: "BRONZE SPONSORS",
    text: false,
    grid: 3,
  },
  {
    name: "Igologic ",
    image: "igologic.jpg",
    type: "NETWORKING PARTNERS",
    text: ``,
    link: "http://www.igologic.pl/en/",
    grid: 3,
  },
  {
    name: "UFG ",
    image: "ufg.png",
    type: "NETWORKING PARTNERS",
    text: ``,
    link: "https://www.upforjobs.com/",
    grid: 3,
  },
  {
    name: "Faloos ",
    image: "faloos.jpg",
    type: "NETWORKING PARTNERS",
    text: ``,
    link: "https://www.faloosapp.com/",
    grid: 3,
  },
  {
    name: "tcs",
    image: "tcs.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "pwc",
    image: "pwc.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "ScVentures",
    image: "scventures.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "vacuumlabs",
    image: "vacuumlabs.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "neosoft",
    image: "neosoft.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "hyperpay",
    image: "hyperpay.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "rubrik",
    image: "rubrik.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "turbonomic",
    image: "turbonomic.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "yethi",
    image: "yethi.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "neosoft",
    image: "neosoft.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "bankserv",
    image: "bankserv.jpeg",
    link: "",
    type: "NETWORKING PARTNERS",
    text: false,
    grid: 3,
  },

  {
    name: "minafintech",
    image: "minafintech.jpeg",
    link: "",
    type: "STRATEGIC INDUSTRY PARTNER",
    text: false,
    grid: 3,
  },
  {
    name: "fintechgalaxy",
    image: "fintechgalaxy.jpeg",
    link: "",
    type: "COMMUNITY PARTNER",
    text: false,
    grid: 3,
  },
  {
    name: "afn",
    image: "afn.jpeg",
    link: "",
    type: "SUPPORTING ASSOCIATIONS",
    text: false,
    grid: 3,
  },
  {
    name: "eppan",
    image: "eppan.jpeg",
    link: "",
    type: "SUPPORTING ASSOCIATIONS",
    text: false,
    grid: 3,
  },
  {
    name: "caia",
    image: "caia.jpeg",
    link: "",
    type: "SUPPORTING ASSOCIATIONS",
    text: false,
    grid: 3,
  },
  {
    name: "blockchain",
    image: "blockchain.jpeg",
    link: "",
    type: "SUPPORTING ASSOCIATIONS",
    text: false,
    grid: 3,
  },
  {
    name: "smefinance",
    image: "sme.jpg",
    link: "https://www.smefinanceforum.org/",
    type: "SUPPORTING ASSOCIATIONS",
    text: false,
    grid: 3,
  },
  // {
  //   name: "The Power 50",
  //   image: "fintechpower.jpeg",
  //   link: "https://www.thepower50.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Cryptonewz",
  //   image: "cryptonewz.jpeg",
  //   link: "https://www.cryptonewsz.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "International Business Magazine",
  //   image: "ibm.jpeg",
  //   link: "https://www.intlbm.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "BeinCrypto",
  //   image: "beincrypto.jpeg",
  //   link: "https://www.beincrypto.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Cointelegraph",
  //   image: "ct.jpeg",
  //   link: "https://www.cointelegraph.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "Fintechna",
  //   image: "fintechna.jpeg",
  //   link: "https://www.fintechna.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "The Fintech Times",
  //   image: "fintechtimes.jpeg",
  //   link: "https://www.thefintechtimes.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "News Affinity",
  //   image: "NewsAffinity.jpeg",
  //   link: "https://www.newsaffinity.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Silicon India",
  //   image: "silicon.jpeg",
  //   link: "https://www.siliconindia.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Financial IT",
  //   image: "financialit.jpeg",
  //   link: "https://www.financialit.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Findbiometrics",
  //   image: "find.jpeg",
  //   link: "https://www.findbiometrics.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Mobile ID World",
  //   image: "mobile.jpeg",
  //   link: "https://www.mobileidworld.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Coin Republic",
  //   image: "coinrepublic.png",
  //   link: "https://www.thecoinrepublic.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "holland",
  //   image: "holland.png",
  //   link: "https://hollandfintech.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "innovationtech.png",
  //   image: "innovationtech.png",
  //   link: "https://innotechtoday.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "residentialtech",
  //   image: "residentialtech.png",
  //   link: "https://restechtoday.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "koinpost",
  //   image: "koinpost.png",
  //   link: "https://koinpost.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "financialnigeria",
  //   image: "financialnigeria.png",
  //   link: "http://www.financialnigeria.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "PCN Capital",
  //   image: "pcn.png",
  //   link: "https://www.pcn.com",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "Oxford Business Group",
  //   image: "oxford.jpeg",
  //   link: "https://oxfordbusinessgroup.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "arabnet",
  //   image: "arabnet.jpeg",
  //   link: "https://arabnet.me/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "Industry Events",
  //   image: "ie.jpg",
  //   link: "http://www.industryevents.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "startupnews",
  //   image: "startupnews.png",
  //   link: "https://www.startupnews.fyi/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },

  // {
  //   name: "security",
  //   image: "security.png",
  //   link: "https://www.securitymiddleeastmag.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "digitalscouting",
  //   image: "digitalscouting.png",
  //   link: "https://www.digitalscouting.de/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "kriptomeda",
  //   image: "kriptomeda.jpg",
  //   link: "https://www.kriptomeda.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Security & Fire Africa",
  //   image: "sfa.jpg",
  //   link: "https://securityafricamagazine.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "fintechfutures",
  //   image: "fintechfutures.jpeg",
  //   link: "https://www.fintechfutures.com/us/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "sinofy",
  //   image: "sinofy.png",
  //   link: "https://sinofy.group/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "coinquora",
  //   image: "coinquora.png",
  //   link: "https://coinquora.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "paypers",
  //   image: "paypers.jpg",
  //   link: "https://thepaypers.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "aroundb",
  //   image: "aroundb.png",
  //   link: "https://aroundb.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "regtech",
  //   image: "regtech.png",
  //   link: "https://regtechafrica.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "listing.help",
  //   image: "listing.png",
  //   link: "https://listing.help/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "EMA",
  //   image: "ema.png",
  //   link: "https://www.e-ma.org/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "Brussels Global Review",
  //   image: "brussels.png",
  //   link: "https://brusselsglobalreview.com/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  
  // {
  //   name: "fintechnews",
  //   image: "fintechnews.png",
  //   link: "https://fintechnews.africa/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
  // {
  //   name: "YourTarget",
  //   image: "yourTraget.png",
  //   link: "https://www.yourtarget.ch/en/",
  //   type: "MEDIA PARTNERS",
  //   text: false,
  //   grid: 3,
  // },
];

export const govsponsors = [];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const live = [
  {
    id: 31,
    link: "Indranil-Bandyopadhyay",
    description: "Speaker Interview- Indranil-Bandyopadhyay",
    content:
      "The people in the respective silos need to be compensated on the bases of effective organization wide data consumption.",
    image: "31.png",
  },
  {
    id: 30,
    link: "Agrippa-Mugwagwa",
    description: "Speaker Interview- Agrippa Mugwagwa",
    content:
      "A 360- or 720-degree view on a customer or issue is not possible with disparate data sets.",
    image: "30.png",
  },
  {
    id: 29,
    link: "Adeoluwa-Akomolafe",
    description: "Speaker Interview- Adeoluwa Akomolafe",
    content:
      "Fraud monitoring tools these days are AI and Machine Learning (ML) based.",
    image: "29.png",
  },

  {
    id: 28,
    link: "Olamide-Adeosun",
    description: "Speaker Interview- Olamide Adeosun",
    content:
      "Data analytics has a huge role to play in synchronising the enterprise system as this provides businesses insights required to make more predictive decision.",
    image: "28.png",
  },

  {
    id: 27,
    link: "Dr-Philip-Oti–Mensah",
    description: "Speaker Interview- Dr. Philip Oti – Mensah",
    content: "Technology adoption by the target market has had mixed outcomes.",
    image: "27.png",
  },
  {
    id: 26,
    link: "Ndungu-Thairu",
    description: "Speaker Interview- Ndungu Thairu",
    content:
      "Digital transformation is less of a digital problem than it is a transformation problem",
    image: "26.png",
  },
  {
    id: 25,
    link: "Babatunde-Obrimah",
    description: "Speaker Interview- Dr. Babatunde Obrimah",
    content:
      "Regulations are not in themselves bad, financial institutions have to be proactive to reposition itself as regulators introduce new regulations.",
    image: "25.png",
  },
  {
    id: 24,
    link: "Jamal-Anouti",
    description: "Speaker Interview- Jamal Anouti",
    content:
      "The main goal behind improving the overall customer experience is mostly to create customer loyalty",
    image: "24.png",
  },
  {
    id: 23,
    link: "Olivier-Crespin",
    description: "Speaker Interview- Olivier Crespin",
    content:
      "It is much easier for banks to take technology to incrementally advance forwards",
    image: "23.png",
  },
  {
    id: 22,
    link: "Jocelyne-Chahwan",
    description: "Speaker Interview- Jocelyne Chahwan",
    content:
      "The main goal should be the continuous improvement of all the processes that impact the customer journey across all channels.",
    image: "22.png",
  },
  {
    id: 21,
    link: "Ihab-Ismail",
    description: "Speaker Interview- Ihab-Ismail",
    content: "Creating a well-rounded customer 360 view is essential",
    image: "21.png",
  },
  {
    id: 20,
    link: "Kumaran-Selvarajalu",
    description: "Speaker Interview- Kumaran Selvarajalu",
    content:
      "This is a complex question as banking is not a simple commercial function of the market.",
    image: "20.png",
  },
  {
    id: 19,
    link: "Dr-Bongani-Andy-Mabaso",
    description: "Speaker Interview- Dr. Bongani Andy Mabaso",
    content:
      "Clients now expect digital interactions to be smoother, more seamless, and even delightful.",
    image: "19.png",
  },
  {
    id: 18,
    link: "Munyaradzi-Kavhu",
    description: "Speaker Interview- Munyaradzi-Kavhu",
    content:
      "Cyber risks remain an inevitable concern in the provision of digital financial innovations.",
    image: "18.png",
  },
  {
    id: 17,
    link: "Rod-Poole",
    description: "Speaker Interview- Rod Pool",
    content: "An ideal Agile Bank is one which is ‘future-ready’ ",
    image: "17.png",
  },
  {
    id: 16,
    link: "George-Mulamula",
    description: "Speaker Interview- George Mulamula",
    content:
      "Innovation strategies are being developed in a modular fashion with teams working on-line from home and physically in shifts within the office.",
    image: "16.png",
  },
  {
    id: 15,
    link: "Peter-Rossiter",
    description: "Speaker Interview- Peter Rossiter",
    content:
      "I’d describe an Agile Bank as one that is ready for the unexpected and can adapt quickly.",
    image: "15.png",
  },
  {
    id: 14,
    link: "Aieti-Kukava",
    description: "Speaker Interview- Aieti Kukava",
    content:
      "Buzzwords like AI, Big Data, Fintech, Blockchain, Open banking, Robotic Advisors, Debranching Cybersecurity etc all are worthy to be explored in different contexts.",
    image: "14.png",
  },
  {
    id: 13,
    link: "Kerim-Alain-Bertrand",
    description: "Speaker Interview- Kerim Alain Bertrand",
    content:
      "The purpose should definitely not be to save the day but to build a healthy future.",
    image: "13.jpeg",
  },
  {
    id: 12,
    link: "Antonios-Vourakis",
    description: "Speaker Interview- Antonios Vourakis",
    content:
      "Balancing compliance and customer experience does not necessarily mean that an organization will have to compromise on either one or the other.",
    image: "12.jpeg",
  },
  {
    id: 11,
    link: "Alvin-Alicevic",
    description: "Speaker Interview- Alvin Alicevic",
    content:
      "The “New normal“ has sped up the process of changes within the Banking industry, challenging the existing business models",
    image: "11.jpeg",
  },
  {
    id: 10,
    link: "Alfonso-Ayuso",
    description: "Speaker Interview- Alfonso Ayuso",
    content:
      "The battle will be fought in the design of services that meet the needs of customers regardless of the product that is finally used to transport it.",
    image: "10.jpeg",
  },
  {
    id: 9,
    link: "Stephane-Malrait-ING-Bank-United-Kingdom",
    description: "Speaker Interview- Stephane Malrait",
    content:
      " Our bank market activity was already digital with some products more automated than others. ",
    image: "9.png",
  },
  {
    id: 8,
    link: "Dr-LANCE-MAMBONDIANI-Managing-Director-CEO-BancABC-Zimbabwe",
    description:
      "DR. LANCE MAMBONDIANI  Managing Director & CEO BancABC Zimbabwe",
    content:
      "The greatest emerging opportunity is created by the increase in digital infrastructure and the cultural change around the use of technology",
    image: "8.jpg",
  },
  {
    id: 7,
    link: "Dr-Christian-Ayiku-Board-Member-Executive-Finance-Director-Novacom-Group",
    description:
      "Dr Christian Ayiku Board Member/ Executive Finance Director, Novacom Group",
    content:
      "The role of the CXO is that of an innovator, that seeks to adapt new customer norms with new business models, resulting in business efficiency and excellence.      ",
    image: "7.jpg",
  },
  {
    id: 6,
    link: "Chresta-C-Kaluba-Chief-Risk-Officer-Atlas-Mara-Zambia",
    description: "CHRESTA C. KALUBA Chief Risk Officer Atlas Mara Zambia",
    content:
      "The greatest emerging opportunity is created by the increase in digital infrastructure and the cultural change around the use of technology",
    image: "6.jpg",
  },
  {
    id: 5,
    link: "Rohit-Dhawan-Chief-Information-Officer-Bank-of-Kigali",
    description: "Rohit Dhawan, Chief Information Officer Bank of Kigali",
    content:
      "Transformation of a bank today is not digital, but the medium through which the transformation is preferred to be delivered is Digital",
    image: "5.jpg",
  },
  {
    id: 1,
    link: "Is-banking-security-in-Africa-holding-fintech-back",
    description: "Is banking security in Africa holding fintech back?",
    content:
      "New figures released by the SABRIC, revealed a huge spike in credit card fraud, up an eyewatering 18% year-on-year",
    image: "1.jpg",
  },
  {
    id: 2,
    link: "Will-Biometric-Technology-Take-Over-Banking-Security",
    description: "Will Biometric Technology Take Over Banking Security?",
    content:
      "If you haven’t heard the news, more banks are adopting biometric technology for customer authentication to increase security",
    image: "2.jpg",
  },
  {
    id: 3,
    link: "Africa-is-leaving-itself-dangerously-exposed-to-cyber-attacks",
    description:
      "Africa is leaving itself dangerously exposed to cyber attacks",
    content:
      "In their rush to embrace the digital future, African entities are leaving themselves dangerously exposed to cyber attacks",
    image: "3.jpg",
  },
];
