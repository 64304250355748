import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

function Sponsor({ sponsors }) {
	let pageHeader = React.createRef();
	const [collapse, setCollapse] = React.useState([0, false]);
	return (
		<div
			style={{
				backgroundColor: '#000',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				zIndex: 5,
			}}
			className="section py-3"
			data-parallax={true}
			ref={pageHeader}
		>
			<Container>
				<Row>
					{Object.keys(sponsors).map((sponsor, index) => (
						<React.Fragment key={index}>
							<Col lg={12}>
								<h2 className="text-center text-white text-bold pb-4 text-uppercase">
									<b>{sponsors[sponsor].length > 1 ? `${sponsor}s` : sponsor}</b>
								</h2>
							</Col>
							{sponsors[sponsor].map((data, i) => (
								<Col lg={6} className={'ml-auto mr-auto'} key={i}>
									<a target="_blank" rel="noopener noreferrer" href={data.link}>
										<Card
											style={{ borderRadius: 0 }}
											onMouseEnter={() => setCollapse([i, true])}
											onMouseLeave={() => setCollapse([i, false])}
										>
											{/* <img
												src={require(`assets/images/sponsor/${data.image}`)}
												alt={data.name}
												width="100%"
												style={{ maxWidth: '250px' }}
												className="m-auto"
											/> */}

											{collapse[0] === i && collapse[1] && data.text && (
												<CardBody>
													<p style={{ textTransform: 'none' }}>{data.text}</p>
												</CardBody>
											)}
										</Card>
									</a>
								</Col>
							))}
						</React.Fragment>
					))}
				</Row>
			</Container>
		</div>
	);
}

export default Sponsor;
