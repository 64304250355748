import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Kumaran Selvarajalu is the current Senior General Manager
                Payments at Banking Association, South Africa. He strongly
                believes in human and technological potentials that will allow
                some of the big problems to be solved.
                <br />
                <br />
                Kumaran is an established professional with a proven ability at
                thought leadership and generating knowledge within complex
                stakeholder groups. He has been in the banking industry for 20
                years, primarily but not limited to payment systems, rather a
                subject matter expert and head of regulation for FSR* on
                payments and payment systems.
                <br />
                <br />
                Mr. Kumaran Selvarajalu will be speaking virtually at Finnovex
                Southern Africa Summit. Please have a look at his view on our
                upcoming Leading Summit on Financial Services Innovation and
                Excellence held Virtually on 27th – 28th July 2021 .( Attend
                from anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As we are aware, Banking services and Financial Institutions
                will need to act fast to create a robust framework, to survive
                in the post COVID-19 era. What is your opinion on investing in a
                digital future and developing new technological solutions?
              </strong>
            </p>

            <p>
              There are multiple elements to this question that require
              unpacking, at a high macro [economic, social, political] level,
              the move to digitalization is seen as a move to greater prosperity
              for countries and their citizens inter alia because of the
              convenience, access to information, ability to make choices and
              importantly to stay at the same level of productivity as other
              regions, nations, citizens and companies.
              <br />
              <br />
              At the same time and at a more pragmatic and ground-level -
              nations, companies, and people (I believe the foundation of
              political and national stability is the family unit) must manage
              the constant transitions to these new states. These challenges
              will be met differently and will result in unavoidable gap
              increases. If these gaps are too wide however the very forces of
              progress may increase inequality and cause social and political
              instability.
              <br />
              <br />
              Then, in terms of value-creation and the interplay between public
              and private sector, I do not support the simple notions that
              innovation leads regulation or that the market innovates, and the
              state regulates. The modern world has shown us that simple
              business logic on investment is often flawed (Consider CelTel in
              Africa and that it created the mobile network in Africa which is
              stimulating growth in SME markets at a time when the much more
              powerful international TelCos saw Africa as simply a bad
              investment). Value-Creation suggests a much more complex framework
              of thinking on investment.
              <br />
              <br />I believe therefore that nations need to address these
              challenges with a policy prerogative that ensures the gap is well
              managed and that prosperity of all citizens must be the primary
              objective. Such policy prerogatives will ground the future
              companies and families. Investment will follow the direction of
              national policy. In establishing this policy prerogative national
              policy makers must place universal prosperity at the forefront and
              embrace the notion of value-creation.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-The power of partnerships should never be underestimated,
                especially in the financial services industry. How can the
                collaboration within FinTech’s and Traditional Banks be
                fostered?
              </strong>
            </p>
            <p>
              Agreed in principle, however… <br />
              <br />
              However again this must be looked at on a case-case basis.
              Consider Silicon Valley. The tip of the ice-berg that we see are
              the successful fintechs that develop an idea and either directly
              or through buyout acquire better funding. This is a great success
              story however the unseen element of the iceberg (inevitable with
              time by the way as innovations attract competitors and emulators)
              is that the vast majority of fintechs in that environ are not
              unicorns. For fintechs it seems good partnerships are a good way
              forward (to accommodate for capital and time issues (as I sense
              many fintechs are led by people who want to take on the next
              challenge and their challenge is time!).
              <br />
              <br />
              For large companies like banks it is important the partnership
              supports the underlying strategies and growth aspirations of the
              company rather than fragment it. If a large company can emulate or
              develop its own functionality based on its core competences, there
              is a powerful argument for not diluting IP and possibly equity but
              to go it alone. Often a company must make the call between good
              sustainable profits and broader but shallower market reach. I do
              not have a favourite but believe in either case agility is
              crucial.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Why do you think it is important to build and enforce a
                Cashless Economy where digital lending and online payments would
                be incorporated?
              </strong>
            </p>
            <p>
              As indicated the move to digitalisation is important but there are
              gaps that need to be addressed and I do not agree the simple move
              to a cashless economy, if this were possible in my lifetime is a
              simple step or a panacea to improve prosperity and efficiency of
              markets.
              <br />
              <br />I think it is important to separate lending and payments as
              the former is moving at a different rate and is largely driven by
              the same business objective (RoI) than payments which incorporates
              all money movements.
              <br />
              <br />I am not an expert on credit but observe that new funding
              and allocation models have arisen that are driven by internet
              access and communication. This is particularly useful in driving
              the growth of unsecured lending especially in such environments
              such as SMEs and SMMEs – value-creation (such as the Grameen Bank
              models) come to mind. I believe this migration from traditional
              lenders to more distributed models will continue and be driven by
              information and cheaply accessible AI to handle the scoring. I am
              reminded there is a large store of lazy capital in the world that,
              if safely leveraged, canadd a lot of value.
              <br />
              <br />
              Bearing in mind what I have said about the continued viability of
              the cash economy, not all payments will simply move to online
              quickly – This is desired but must be stimulated by policy and
              market collaboration. However, for the online economy in terms of
              payments the drivers seem to be towards essential utility i.e
              Standardised outcomes, consumer protection and marginal to zero
              profits. This means in my humble opinion that payments moves to
              integrate deeper into eCommerce and Data collection models (big
              data, small data, and importantly real-time
              transactional/behavioural data)
              <br />
              <br />A move from cash to digital does not offer immediate
              benefits to all players in the ecosystem and the evidence of the
              world attempting to eliminate cash supports this. Firstly,
              digitally advanced countries such as Sweden and New Zealand have
              indicated there is a threshold beyond which cash cannot be simply
              removed.
              <br />
              <br />
              Secondly, cash and digital payments are not substitutable at a
              practical level. CBDC and/or private crypto currencies may address
              this but do not have the footprint, legal foundation, or national
              collaboration needed (consider FX, the reserve currency
              requirements, foreign holdings etc..) to affect this in the
              short-term. That said Africa and developing countries may well be
              able to leap-frog if policy is directed appropriately.
              <br />
              <br />
              The cash economy/market is evolved and highly effective. Without
              the payer or payee changing their ecosystem interaction which is
              more possible in some markets than others – making cash sticky to
              those that need to be in cash markets, this is a difficult
              practical challenge.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-We are aware that automation simplifies core banking
                operations. However, does cyber threats hinder this in anyway?
              </strong>
            </p>
            <p>
              Cyber-threats challenge all digital connected environments.
              Typically, the human error or accommodating for humans is the
              greatest source of vulnerability. I would imagine that automation
              would significantly improve the defenses against cyber-attacks
              unless of course vulnerabilities are built into the algorithms.
              Even so, over time continuous improvement should see marked
              improvements in defense.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Do you think there are any potentials for transforming the
                overall KYC process in digital banking world?
              </strong>
            </p>
            <p>
              Certainly – There have been many advances in identification
              methods since the original ‘Physical world’, KYC processes were
              introduced. Already we are seeing banks open accounts using
              biometric verification on central stores, facial recognition, GPS
              positioning. Often these are layered adding behavioral
              characteristics to improve accuracy.
              <br />
              <br />
              It is important to stratify the need for KYC against national,
              societal and business objectives. On the positive side KYC can be
              an amazing tool to reduce criminal activities, build trust in
              digital society, improve customer service, and the applicability
              of products. If KYC is not geared to these priorities however it
              could hamper product rollout and inclusion, create a fear of being
              watched or exposed, increase practices such as rob-calling and
              generic treatment of customers.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. As it relates to cross border payments – what kind of risks
                would be involved with new technology?
              </strong>
            </p>
            <p>
              I am not sure there are any new risks or material changes in risk
              impact in this space. If one considers the current state of
              cross-border payments that are (a) too expensive (b) cumbersome
              (c) largely manually reported, I think the benefits of greater
              digitalisation, particularly such moves as the Singapore to
              Thailand mobile-mobile remittance initiative, far outweigh any
              risk issues.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. In what ways can banks deliver compelling value in this new
                financial ecosystem
              </strong>
            </p>
            <p>
              which banks have scaled to accommodate mobile and other
              mechanisms). Very open to discuss elements of this, and the
              overall thesis that banking and the homogenous bank is very
              adaptive directly and learn from what is happening in the market.
              Feel free to contact me.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. How can a customer’s relationship with the bank be
                effectively personalised by leveraging technology?
              </strong>
            </p>
            <p>
              This depends on what the bank vision is in terms of their customer
              understanding and personalisation strategies. Data will inform
              these positions but not establish the intent. Similarly, ICT will
              enable better communication and data access but design the vision
              of the company.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. As a leader within your company, would you like to highlight
                some recent challenges you have faced in the course of aligning
                innovation strategies to meet customer expectations?
              </strong>
            </p>
            <p>
              Mainly trying to make the different business units of the bank not
              to work vertically in silos but horizontally and in a
              collaborative manner to innovate and scale up products for our
              customers.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. In implementing evolutionary innovation strategies, what are
                the 3 major points to pay attention to while ensuring they meet
                customer expectations?
              </strong>
            </p>
            <p>
              a) Innovative products go to solve pain points of customers
              <br />
              b) Innovative strategies should align to medium and long-term
              plans of bank
              <br />
              c) Repetitive education be given to customers on bank products
              <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Being faced with disruption as a result of the pandemic, how
                is your organisation constantly implementing timely innovation
                strategies to meet customer expectations?
              </strong>
            </p>
            <p>
              Innovation strategies are being developed in a modular fashion
              with teams working on-line from home and physically in shifts
              within the office.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. As we are aware, Banking services and Financial Institutions
                will need to act fast to create a robust framework, to survive
                in the post COVID-19 era. What is your opinion on investing in a
                digital future and developing new technological solutions?
              </strong>
            </p>
            <p>
              The Post COVID-19 area will require digital tools and some work to
              be done on-line. So investment in developing technological
              solutions will have to be done, though in Tanzania this started
              even before the COVID-19 pandemic, as we have to reach the
              unbanked people in the rural areas.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
