import React from "react";
import { Container, Row, Col } from "reactstrap";
import { size } from "./common";

function TextGrid() {
  let pageHeader = React.createRef();
  return (
    <>
      <div
        style={{
          // backgroundImage: "url(" + require("assets/images/bg.png") + ")",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          background: "transparent",
          padding: size && "100px",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={6}>
              {size ? (
                <h2 className="text-white text-900-a">
                  Finnovex is a series of Summits that examines the Future of
                  Financial Services on how disruptive innovations are reshaping
                  the way they are structured, provisioned and consumed.
                </h2>
              ) : (
                <h3 className="text-white text-900-a">
                  Finnovex is a series of Summits that examines the Future of
                  Financial Services on how disruptive innovations are reshaping
                  the way they are structured, provisioned and consumed.
                </h3>
              )}
            </Col>
            <Col lg={6}>
              <h5 className="text-white text-500">
                <br />
                <br />
                Rapidly progressing technologies, evolving customer expectations
                and a shifting regulatory landscape are opening doors to
                disruptive innovation in financial services. From
                crypto-currencies to big data to peerto-peer lending, fintech
                innovations have seized the attention and imagination of
                customers, investors and organizations.
                <br />
                <br /> Finnovex as a series of Summits creates thought
                leadership on cutting-edge issues with long-term implications to
                the industry and lays foundation for multi-stakeholder dialogues
                that explore the potential of these innovations to transform the
                financial ecosystem as well as the risks and opportunities that
                could emerge from shifts in the way financial services are
                designed, delivered and consumed in the future.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
