import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { size } from "./common";

function SpeakerCard({ content }) {
  return (
    <div
      className="slide-container bg-white"
      style={{ minHeight: size ? "300px" : "250px" }}
    >
      <img
        src={require(`assets/images/${content.image}`)}
        width="100%"
        alt="about"
        className="image"
      />
      <div className="overlay">
        <div className="text-white">
          <h1 className="text-700-a mt-1 text-center">{content.name}</h1>

          <Container className="mt-3">
            <Row>
              {content.stats.map((s) => (
                <Col xs={6}>
                  {size ? (
                    <Card className="p-2 text-center">
                      <h6 className="text-700-a text-center mt-0">{s.name}</h6>
                      <h4 className="text-700-a text-center mt-1">
                        {s.value}%
                      </h4>
                    </Card>
                  ) : (
                    <Card className="text-center py-1 my-0">
                      {s.name}
                      <br />
                      {s.value}%
                    </Card>
                  )}
                </Col>
              ))}
            </Row>
            <Row>
              <Col lg={12} className="text-center">
                <Button className="btn-primary">Know More</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

function Editions() {
  return (
    <>
      <div
        style={{
          // backgroundImage: "url(" + require("assets/images/12240.jpg") + ")",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          background: "transparent",
          overflow: "hidden",
        }}
        className="section"
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="text-700-a text-white text-center mt-0"
                style={{ fontSize: size ? "10rem" : "4rem" }}
              >
                EDITIONS
              </h1>
            </Col>
            {content.map((c, i) => (
              <Col lg={6} className="mt-3" key={c.name}>
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={1 + i}
                >
                  <SpeakerCard content={c} />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Editions;

const content = [
  {
    name: "MIDDLE EAST",
    image: "dubai.gif",
    stats: [
      {
        name: "C-Suites",
        value: 41,
      },
      {
        name: "VPs/Directors/Heads",
        value: 30,
      },
      {
        name: "Managers/Sr. Managers",
        value: 19,
      },
      {
        name: "Others",
        value: 10,
      },
    ],
  },
  {
    name: "WEST AFRICA",
    image: "ghana.gif",
    stats: [
      {
        name: "C-Suites",
        value: 47,
      },
      {
        name: "VPs/Directors/Heads",
        value: 29,
      },
      {
        name: "Managers/Sr. Managers",
        value: 19,
      },
      {
        name: "Others",
        value: 5,
      },
    ],
  },
  {
    name: "SOUTHERN AFRICA",
    image: "johan.gif",
    stats: [
      {
        name: "C-Suites",
        value: 46,
      },
      {
        name: "VPs/Directors/Heads",
        value: 27,
      },
      {
        name: "Managers/Sr. Managers",
        value: 18,
      },
      {
        name: "Others",
        value: 9,
      },
    ],
  },
  {
    name: "EAST AFRICA",
    image: "ea.gif",
    stats: [
      {
        name: "C-Suites",
        value: 47,
      },
      {
        name: "VPs/Directors/Heads",
        value: 29,
      },
      {
        name: "Managers/Sr. Managers",
        value: 19,
      },
      {
        name: "Others",
        value: 5,
      },
    ],
  },
];
