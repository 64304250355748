import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Speaker Interview Creative Mr Jamal Anouti is an Electrical
                engineer and entrepreneur who is passionate about software
                development. He has co-founded Integrated Digital Systems - IDS
                Fintech and Vanrise Solutions serving several distinct market
                segments.
                <br />
                <br />
                He has established solid corporate structures and led for over
                30 years the engineering and development of several financial
                and business-oriented software applications in the field of ERP,
                e-government, e-banking, capital markets, document & information
                management, and telecom including OSS/BSS, Revenue Assurance,
                Fraud Detection, and Network Monitoring.
                <br />
                <br />
                His main work focuses on innovation and digital transformation
                introducing a wide scope of technical solutions and helping
                industries serve their clients more efficiently.
                <br />
                <br />
                Mr Jamal will be speaking live at Finnovex Middle East Summit.
                <br />
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 7th – 8th
                September 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- What do you suggest should be the main goal for improving the
                overall customer experience? What are the 4 most important
                components necessary for an outstanding customer experience
                journey?
              </strong>
            </p>

            <p>
              The main goal behind improving the overall customer experience is
              mostly to create customer loyalty. Banks need to foster a strong
              relationship with their clients and serve them better in order to
              keep them and improve their business with them. The 4 most
              important components necessary for an outstanding customer
              experience would have to be availability/reliability, speed,
              consistency, and personalization.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How can CX Leaders identify the need to upgrade the overall
                customer experience journey needs any form of improvement? How
                can AI based solutions be leveraged in collating and analysing
                data?
              </strong>
            </p>
            <p>
              Historically, customer experience was basically assessed through
              customer feedback. CX leaders had to constantly seek customer
              feedback through surveys and direct calls in order to be able to
              understand the problems customers may be facing when seeking
              services. With AI, data analytics is turning raw data into clear,
              meaningful, and actionable insights. AI does not only allow us to
              understand the customer, it actually enables CX leaders to predict
              the customer’s behavior and next action, the basis for improving
              customer experience. AI allows banks to accompany customers in
              their journey by protecting them, giving them advice, and
              introducing to them personalized services.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What steps can be considered in the moulding the building
                blocks for a successful wealth and asset management platform
                implementation?
              </strong>
            </p>
            <p>
              Implementing a wealth and asset management system is not an easy
              task. It requires substantial effort on several fronts and the
              full commitment of the organization as it involves almost every
              department in the financial institution. Knowing that the
              successful implementation of a wealth and asset management system
              is no different than any other large information system
              implementation in general, the following is a concise guideline
              for the essentials:
              <br />
              <br />
              <ul>
                <li>
                  Have a clear vision and measurable objectives with a phased
                  approach
                </li>
                <li>
                  Build an internal team of SMEs to accompany the implementation
                  and set regular meetings to follow up on progress and improve
                  plans
                </li>
                <li>
                  Give the full time needed for the implementation (trying to
                  shorten the implementation period usually delays the project)
                </li>
                <li>
                  Give special attention since the beginning of the project to
                  data migration from existing systems (a partial or unclean
                  data migration can be the cause of many problems)
                </li>
                <li>
                  Identify all possible integrations and agree on how to conduct
                  them (real time, scheduled, etc.)
                </li>
                <li>
                  Provide continuous training and support through a dedicated
                  onsite team (outsourced resources for implementation improves
                  chances of success)
                </li>
                <li>Provide babysitting for a considerable period of time</li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you highlight the relationship between Artificial
                intelligence and Customer Journey in new age of banking?
              </strong>
            </p>
            <p>
              Customers expect immediate answers and solutions. With AI,
              customers are better understood and are provided with personalized
              services that give them the solutions and answers they are
              seeking. Besides providing personalized services, AI can predict
              what customers want and provide them with the services and/or
              answers they would eventually require. In the new age of banking,
              AI provides customers with immediate answers, financial advisory,
              better security, smart fraud detection, hyper-personalized
              services, and more.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- An Analytics Infrastructure is very essential for every
                organisation, what top 3 characteristics must reflect this?
              </strong>
            </p>
            <p>
              An analytics infrastructure consists of - the services, tools,
              utilities, protocols, and applications needed to prepare (clean,
              structure, etc.), validate, model, and store data.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.As we adopt advanced technologies, we are aware it is always
                a case of Business intelligence vs. Data Analytics. In relation
                to the aforementioned, can you state 3 limited factors hindering
                technological advancement within the Middle East?
              </strong>
            </p>
            <p>
              Unlike the industrial revolution of the 20th century, the
              information revolution of this century has given the Middle East a
              fair chance to compete as it does not require enormous
              prerequisites. Moreover, the factors that are limiting
              technological advancement in the Middle East are pretty much the
              same all over the world with a difference in their magnitudes. The
              main limiting factors are mostly related to the lack of
              regulations, limited access to data, and poor vision in most
              Middle Eastern countries. Moreover, the wide adoption of AI
              technologies would have to be implemented on nation-wide scale for
              it to be properly benefited from. Saudi Arabia and the UAE are
              excellent examples of how data science, data analytics, and AI in
              general are being explored based on the set national vision (2030
              and 2040).
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Regardless of the Security challenges tackled in an open
                banking process, do you think the applications of AI, Machine
                Learning and other Immersive Technologies have aided in
                combating this effectively?
              </strong>
            </p>
            <p>
              AI has definitely contributed to better security in banking. AI
              has given the banking industry the tools to detect in real time
              fraudulent activities using machine learning. Not only AI can
              detect fraudulent and/or malicious behaviours for immediate
              action, but it provides predictive and prescriptive tools to
              increase security. Enhancing security and preserving the customer
              is a key objective in AI. This is normally handled through the
              identification of complex patterns that are not visible to people
              as they require thorough data analytics using enormous data from
              different sources. On top of detecting the existence of predefined
              patterns, AI gives us the ability to discover patterns that cannot
              be easily identified even by humans.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
