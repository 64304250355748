import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Dr. Babatunde Obrimah is currently responsible for the
                day-to-day management of the FinTech Association of Nigeria
                (FinTechNGR). He is an accomplished and multi-talented executive
                with substantial experience developing and leading banking,
                financial, project/program and administrative management.
                <br />
                <br />
                Dr. Obrimah will be speaking live at Finnovex West Africa
                Summit.
                <br />
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 26th –
                27th October 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q-Currently, how are financial institutions managing their
                fintech strategy to meet the expectations of Customers?
              </strong>
            </p>

            <p>
              Financial institutions are currently improving on technology
              applications to ensure customer satisfaction. Couple of financial
              institutions have upgraded their mobile apps to ensure they can
              provide more services.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-With the ever-changing regulatory landscape of the West
                African region, how can financial institutions stay on top of
                handling new and changing regulations?
              </strong>
            </p>
            <p>
              Regulations are not in themselves bad, financial institutions have
              to be proactive to reposition itself as regulators introduce new
              regulations.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-As we adapt to the current digital disruption, would we be
                seeing more banks partnering with digital wallets to overcome
                this disruption?
              </strong>
            </p>
            <p>
              We are going to see a lot of collaboration between banks and
              Fintechs to deliver financial services.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can loyalty points and alternative payment methods be
                integrated into mobile wallets in the future?
              </strong>
            </p>
            <p>
              Yes, this is possible, there are already initiatives in this
              direction to introduce loyalty points for use on digital
              platforms.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Within the West African region, how are Banking and Financial
                Institutions adapting to a Mobile-First Banking option? Have
                there been an increase in the adoption rate by customers?
              </strong>
            </p>
            <p>
              Delivery of financial services through mobile options is the only
              way to go. With introduction of USSD services, no doubt that there
              is an increase in the adoption.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.With the technological disparities in the West African
                region, how does this affect the onboarding of new customers by
                mobile banking agents?
              </strong>
            </p>
            <p>
              This will vary from country to country. Agent services are
              critical in rural areas unfortunately there are infrastructural
              challenges in these locations. As cost of providing
              infrastructures reduces, we are going to see more mobile banking
              agent activities.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Can you highlight 3 challenges that must be overcome in
                order to promote a cashless society?
              </strong>
            </p>
            <p>
              Increase literacy level <br />
              Increase telco infrastructures in rural areas <br />
              Reduce or eliminate fees on electronic transactions <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. The Summit kicks-off in October, what advice do you have to
                give to your other industry peers in order to bridge the
                financial inclusion gap?
              </strong>
            </p>
            <p>
              Bridging the financial inclusion gap requires a paradigm shift
              from the current approach of wanting people to open accounts.
              People are looking for value, we should be solving issues to
              on-board more people. A farming community will be concerned with
              snake bites, scorpion bites and injuries. Value to them will be
              proving a service that will take care of them if any of these
              occur and use that to on-board them into financial services.
              Agents should be empowered to sell other services like micro
              insurances (Health, life, accident), micro-pension, etc.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
