import React from "react";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import TextGrid from "components/TextGrid";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Editions from "components/Editions";

import Logos from "components/Logos";
import Speaker from "components/Speaker";
import Sponsor from "components/Sponsor";
import SponsorNew from "components/SponsorNew";
import Exibex from "components/Exibex";

import Live from "components/Live";
import Awards from "components/Awards";

// import Register from 'components/Register';

import { speakers, govsponsors, pastSpeakers, sponsors, live, sponsorsPage } from "./content";

import ScrollUpButton from "react-scroll-up-button";
import Stats from "components/Stats";

function Home() {
  return (
    <div id="outer-container">
      <Navbar page="home" />
      <ScrollUpButton />
      <section id="home" />
      <LandingPageHeader />

      <section id="about" />
      <div
        className="section"
        style={{
          backgroundImage: "url(" + require("assets/images/halfcircleglobe.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <TextGrid />
        <Editions />
      </div>
      <section id="Upcoming" />
      <Logos />
      <Stats />
      {/* <Event />
			<Spotlight spotlights={spotlights} /> */}
      <section id="speakers" />
      <Speaker speakers={speakers} pastSpeakers={pastSpeakers} />
      <section id="sponsors" />
      <Sponsor sponsors={sponsorsPage} />
      <SponsorNew />
      <section id="awards" />
      <Awards />
      <Exibex />
      <section id="finnovexLive" />
      <Live live={live.filter((l, i) => i < 3)} />
      <Footer />
    </div>
  );
}

export default Home;
