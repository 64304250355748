import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Olamide Adeosun is an experienced finance professional with over
                cumulative of 20 years cross sector experience within Nigeria
                and globally.
                <br />
                <br />
                Her skills portfolio includes - Financial Control, Business
                Process, Operational Excellence, Change Management and
                Leadership.
                <br />
                <br />
                Olamide Adeosun will be speaking live at Finnovex West Africa
                Summit.
                <br />
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 26th –
                27th October 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In your line of duty, would you say data analytics and cloud
                offers the opportunity to synchronise the overall enterprise
                system?
              </strong>
            </p>

            <p>
              Data analytics has a huge role to play in synchronising the
              enterprise system as this provides businesses insights required to
              make more predictive decision.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How are the operational and data silos across - risk, finance,
                regulatory and customer support to be broken-down?
              </strong>
            </p>
            <p>
              A robust ERP system which is linked to an analytics system will go
              a long way in breaking the silos of these functions as they are
              all able to easily access data real time and the data is deposited
              centrally thus reduce/avoid duplication and errors in reporting.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What changes need to be made in managing transformation
                around digital marketing that inspires engages and captivates
                the audience?
              </strong>
            </p>
            <p>
              It needs to be targeted using predictive analysis of customers
              linked to a CRM.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you highlight 5 innovation strategies to apply advanced
                analytics for integrated insights and visibility?
              </strong>
            </p>
            <p>
              Technology has to be the heart of any transformational advanced
              analytics strategy.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-An Analytics Infrastructure is very essential for every
                organisation, what top 3 characteristics must reflect this?
              </strong>
            </p>
            <p>
              Good/Clean accessible data, Data warehouse and a robust reporting
              tool to process the data mined.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. To your Industry peers looking forward to attending the
                summit, what do have to say to them to prepare them for their
                attendance?
              </strong>
            </p>
            <p>
              They should come expecting to hear great insights and interesting
              perspectives.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
