import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Dr. Bongani Andy Mabaso is the current Chief Information Officer
                at Standard Bank, South Africa.
                <br />
                <br />
                He strongly believes and loves the continent of Africa and is
                self-motivated to change the world at large.
                <br />
                <br />
                Dr. Bongani, is a seasoned change agent with a cumulative
                experience of over 12 years, which includes his past experience
                at Transnet Engineering. He is a passionate driver of digital
                change.
                <br />
                <br />
                Dr. Bongani Andy Mabaso will be speaking virtually at Finnovex
                Southern Africa Summit. Please have a look at his view on our
                upcoming Leading Summit on Financial Services Innovation and
                Excellence held Virtually on 27th – 28th July 2021 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As we are aware, Banking services and Financial Institutions
                will need to act fast to create a robust framework, to survive
                in the post COVID-19 era. What is your opinion on investing in a
                digital future and developing new technological solutions?
              </strong>
            </p>

            <p>
              COVID-19 has had the unforeseen impact of accelerating digital
              transformation for many businesses, but particularly in the
              financial services industry. Clients now expect digital
              interactions to be smoother, more seamless, and even delightful.
              Financial services industries will need to accelerate their
              transformation agendas responsibly (in light of revenues that were
              severely impacting in many cases by the pandemic) – and they need
              to do it quickly.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- How can the collaboration within FinTech’s and Traditional
                Banks be fostered?
              </strong>
            </p>
            <p>
              Innovation is often a highly networked phenomenon – the more ideas
              are free to flow from everyone and everywhere, including from
              fintech partners, preferably the one that can lead to a great deal
              of positive client impact. So, I think the innovation models that
              the banks use needs to be more open to allowing partners to engage
              in the process.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Why do you think it is important to build and enforce a
                Cashless Economy where digital lending and online payments would
                be incorporated?
              </strong>
            </p>
            <p>
              The reason to drive a cashless economy is widely documented and
              understood, so I won’t repeat that. What I can say is the
              opportunity is there, and we are seeing movements in this space
              across the industry.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-In the applications of AI, Machine Learning and other
                Immersive Technologies, can you state 3 limited factors within
                the Southern Africa?
              </strong>
            </p>
            <p>
              I will speak briefly on AI since that is a field, I am familiar
              with. Funny enough, I think the biggest challenge those financial
              institutions face when it comes to AI is not the models
              themselves, but the data to support various data science
              initiatives.
              <br />
              <br />
              Firstly, organisational data is not generally in a state where it
              can be easily exploited and used to drive personalisation and
              cross/up-sell. It tends to contain errors, lies in various siloed
              systems, and is controlled by various business owners who sit
              within product lines, thereby creating barriers for data
              scientists. This needs to change!
              <br />
              <br />
              Secondly, I’m not so sure that the typical organisational response
              to the problem above, you know, the centralisation of data into
              lakes and other such constructs, is necessarily the best answer
              for the problem. Centralising data in this way tends to come from
              people who believe it is an asset (it is rightfully so!). But we
              tend to want to horde something that is an asset. I think people
              should rather treat data as a product. Treating it this way means
              we tend to want to find ways to exploit it right now, and use it
              for the benefit of our clients and organisation. Hence, I think a
              combination of modern data strategy and business ethos of
              exploitation, as opposes to centralisation, will greatly drive the
              efforts to commercialise AI and data driven applications in the
              industry.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
