import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Alfonso Ayuso served as the Former Chief Innovation Officer -
                Banco Sabadell. He is an Exponential Coach & Trainer (ExO)
                <br />
                He has developed his professional career in several banking
                entities managing positions of responsibility in areas related
                to technology, financial markets, product development and
                relationship with clients, such as Treasury and Capital Markets,
                Private Banking or Research.
                <br />
                He holds a degree in Aeronautical Engineer from the Polytechnic
                University of Madrid and an MBA from the IE Business School.
                <br />
                Likewise, he is a very active member of Spanish sectorial
                working groups belonging to the Spanish Banking Association and
                acting as member of the board in several organizations like
                Alastria Blockchain Consortium, Redsys or Iberpay, among others.
                <br />
                MR Alfunso Ayuso will be speaking virtually at the Finnovex
                Europe Summit. Please have a look at his view on our upcoming
                Leading Summit on Financial Services Innovation and Excellence
                held Virtually on 24th – 25th May 2021 ( Attend from anywhere
                💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In your opinion, what do you think about the current Open
                Finance paradigm shift within the European region?
              </strong>
            </p>

            <p>
              We are at the beginning of a new playing field in which the
              scenario is definitely opened to the participation of large
              platforms that will have to react to the regulatory environment
              that lies ahead. According to the Digital Markets Act, business
              users and end users could port that data in real time effectively,
              such as for example through high quality application programming
              interfaces. We are directly entering the next phase of OpenData.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- As it relates to Financial data, can you identify the
                challenges that hinders the Open Finance process?
              </strong>
            </p>
            <p>
              Starting from the premise that data will be accessible in real
              time in a machine-readable format through APIs, the main
              challenges that the industry will face will be those related to
              accessing and processing data in a standardized way. The
              implementation of PSD2 has already surfaced the complexities to
              build an industry standard as it is not established by regulation
              or there is no single entity in charge of defining them. Important
              coordination will be required by the intervening parties or
              players who take the initiative in the process.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Based on your wealth of experience, can you pinpoint the
                opportunities available for building a more efficient and
                productive financial system within the European Region?
              </strong>
            </p>
            <p>
              In this new scenario, the relationship with the customer takes on
              a new dimension and the manufacture of products, which will become
              commodities, takes a back seat. The battle will be fought in the
              design of services that meet the needs of customers regardless of
              the product that is finally used to transport it. The coverage of
              the applications of some entities so that they can be used by
              non-clients is a sign that the path is beginning towards the
              commercialization of financial products on open platforms at
              scale.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Can you tell us the risks for the future of banks that can be
                as a result of the impact of the technological revolution and
                its far-reaching changes in the global economy and society at
                large?
              </strong>
            </p>
            <p>
              There are two different types of risks for the future of banks
              under the new Open Finance paradigm. One related to its own model
              and another related to the stability of the financial system. In
              the first place, banks will have to structure themselves to work
              as a platform or they will run the risk of becoming mere product
              manufacturers with no relation to the end customer. Secondly, most
              of the new models that are being developed under the PSD2
              regulatory framework are designed to provide transactional
              business models, especially in the payments sector, with lower
              regulatory burdens that can cause a transfer of funds from bank
              deposits, that finance the economy, to payment accounts that
              cannot be used to be borrowed, which could stress the current
              banking model itself.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What advice would you like to share with industry peers and
                banking professionals who are looking forward to attending this
                conference?
              </strong>
            </p>
            <p>
              Many times, I have answered this question and I always come to the
              same conclusion. Banking professionals have always thought
              linearly, with annual projections based on linear increments, but
              the current world of the fourth industrial revolution is unfolding
              exponentially and when the sector suffers the disruption that the
              combination of OpenFinance and OpenData will cause, those who do
              not have changed course of thinking from product thinking to
              necessity thinking will disappear from the scene.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
