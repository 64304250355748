// reactstrap components
import { Container, Col, Row } from "reactstrap";

import React, { useState, useEffect, useRef } from "react";
import { size } from "../common";

function LandingPageHeader() {
  // const navType = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        window.VANTA.GLOBE({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x3fd4ff,
          color2: 0x5b96d6,
          backgroundColor: "#010322",
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <div
      className="page-header"
      style={{
        backgroundColor:
          "linear-gradient(300deg, rgba(11,36,133,1) 0%, rgba(1,3,34,1) 100%)",
      }}
      ref={myRef}
    >
      <Container>
        <Row>
          <Col md={7} xs={12} className="">
            <img
              src={require(`assets/images/finnovex.png`)}
              width={"100%"}
              alt="Finnovex"
            />
            {size ? (
              <>
                <h2
                  style={{
                    color: "#fff",
                    fontSize: "30px",
                  }}
                  className="text-600-a mt-2"
                >
                  THE LEADING SUMMIT ON <br /> Financial Services Innovation and
                  Excellence
                </h2>
              </>
            ) : (
              <>
                <br />
                <h4
                  style={{
                    color: "#fff",
                    // fontSize: '30px',
                  }}
                  className="text-600-a"
                >
                  THE LEADING SUMMIT ON <br /> Financial Services Innovation and
                  Excellence
                </h4>
              </>
            )}
            <br />
            <br />
            {/* <Button
              className="px-3 py-3"
              color="primary"
              size="lg"
              href="/request-brochure"
            >
              <h5 className="m-0 text-700" style={{ color: "#000" }}>
                Download Brochure
              </h5>
            </Button>{" "}
            <Button
              className="px-3 py-3"
              color="primary"
              size="lg"
              href="/sponsors"
            >
              <h5 className="m-0 text-700" style={{ color: "#000" }}>
                Become a Sponsor
              </h5>
            </Button> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandingPageHeader;
