import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Since becoming the Chief Executive Officer of the new entity in
                May 2018, Olivier Crespin has focused on leveraging hybrid
                digital banking strategies to establish Zand as the first fully
                digital bank in the region to combine both retail and corporate
                banking.
                <br />
                <br />
                He is committed to establishing state-of-the-art infrastructure
                and processes coupled with analytics frameworks and big data
                environments to deliver unique customer journeys and to provide
                innovative, effective financial solutions that help simplify
                business and lives, addressing the needs of both retail and
                corporate customers. Crespin brings over 20 years of extensive
                expertise and leadership roles in digital banking and FinTech
                across Latin America, the USA and Asia. He recognises the need
                for a customer-centric approach to humanise the banking
                industry.
                <br />
                <br />
                Please have a look at his 3view on our upcoming Leading Summit
                on Financial Services Innovation and Excellence held from 7th –
                8th September 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- Is there a vital correlation between innovation and
                entrepreneurship in driving technological advancement in the way
                the overall banking system works?
              </strong>
            </p>

            <p>
              Yes. It is much easier for banks to take technology to
              incrementally advance forwards, as they evolve the systems and
              processes, they know and understand. External innovation,
              disruption, and the entrepreneurship that comes with that can
              drive step-change, though.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Based on your level of industry expertise, how can you
                describe the current systematic evolution
              </strong>
            </p>
            <p>
              Almost all banks have recognized the need to modernize – in their
              skills, their systems, and their processes. The approaches to that
              differ greatly though, both in what each bank is trying to evolve
              into, and their route to getting there. There is broad acceptance
              of the migration of customers to more digital channels, and the
              need to build capabilities to maintain those relationships even if
              the face-to-face interaction is lost in that migration.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-As we adapt to the current digital disruption, would you
                consider the need to build and enforce a Cashless Economy where
                digital lending and online payments would be incorporated as
                paramount?
              </strong>
            </p>
            <p>
              Holistic digital financial services can enable and power much
              broader services and industries. However, rather than enforcing it
              on customers, the goal should be to make them compelling enough
              that customers want to adopt them. If they don’t, to understand
              the challenges faced by customers and the solutions cash was
              providing.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you share 3 essential tips with new entrants within the
                retail, wholesale and institutional sector in relation to
                developing and promoting cross border payments?
              </strong>
            </p>
            <p>
              <ol>
                <li>Solve a real customer problem</li>
                <li>
                  Understand all the different people involved in the chain
                </li>
                <li>Make it easy, relevant, best rate, instant</li>
              </ol>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Within the GCC region, how are Banking and Financial
                Institutions adapting to digital disruption despite some
                hinderance on governmental policies? Do you foresee a potential
                for more policy changes and flexibility in the future regardless
                of security challenges?
              </strong>
            </p>
            <p>
              Government policies are actually really helping with the digital
              disruption; providing frameworks and consistency to operate
              within. Ensuring cross-border alignment would further promote
              broader, integrated financial services, of course.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.As cross-border payment models have the tendency to be
                inefficient because there is not one single universal global
                payment system. Can you highlight 3 challenges that must be
                overcome in order to improve the cross-border payment processes?
              </strong>
            </p>
            <p>
              End-to-end transactional certainty has always been a challenge;
              being sure that a transaction is going to complete at the
              recipient’s end before it is ‘committed to’ from the sender. This
              is an incredibly difficult challenge given all the steps involved,
              such as screening the transaction by multiple parties, etc.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Can you talk about some of the current trends shaping the
                future of the cross-border payment landscape within the GCC
                region?
              </strong>
            </p>
            <p>
              There’s the new GCC payment initiatives which is very encouraging.
              Blockchain based solutions are obviously a big one
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. We are aware that some domestic infrastructures are not
                designed to handle cross-border payments, what advice do you
                give to your other industry peers in other to bridge the
                infrastructure gap?
              </strong>
            </p>
            <p>
              There are always going to be ‘infrastructure gaps’ outside the
              bank; these may be gaps in speed, efficiency, resilience,
              functionality, or just misalignment between multiple partners. And
              this landscape constantly changes. Industry players need to build
              modern, flexible architectures to help efficiently manage this
              everchanging ecosystem, minimizing the overheads.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
