import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Event() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="p-4 text-center">
              <a href="/" className="">
                <img
                  src={require('assets/images/logo/exibex.gif')}
                  className="mx-auto mb-3"
                  alt="exibex"
                  width="100%"
                  style={{ maxWidth: '270px' }}
                />
              </a>
              <h5 className="text-400 text-justify mt-0">
                As market leaders in knowledge-based conferences, managed events and business
                consulting, Exibex provides innovative and engaging networking platforms to create
                and engage C level communities for global corporations. Our platforms enable
                acceleration of both individual, and organisational performance, to benefit global
                business leaders. We comprehend the challenges organisations face in this volatile
                and uncertain market, so we look to help your core revenue generation teams by
                building a complete 360-degree package tailored to your business strategies, goals
                and needs.
              </h5>
            </Col>
            {/* <Col lg={2}/> */}
            {/* <Col lg={2} className="align-self-center">
              <div
                id="surly-badge"
                className="surly__id_153785638 surly-badge_white-blue"
                onclick="if(event.target.nodeName.toLowerCase() != 'a' && event.target.parentElement.nodeName.toLowerCase() != 'a') {window.open('https://sur.ly/i/finnovex.com/'); return 0;}"
              >
                <div className="surly-badge__header">
                  <h3 className="surly-badge__header-title">Brilliantly</h3>
                  <p className="surly-badge__header-text">SAFE!</p>
                </div>
                <div className="surly-badge__tag">
                  <a className="surly-badge__tag-text" href="https://sur.ly/i/finnovex.com/">
                    finnovex.com{' '}
                  </a>
                </div>
                <div className="surly-badge__footer">
                  <h3 className="surly-badge__footer-title">Content & Links</h3>
                  <p className="surly-badge__footer-text">
                    Verified by{' '}
                    <a href="https://sur.ly" className="surly-badge__footer-link">
                      Sur.ly
                    </a>
                  </p>
                </div>
                <div className="surly-badge__date">2022</div>
              </div>{' '}
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Event;
