import React from "react";
import { Container, Row, Col } from "reactstrap";

function Sponsor({ sponsors, govsponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  return (
    <div
      style={{
        background: "fff",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 5,
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="text-center text-dark text-700 pb-4">
              <b>SERIES SPONSORS & PARTNERS</b>
            </h2>
          </Col>
        </Row>
        <Row>
          {Object.keys(sponsors).map((sponsor, index) => (
            <React.Fragment key={index}>
              <Col lg={sponsors[sponsor][0].a ? 6 : 12} className="mb-4">
                <h3
                  className="text-center text-muted pb-1"
                  style={{ fontSize: "30px" }}
                >
                  <b>{sponsor}</b>
                </h3>
                <Container fluid>
                  <Row>
                    {sponsors[sponsor].map((data, i) => (
                      <Col lg={data.grid} className={"mx-auto align-self-center mb-2"} key={i}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={data.link}
                        >
                          <img
                            src={require(`assets/images/sponsor/${data.image}`)}
                            className="mx-auto"
                            alt={data.name}
                            width="100%"
                          />
                        </a>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </div>
  );
}




export default Sponsor;
