import React from 'react';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

import WhoAttend from 'components/WhoAttend';

// import { speakers } from './content';

import ScrollUpButton from 'react-scroll-up-button';

function Delegates(props) {
	return (
		<div id="outer-container">
			<Navbar />
			<ScrollUpButton />
			<ProfilePageHeader title="DELEGATES" />
			<WhoAttend {...props} />
			<Footer />
		</div>
	);
}

export default Delegates;
