import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavLink,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  const [navbarColor, setNavbarColor] = React.useState("");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(true);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setNavbarColor("");
        setScroll(false);
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("");
        setScroll(true);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={classnames("fixed-top border-bottom", navbarColor)}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              target="_blank"
              className="p-0"
              tag={Link}
            >
              {!(home && scroll) && (
                <img
                  src={require(`assets/images/finnovexdark.png`)}
                  className=""
                  width="180px"
                  style={{ maxWidth: "250px" }}
                  alt="logo"
                />
              )}
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-400 text-decoration-none"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-400"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-400"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle nav caret className="navbar-item-custom">
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem href={i.link} key={i.link}>
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
              {/* <NavItem>
								<NavLink
									to="#register"
									tag={Link}
									className="btn btn-primary rounded-0 navbar-item-custom text-400 text-white"
									style={{ opacity: '1' }}
								>
									<span style={{ color: '#fff' }}>Contact Us</span>
								</NavLink>
							</NavItem> */}
              <NavItem>
                <NavLink
                  to="/register"
                  tag={Link}
                  className="btn btn-primary rounded-0 navbar-item-custom text-400 text-white px-4"
                  style={{ opacity: "1" }}
                >
                  <span style={{ color: "#fff" }}>Register</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;
const menu = [
  { name: "HOME", link: "home", page: "/", items: undefined, grid: 1 },
  { name: "ABOUT", link: "about", page: "/", items: undefined, grid: 1 },
  { name: "SPEAKERS", link: "speakers", page: "/", items: undefined, grid: 1 },
  {
    name: "#FINNOVEXLIVE",
    link: "finnovexLive",
    page: "/",
    items: undefined,
    grid: 1,
  },
  { name: "PARTNERS", link: "sponsors", page: "/", items: undefined, grid: 1 },

  // { name: "CONTACT", link: "register", page: null, items: undefined, grid: 1 },
];
