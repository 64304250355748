import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Rod Poole is the Chief Change and Business Transformation
                Officer, Standard Bank Group, South Africa!
                <br /> <br />
                Rod joined the Standard Bank group in 𝟏𝟗𝟖𝟒 and has held various
                senior executive roles within the bank including head of
                Marketing and Communication, head of Human Capital of Standard
                Bank Plc based in London, head of Human Capital CIB and chief of
                staff CIB, where he was responsible for Human Capital, Marketing
                and Strategy.
                <br /> <br />
                Rod Poole will be speaking virtually at Finnovex Europe Summit.
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held Virtually on
                24th – 25th May 2021 ( Attend from anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In relation to your current role, how would you describe an
                ideal Agile Bank that is ready for the “New Normal”?
              </strong>
            </p>

            <p>
              IAn ideal Agile Bank is one which is ‘future-ready’ – by putting
              the client at the centre of everything that they do – and
              delivering superior client experience through physical, digital,
              platform and ecosystems. At Standard Bank, we are using MIT’s
              two-by-two matrix to talk about the process of becoming
              future-ready, where the vertical axis measures client experience
              and the horizontal axis measures operational efficiency, and I
              think this model best describes the state of an agile,
              future-ready bank.
              <br /> <br />
              An Agile Bank would be score well on both client experience and
              operational efficiency, with the result that they are in the
              top-right quadrant of the matrix. Companies or banks in the top
              right quadrant are described as being future ready.
              <br /> <br />
              They are both innovative and low cost; provide great customer
              experience; are modular and agile; engage in dynamic partnering;
              treat data as a strategic asset; and are ecosystems ready.
              Strategically – Focused on solving client needs across a broad
              range of ecosystems (i.e beyond just serving their financial
              needs), working together with key strategic partners to offer
              products and services beyond the Bank’s own services - Technically
              – Modular, API-based technology architecture, in the cloud and
              data-driven and Organisationally – connected, simple processes,
              empowered decision making in teams, embracing new skills sets and
              ways of working and embracing a culture of innovation
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What are the vital elements that organisations need to put in
                place to ensure they are ever ready for an unpredictable world?
              </strong>
            </p>
            <p>
              It is vital that companies are ambidextrous, focusing
              simultaneously on improving client experience and operational
              efficiency, in order to become a future-ready, an ever-ready
              organisation, able to handle an unpredictable world. Again, to use
              MIT’s model, they describe four organisational explosions that
              companies need to adopt in order to become future-ready.
              <br /> <br />
              These are:
              <ul>
                <li>
                  Decision rights – getting the right people to lead key
                  decisions
                </li>
                <li>
                  New ways of working – changing the culture, to be agile,
                  evidence-based and always thinking of the customers’
                  perspective
                </li>
                <li>
                  Organisational surgery – removing organisational complexity
                </li>
                <li>
                  Platform mindset – connecting organisational silos, through
                  the use of data sharing, APIs, and reducing legacy spaghetti
                  infrastructure systems
                </li>
                <li>
                  Keeping client-centricity at the heart of your organisation
                  and being nimble to react to client need as they change
                </li>
                <li>
                  Establishing a lean and scalable technology architecture to
                  enable the business
                </li>
                <li>
                  Creating a culture and employee experience that stimulates
                  innovation and encourages decision-making at all levels of the
                  organization
                </li>
                <li>Agile teaming structures (multi-disciplinary teams)</li>
                <li>
                  Diversity of employees (i.e skills, background, demographics)
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- The world as we know has become more technologically
                advanced, how can Banks’s cushion the effects of cyber threats
                while leveraging technologies to meet the needs of their
                customers?
              </strong>
            </p>
            <p>
              Cybercrime is one of our number one concerns, and we dedicate an
              enormous amount of resources to combatting cybercrime. We
              regularly communicate with our clients, keeping them aware of the
              latest phishing tactics to obtain access to their accounts. Our
              staff have to complete compliance training to assist them to
              detect fraudulent communication which may allow criminals to
              access our systems. The group information and technology committee
              oversee how the group manages IT and the governance processes that
              are in place to ensure, among other things, effective
              cybersecurity and ongoing cyber resilience that together offer
              better client service and protection of their assets. We continue
              to mature the cybersecurity model to face the ongoing threat of
              attack against a digital bank. During the pandemic, we partnered
              with Microsoft and Pioneering Solutions Studio to deliver a
              learning programme on a number of basic computer skills, including
              cybersecurity, reaching 11,600 South Africans with 47% having
              completed the course by November 2020.Through the Covid-19
              pandemic, the board was concerned whether it would be possible to
              maintain the security of our information systems with such a large
              proportion of employees working away from the office. The answer
              was that we could. There were no material cybersecurity incidents
              in 2020.
              <br /> <br />
              Cyber threat risk management include:
              <br />
              <ul>
                <li>
                  Cyber risk assessment (e.g., security landscape, exposure,
                  gaps etc.) including simulated attacks (e.g. hackathons)
                </li>
                <li>
                  Create dedicated capacity/ teams that manage and monitor cyber
                  risks
                </li>
                <li>
                  Implement systems/ processes to limit the risk of attacks
                  (e.g. insurance, cloud vs. on-prem data storage, masking of
                  personal data)
                </li>
                <li>Employee training/ awareness campaign on cyber threats</li>
                <li>Client needs</li>
                <li>
                  Creating a single client view (leveraging data capabilities)
                </li>
                <li>Personalization</li>
                <li>Easy access onto the financial services platform</li>
                <li>
                  Lower barriers to access (e.g. online channels/ WhatsApp)
                </li>
                <li>
                  Better customer service (e.g. lower turnaround times and
                  better transparency around workflows, tailored products)
                </li>
                <li>
                  Automation (e.g. Straight through processing) and AI/ML for
                  faster and more accurate solutioning (e.g. immediate access to
                  loans)
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Within your organisation, can you highlight 3 key steps that
                have been taken to ensure you remain an Agile Bank?
              </strong>
            </p>
            <p>
              As mentioned above, we have been following the guidance provided
              by MIT’s model in terms of explosions – or steps that need to be
              taken to become future ready. First, we have implemented a
              framework for decision-making across the organisation, to ensure
              that decisions are taken by those closest to the client, and it is
              clear who is accountable for making decisions on what vs how, new
              customer offers, off-path spending etc.
              <br />
              <br />
              Second, we have been for some time being working on enabling new
              ways of working throughout the organisation – from adopting agile
              methodologies in the way we work, to experimenting with small
              cross-functional teams etc. This has enabled us to practice a
              “test and learn” approach to new ideas.
              <br />
              <br />
              Third, we recently changed our operating structure, moving away
              from our old monolithic business unit construct to a structure
              which will be less complex and less siloed and will provide all
              our clients with a comprehensive range of financial services and
              solutions in a seamless way, without expecting them to navigate
              our internal complexities.
              <br />
              <br />
              We are now primarily organised into three client segments, and
              each of these is equally supported by our Client Solutions
              business, by our Engineering infrastructure, and by a specialised
              Innovation capacity. The Client Solutions business will partner
              the three client segments and the Group as a whole. It will focus
              on delivering innovative and cost-effective client solutions which
              can be scaled across client segments and countries, or sold to
              third parties.
              <br />
              <br />
              The Engineering capability will bring together all the
              infrastructure we use to deliver the Group’s services. Their
              responsibilities include maintaining our physical infrastructure,
              running an efficient, stable, robust and secure technology stack,
              and providing comprehensive data management, analysis and
              monetisation capacities.
              <br />
              <br />
              Finally, a new capability known as Innovation to generate
              future-ready solutions and new business models more quickly and
              efficiently in the service of our clients. These benefits include:
              Reorganised around client segments – switching from business line
              to client segments to ensure we are truly client driven;
              Developing ecosystems to deliver beyond our traditional services
              to clients; Establishing a partnership model where we can both
              bring in services from partners to our clients, as well as
              channelling SBG products and services through 3rd party platforms.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Delegates are certainly getting ready to attend this
                conference, would you like to give any advice to them?
              </strong>
            </p>
            <p>
              <ul>
                <li>Become digital savvy!</li>
                <li>
                  Understand how digital will impact your company success in the
                  next decade and the organisational change required.
                </li>
                <li>
                  Develop an enterprise-wide understanding of technology such as
                  digital platforms, AI, big data, IoT, mobile and digital
                  processes that enable new business models, partnering,
                  improved customer experience, more efficient operations and
                  cyber risks.
                </li>
                <li>
                  Understand when to commit, when to experiment, when to
                  partner, and early indications of both success and challenges
                  with new initiatives operating at enterprise scale.
                </li>
                <li>
                  Consider technology early in strategy process, focusing on
                  business model then project risk.
                </li>
                <li>
                  Don’t just focus on competition and innovation in financial
                  services sector– focus on all sectors as industries converging
                  and so is technology across various sectors and industries
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.Would you like to share any personalized experiences within
                your organization that has been improved by use of data and
                advanced analytics?
              </strong>
            </p>
            <p>
              We have a few goods examples of this:
              <br />
              <ul>
                <li>
                  Our BizFlex loan offering to SMEs is one example of this.
                  Leveraging our existing relationship with a business, we can
                  proactively offer them a loan based on how they’ve been
                  interacting with us over the last few months. This loan is
                  made by tying the repayments to their income each month –so if
                  they earn less, their repayments are lowered to ensure they
                  feel comfortable as they continue to run their business i.e.
                  the bank is an understanding partner in their business, and
                  not just another removed party.
                </li>
                <li>
                  The Early Warning Financial Distress solution in our Wholesale
                  client segment (old CIB Digital) is a model which uses data
                  analytics to examine both internal and external sources of
                  data and determine if a client could potentially go into
                  credit distress over the next 3-6 month period. The team can
                  then proactively partner with the business to help them avoid
                  going into credit distress.
                </li>
                <li>
                  Trader Direct, which is live in Zambia and Lesotho, provides
                  the merchants, who typically don’t have the ability to go to
                  banks nor good sources of data for lenders to make credit
                  decisions, with point-of-sale devices (in partnership with
                  Nomanini). We are able to gather “health and wealth” data to
                  build profiles on the merchants – health meaning do they open
                  their store at the same time each morning, are they
                  consistently supplied with power etc; and wealth meaning what
                  value flows and volumes do they achieve in their business.
                  These profiles give us the opportunity to offer short-term
                  virtual stock advances to help the trader continue trading
                  without leaving their store to go to the bank for a loan.
                </li>
                <li>
                  Single client view: enabled advisors to provide more relevant/
                  value-adding solutions to clients
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.Can you highlight some of the negative and positive
                implications of COVID-19 on the BFSI industry in the Southern
                Africa region?
              </strong>
            </p>
            <p>
              Covid-19 has accelerated the shift to digital. For banks and other
              financial services companies who were slowly shifting towards
              becoming more digital, it has now become an imperative to move
              more quickly to change their business model. Those who move
              quicker will thrive, those who don’t pivot fast enough will be
              disintermediated and become a service in the back end.
              <br />
              Covid-19 has shown us that it is entirely possible to work more
              remotely and still effectively deliver to clients. Going forward,
              this will mean that our people will have more flexibility in the
              way in which they work, and it provides the opportunity for real
              estate savings.
              <br />
              <br />
              Positive:
              <ul>
                <li>
                  Opportunity to deepen client relationships and goodwill
                  (supporting them through hard times)
                </li>
                <li>
                  Increased digital adoption in the client base(e.g. online
                  banking/transacting)
                </li>
                <li>
                  Innovation in banking channels and new financial technology
                  (new channels, better digital CX)
                </li>
                <li>Event-driven business (restructures, M&A activity)</li>
                <li>
                  Increased use of modern tools of BFSI staff resulting in a
                  more efficient workforce
                </li>
                <li>
                  Easier access to global talent a (potentially) improved
                  employee value proposition (e.g. flexible hours,work from
                  home)
                </li>
                <li>
                  Potential reduction in fixed cost base (e.g. office space)
                </li>
              </ul>
              <br />
              Negatives:
              <br />
              <ul>
                <li>Credit risk/ default risk</li>
                <li>Lower interest rates</li>
                <li>
                  Less disposable money in circulation, low economic activity
                  (e.g. lay-offs, pay cuts etc.)
                </li>
                <li>Loose monetary policy (inflation implications)</li>
                <li>Cyber security/ risk</li>
                <li>Negative impact to revenues given economic stagnation</li>
                <li>Neutral (debatable)</li>
                <li>WFH considerations (e.g. burn-out, flexible hours)</li>
                <li>
                  Impact on diversity and inclusion (flexible hours and
                  voice-only interactions may have a strong positive influence
                  on women, especially in environments/ teams that have a much
                  larger male component)
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.The power of partnerships should never be underestimated,
                especially in the financial services industry. How can the
                collaboration within FinTech’s and Traditional Banks be
                fostered?
              </strong>
            </p>
            <p>
              Using ourselves as an example, we have invested in or partnered
              with fintech start-ups over a number of years through a small team
              whose job it is to look for and make strategic investments and
              alliances. We realise that we don’t have to build everything
              ourselves, and that partnerships create shared wins much more
              cost-effectively and efficiently, ultimately benefiting our
              clients, who get a solution, our partners, who achieve scale more
              quickly than would have been achieved otherwise, and ourselves.
              <br />
              This obviously does not form the bulk of our offerings and
              solutions – yet – but we believe it is an important part of our
              overall business. Developing partnerships based on each company’s
              strengths to leverage each other’s client base and expertise.
              <br />
              <br />
              Some of the ways to collaborate include
              <ul>
                <li>
                  Optimising legal structures to enable equity/ VC type
                  investments
                </li>
                <li>
                  Simplifying access to business, simplification of procurement
                  processes
                </li>
                <li>
                  Banking as a Service - Provide support, Access to capital/
                  SMEs, Access to data, Access to office space, Act as a
                  financial backer/ underwriter
                </li>
                <li>
                  Formal structures to introduce partners to the business
                  include - innovation awards, academies
                </li>
              </ul>
              <br />
              Opensource development practices and API-enabled, modular
              technologies that are attractive for partners to plug into a
              strong partner value proposition, including well-structured value
              sharing models. Branding and public messaging on sustainable
              business practices (E.g. a high BEE rating, low carbon footprint)
              – especially for large strategic partnerships even before the
              COVID-19 pandemic, as we have to reach the unbanked people in the
              rural areas.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
