import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col xs={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="align-self-center">
            <p>
              <strong>
                Dr. Lance Shingai Mambondiani is the CEO of BancABC Zimbabwe.
                Dr. Mambondiani is a renowned Digital Banking thought leader and
                seasoned banker. He has worked as an executive for regional and
                international financial institutions over the years. Named in
                the African Report Magazine’s Top List of Who is Who in African
                Finance and as 2019’s Most Innovative CEO by the CEO Africa
                Roundtable, Dr. Lance has won several leadership awards and is a
                much sought-after speaker at international conferences as a
                global expert on Fintech, digitization and transformation of
                banks. He was recently featured in the August 2020 Global
                Edition of the CEO Magazine for his contribution to bank
                innovation across Africa.
                <br />
                <br /> Dr. Lance will be speaking virtually at Finnovex East
                Africa Virtual Summit. Please have a look at his view on our
                upcoming The Leading Summit on Financial Services Innovation and
                Excellence to held Virtually on 9-10 February 2021 ( Attend from
                anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
          <br/>
          <br/>
            <p>
              <strong>
                Q- In post COVID-19 era, with the emergence and growth of
                FinTech and digitalization of banks what do you think would be
                potential opportunities of the banking industry?&nbsp;
              </strong>
            </p>

            <p>
              COVID-19 significantly impacted the whole world, it has certainly
              made us more conscious about hygiene and has also forced us to
              deliberate on our daily routines. The pandemic has opened
              tremendous opportunities in the banking sector to increase the
              pace of digitisation. Contactless payments and e-commerce gained
              significant ground during the lockdown period. The payments
              journal for example reported that 30% of consumers started using
              contactless payments and of those 30%, 70% will continue to use
              such payment methods even after the pandemic has died down. The
              deployment of these solutions in Africa has to be coupled with
              improvements in infrastructure and connectivity.&nbsp;
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Also, what would be concerns or challenges of the banking
                industry?
              </strong>
            </p>

            <p>
              Loan portfolio management is an area of major concern due to
              increased business vulnerabilities arising from the pandemic,
              sectors such as Tourism and Hospitality sector have taken a
              significant hit. Bailouts by Central Bank&rsquo;s have assisted in
              mitigating these effects but access to these funds and the
              eventual repayment terms for small businesses has been onerous in
              some instances. For banks that&rsquo;s a challenge because we want
              our SMEs to grow and this pandemic has resulted in a setback.{" "}
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What is the role of CXO (depending on speaker designation)
                from developing policies to developing infrastructure for
                Digital Transformation in the banking sector?
              </strong>
            </p>

            <p>
              A CEO is really a project sponsor and acts as the Chief
              Encouragement Officer or Chief Inspiration Officer in the digital
              transformation process. After allocating the right resources and
              prioritising projects, the CEO needs to encourage or inspire the
              team to the desired future state, this future state is understood
              by CEOs at a strategic level, taking into account the structural
              changes in operating, for example the migration to cloud
              banking.&nbsp;
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- According to you, who leads the way in change and
                transformation -Banks or FinTechs? Who inspires, who follows
                trends and who brings customer needs into perspective?&nbsp;
              </strong>
            </p>

            <p>
              As opposed to a competitive approach we&rsquo;ve witnessed a
              collaborative approach in which banking industry leaders are
              partnering with or investing in Fintechs. The leaders are those
              banks and fintech that understand the need for collaboration and
              play off of each other&rsquo;s strengths, the capital base of
              traditional banks and the dexterity and flexibility of Fintechs
              that are unencumbered by legacy systems.&nbsp;
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What steps would you take in your organization towards
                building a Cyber Resilient and Digitally Empowered
                Economy?&nbsp;
              </strong>
            </p>

            <p>
              The human element is incredibly important in this process. Unless
              one moves together and creates understanding within the workforce,
              these fanciful terms remain foreign to many people. To build such
              an organisation requires you to demonstrate that there are real
              benefits to being cyber resilient and digitally empowered. So,
              people first, the rest of the building blocks fall into place
              after that.&nbsp;
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- I believe you had a chance to go through our brochure and
                agenda, what are your thought about this conference? Which
                session are you looking forward to attend?&nbsp;
              </strong>
            </p>

            <p>
              The agenda of this conference clearly highlights the broader
              African agenda, that is to financially empower individuals through
              technology. Am I allowed to say I look forward to attending all of
              the sessions? If I had to had to pick one it would be the opening
              day panel on The Transforming Face of Banking in East Africa:
              Opportunities and Challenges.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What would you like to say to your industry peers and banking
                professionals who are looking forward to attend this conference?
              </strong>
            </p>
            <p>
              I would like to say that you either fear or you embrace change. To
              those attending this conference, thank you for choosing to embrace
              the future. Although I am a participant, I am certainly looking
              forward to hearing from the various industry experts featuring at
              Finnovex to discuss the latest trends and innovations in banking
              and the collaborative approach with Fintechs.{" "}
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
