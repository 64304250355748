import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Liveall from "components/Liveall";

import { live } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function News() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <section id="" />
      <ProfilePageHeader title="#FinnovexLive" />
      <Liveall live={live} />
      <Footer />
    </div>
  );
}

export default News;
