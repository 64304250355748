import React from 'react';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import SpeakerAll from 'components/SpeakerAll';

import { speakers } from './content';

import ScrollUpButton from 'react-scroll-up-button';

function Speakers() {
	return (
		<div id="outer-container">
			<Navbar />
			<ScrollUpButton />
			<ProfilePageHeader title="OUR SPEAKERS" />
			<SpeakerAll speakers={speakers} />
			<Footer />
		</div>
	);
}

export default Speakers;
