import React from "react";
import { Button, Container, Row, Col, Input, Form, Card, Alert, Spinner } from "reactstrap";

function Register({ title }) {
  const pageSize =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024
      ? "10rem"
      : "1rem";
  const [details, setDetails] = React.useState({
    type: "REGISTER",
    source: "FINNOVEXGLOBAL",
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    fetch("https://us-central1-finnosec.cloudfunctions.net/api/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setSuccess("Thank you for your request. We'll get back to you shortly.");
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  return (
    <div className="modal-register" style={{ paddingTop: pageSize }}>
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="text-center text-g text-700 pb-4">
              <b>{title}</b>
            </h2>
          </Col>
          <Col className="mx-auto" lg={6}>
            <Card
              className="card-register mx-auto"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom, #011122, #01162c, #001b37, #001f41, #00234c, #002654, #01285b, #042b63, #042e69, #053070, #063376, #07357d)",
              }}
            >
              <Container className="my-auto">
                <Form onSubmit={onSubmitForm} className="register-form">
                  <Row>
                    <Col lg={6}>
                      <label>First Name*</label>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="first_name"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <label>Last Name*</label>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="last_name"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={6}>
                      <label>Email*</label>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="email"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={6}>
                      <label>Phone*</label>
                      <Input
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={6}>
                      <label>Company*</label>
                      <Input
                        placeholder="Company"
                        type="text"
                        name="company"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <label>Job Title*</label>
                      <Input
                        placeholder="Job Title"
                        type="text"
                        name="job"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={6}>
                      <label>Country*</label>
                      <Input
                        placeholder="Country"
                        type="text"
                        name="country"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <label>Interested In Edition</label>
                      <Input
                        type="select"
                        name="interest"
                        id=""
                        onChange={onChangeDetails}
                        required
                      >
                        <option>Select</option>
                        <option>Finnosec East Africa</option>
                        <option>Finnovex Qatar</option>
                        <option>Finnosec Middle East</option>
                        <option>Finnovex Southern Africa</option>
                        <option>Finnovex West Africa</option>
                      </Input>
                    </Col>
                    <Col lg={12}>
                      <label>Where did you hear about us?</label>
                      <Input type="select" name="hear_about_from" id="" onChange={onChangeDetails}>
                        <option>Select</option>
                        <option>Email</option>
                        <option>LinkedIn</option>
                        <option>Twitter</option>
                        <option>Facebook</option>
                        <option>Instagram</option>
                        <option>News/Other Websites</option>
                        <option>Referral</option>
                      </Input>
                    </Col>
                    <Col lg={12}>
                      <label>Which other country/region would you like us to host Finnovex?</label>
                      <Input
                        placeholder="Country"
                        type="text"
                        name="host_country"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  </Row>

                  <Button
                    block
                    className="btn text-white text-700"
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                    {loading ? (
                      <span>
                        {" "}
                        <Spinner color="warning" size="sm" />
                      </span>
                    ) : (
                      ""
                    )}
                  </Button>
                </Form>
              </Container>
              {error ? (
                <Alert
                  color="danger"
                  isOpen={true}
                  fade={true}
                  className="text-center"
                  style={{ marginTop: "1rem" }}
                >
                  {error}
                </Alert>
              ) : (
                ""
              )}
              {success ? (
                <Alert
                  color="success"
                  isOpen={true}
                  fade={true}
                  className="text-center"
                  style={{ marginTop: "1rem" }}
                >
                  {success}
                </Alert>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
