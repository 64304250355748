import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Adeoluwa Akomolafe is the current Chief Information Security
                Officer & Assistant GM at Wema Bank Plc.
                <br />
                <br />
                His wealth of experience spans over 20 years cumulatively.
                <br />
                <br />
                Adeoluwa Akomolafe will be speaking live at Finnovex West Africa
                Summit.
                <br />
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 26th –
                27th October 2021.Visit{" "}
                <a href="https://wa.finnovex.com">wa.finovex.com</a> for more
                information.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- What approaches can Banks utilise in changing the outlook of
                cyber warfare within the West African Region?
              </strong>
            </p>

            <p>
              Cybersecurity strategies have to change from being reactive to
              proactive; we need to be able to in a way “anticipate” cyber
              breaches; this might sound far-fetched but due to Digital
              transformation, security solutions/Technology have also reacted.
              Defenses can be boosted by implementing intelligence-driven
              measures, such as the use of artificial intelligence (AI), already
              applied in strengthening authentication methods via biometric
              logins for multi-factor authentication (MFA). Fraud monitoring
              tools these days are AI and Machine Learning (ML) based. There is
              improved technology in the user behavioural monitoring space which
              makes it possible to proactively stop fraudulent transactions. We
              also need to be proactive in testing our cyber defenses via
              Vulnerability and penetration tests; this ensures we deal with
              weaknesses in our infrastructure before they are exploited by
              fraudsters.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-The “New Normal” has led to the adoption of the technological
                way of life, how can Cybersecurity consciousness be instilled
                among staff members?
              </strong>
            </p>
            <p>
              Training and awareness cannot be over emphasized and the best
              approach to this training is to bring it alive; what do I mean by
              this? We need to relate cybersecurity training to daily life
              activities; How do we secure social media accounts, how do we
              secure our home, cars, phones etc. When we are able to relate that
              to the work environment then staff members understand better and
              it becomes a part of them.
              <br />
              <br />
              When cyber security training/awareness gets too technical it loses
              the non-technical audience. Staff MUST be able to relate to the
              content and message. Continuous Training and awareness is the key.
              When you start a work-from-home system, your staff’s home becomes
              part of your enterprise and apart from the technology you will
              deploy to ensure security they must be aware of their
              responsibilities.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Can you highlight some evolving legal and regulatory context
                guiding the West African Fintech Space?
              </strong>
            </p>
            <p>
              From a Nigerian perspective one major legal and regulatory context
              in the last two years is the launch of the NDPR (Nigeria Data
              Protection Regulation), as the saying goes; Data is the new Oil
              and its essential organizations and their customers understand
              their responsibilities and limitations when it comes to handling
              data. This has created awareness around Data especially in the FI
              sector as compliance has been made mandatory.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Is there any specific training to stir up the awareness and
                adaptation to new threats that you would recommend to
                organisations?
              </strong>
            </p>
            <p>
              There are several training programs available to organizations, I
              will advise HR learning teams consult their subject matter experts
              (CISO, CIOs) internally that can advise on the best approach. I
              will not want to mention any specific training so I am not seeing
              as endorsing any as the preferred approach/option.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Bearing in mind that there is an inherent fear of people
                losing their jobs to “bots”, do you think automation is a more
                viable alternative to addressing the cyber skills shortage in
                the industry?
              </strong>
            </p>
            <p>
              A recent article by Kenna Security’s Chief Data Scientist, Michael
              Roytman, explains the issue perfectly. “We don’t have a workforce
              shortage problem,” he says. “What we have is an
              automation-in-the-wrong-place problem.” For e.g. in the
              vulnerability management space, he argues that risk-based,
              data-driven approaches can overcome any skills shortages that
              organisations may have by focusing efforts on the bugs most likely
              to be exploited.
              <br />
              <br />
              This same logic can be applied to other areas “The key is to find
              tools, datasets, and statistical methodologies that can help you
              separate the signal from the noise. The right tools will help you
              quantify risk and apply that analysis to prioritise the actions
              that get the most meaningful results.”
              <br />
              <br />
              In summary; automation deployed intelligently can help with cyber
              skills shortage but not eliminate it in its entirety.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Can you state 5 crucial steps for successfully developing
                technical infrastructure that would be more resilient to new
                cyber threats?
              </strong>
            </p>
            <p>
              Several industry articles have provided different ways to ensure
              secure infrastructure, and I will just list 5:
              <br />
              <ol>
                <li>Run a Network Security Audit</li>
                <li>Conduct Security Awareness Training</li>
                <li>
                  Limit User Access Privileges to the Minimum Necessary for Work
                </li>
                <li>Have a mature Patch Management program</li>
                <li>
                  Review security tools to ensure the right tools are deployed
                  to mitigate cyber risks
                </li>
              </ol>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Describe how the face of Cyber Warfare has changed with the
                West African Region in 3 words?
              </strong>
            </p>
            <p>
              This is a tough question, not sure I can manage in 3 words -
              “Ransomware, Phishing, DDOS”.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
