import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Antonios Vourakis is the Chief Digital and Retail Officer at
                Pancreta Bank S A, Greece.
                <br />
                He is a work stream leader for retail banking in the rebranding
                of Commercial Bank of Greece to Emporiki Bank and the
                transformation project of the bank. He successfully launched the
                first home equity loan in the market “Cash4U”.
                <br />
                He was responsible for the Consumer financing and Payments of
                the subsidiaries abroad of Alpha Bank (Albania, Bulgaria,
                Cyprus, Romania, Serbia, FYROM) and the launch of six new
                deposits and loans products in Attica Bank.
                <br />
                He has built, trained, and motivated the staff of bank’s
                branches (53) with a focus on exceeding expectations, delivering
                on all key performance indicators (KPIs) and enhancing customer
                experiences.
                <br />
                MR Antonios Vourakis will be speaking virtually at the Finnovex
                Europe Summit. Please have a look at his view on our upcoming
                Leading Summit on Financial Services Innovation and Excellence
                held Virtually on 24th – 25th May 2021 ( Attend from anywhere
                💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In relation to the potential customer experience data
                protection risk, how does the European regulatory environment
                presents a series of new challenges?
              </strong>
            </p>
            <p>
              Looking back not many years ago, all of us can see the tremendous
              technological leap in areas like the internet infrastructure, on
              line payments, e-commerce, on line search, that presented to all
              of us a new landscape in which we daily interact with. That
              interaction leaves traces, and traces are useful as it leaves
              valuable data. Again, technological development provided the tools
              to exploit that information in better targeting consumers for
              commercial purposes. In my point of view the challenges ahead will
              be the transition from data protection to consumer protection, and
              to consumer empowerment through the monetization of ‘consumer
              data.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How can organizations incorporate regulatory compliance
                without hindering a customer’s experience?
              </strong>
            </p>
            <p>
              Balancing compliance and customer experience does not necessarily
              mean that an organization will have to compromise on either one or
              the other.
              <br />
              I firmly believe that to us as banking executives, and to our
              customers as well, trust is an essential component in building a
              relationship. We at Pancreta Bank, invest in familiarity and trust
              as it relates to our service model because both will remain the
              key currency for us to leverage in order to attract and retain
              customers.
              <br />
              The building blocks in our customer approach to establish trust
              are, transparency, solid advice on products, services, and
              simplification of processes. Compliance can complement the process
              of building trust and, delivered in the right way, can enhance the
              customer experience. The right way is on an advisory perspective
              that explains the reason way and in a seamless process that
              facilitates the customer interaction
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Can you give some examples of innovations that have improved
                the customer experience without clashing with the regulatory
                compliance within the European region?
              </strong>
            </p>
            <p>
              From the early days of KYC adaptation, technology innovation
              allowed banks to streamline existing processes, make onboarding
              and due diligence activities more frictionless, improve
              transaction monitoring, and enhance fraud resolution, and
              potentially create growth opportunities.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- As banking moves increasingly onto mobile devices, how can
                banks’ balance the customer experience with security and
                privacy?
              </strong>
            </p>
            <p>
              It is evident from every region of the world that the physical
              network of a bank decreases rapidly and there are consumers from
              every segment (from baby boomers to millennials) that have never
              visited a branch. Mobile and on-line banking will continue
              offering a secure channel for consumers to interact with their
              bank based on two or more factors authentication, biometrics, etc.
              Security and privacy is a dance for two and also consumers need to
              be actively involved on these issues. For example, banks are
              offering alerts but are consumers setting them up.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Do you think the Open Banking paradigm serves as a balance
                between maintaining regulatory compliance and customer
                experience?
              </strong>
            </p>
            <p>
              Open Banking regulation will facilitate and speed up the
              penetration of new entrants by reducing the barriers to enter into
              some parts of the retail banking value chain. The standardization
              of technologies such as data aggregation, the embedment of
              payments into any customer interaction would enable the customer
              have a better experience handling his finances.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What is your expectation from the Technology solution
                providers/ Vendors in the Financial Industry? What would you
                like to hear from Fintech companies in terms of challenges faced
                by the Banking Industry?
              </strong>
            </p>
            <p>
              As we are facing a new era not for the banking industry only, but
              the society we will have to work together to meet the demands of a
              customer that will be aware of the power he can drive from his own
              data, and will ask from us to provide him with products and
              services that meet his expectations of a quicker, safer, more
              convenient, transparent and credible offering. We can do only this
              in a cooperative way, by working together.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
