import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Dr. Philip Oti-Mensah is currently the Executive Director at
                OmniBSIC Bank. He is a Certified International Change Manager
                and member of IABFM. His work and performance over the last 17
                years has won him many coveted awards.
                <br />
                <br />
                Dr. Oti-Mensah will be speaking live at Finnovex West Africa
                Summit.
                <br />
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 26th –
                27th October 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q-The “New Normal” has led to the adoption of the technological
                way of life, how can new technology be instilled among staff
                members?
              </strong>
            </p>

            <p>
              Good Question. This is about innovation adoption.
              <br />
              <br />
              Technology adoption by the target market has had mixed outcomes.
              New technologies/products are introduced with high expectations
              but actual adoption and use by the target group is generally low.
              For example, the internet penetration rate in Ghana is almost 40%
              but bank clients actually using internet banking is less than 2%
              though the product was introduced more than 20 years ago in Ghana.
              <br />
              <br />
              On the other hand, mobile money which was introduced just about 11
              years ago has a penetration rate of over 60%. The solution lies in
              staff (especially sales force) adoption. Safaricom and MTN
              recognized the three levels of adoption (company, sales force,
              client adoption) and used it to achieve massive penetration. To
              the question itself. Staff adoption can be achieved in the
              following ways:
              <br /> <br />
              1. Proper change management: There must be a conscious effort by
              the companies to achieve mindset change among staff.
              <br />
              2. Internal Piloting and company readiness
              <br />
              3. Regular Training
              <br />
              4. Supervisor support
              <br />
              5. Management posture and support
              <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you highlight some evolving legal and regulatory context
                guiding the West African Fintech Space?
              </strong>
            </p>
            <p>
              1. Promotion and ownership of fintechs: Should fintechs be owned
              by Banks/Telcos or should regulation insist that they are separate
              entities?
              <br />
              2. Electronic Money Issuance: Should Banks be allowed to run EMI
              as departments or should regulation insist that they should be
              separate entities?
              <br />
              3. Pricing: To what extent should regulation drive the pricing of
              mobile financial services? To achieve maximum financial inclusion,
              both affordability from the view point of customers and
              profitability from the view point of service providers are all
              important. <br />
              4. Given that cross border transactions have always been heavily
              regulated with strict KYC and controls (SWIFT, Cards), to what
              extent should such regulation be transferred into the ongoing
              mobile financials services revolution and AfCFTA? Should controls
              be lowered to accommodate African integration?
              <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Are there any systematic principles Fintechs can implement in
                understanding and reaching the unbanked in the West Africa
                region?
              </strong>
            </p>
            <p>
              History and culture of a country must be thoroughly researched and
              understood before product concept development. The strategic
              management process must also be applied (analysis, formulation,
              implementation, monitoring and control).
              <br />
              <br />
              The Unique Selling Proposition (USP) of a “new” product but be
              unambiguous. Cost is therefore important for the unbanked.
              Fintechs should not charge prohibitive prices to exclude the very
              people they want to include. Every product must be easy to
              understand and use (user friendliness). Innovation adoption
              principles must be thoroughly applied. Answer:
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-As strong advocates of fostering partnerships and
                collaborations between Fintechs and Banking Financial
                Institutions, how can collaborations be fostered?
              </strong>
            </p>
            <p>
              Firstly, in going back to the basics the concepts of division of
              labor and specialization must be understood by the players. There
              should also be a willingness to share revenue.
              <br />
              <br />
              Fintechs must also play a role in “selling” to consumers not just
              sit back and collect revenue. A collaborative approach makes the
              idea of revenue sharing more acceptable.
              <br />
              <br />
              Finally, advocacy for Fintechs being the “new” players should in
              support of collaboration. Banks should also be open minded and
              understand the ongoing revolution requires collaboration.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you identify 5 trends that are likely to shape the future
                of Fintech within the West African landscape?
              </strong>
            </p>
            <p>
              1. Internet penetration
              <br />
              2. The price of data
              <br />
              3. USSD penetration
              <br />
              4. Collaboration with Banks, Merchants, Insurance Companies, etc
              <br />
              5. Access to funding
              <br />
              6. Research and Development
              <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. In comparison to industry standards, how is your
                organization measuring its current competency levels?
              </strong>
            </p>
            <p>
              1. Setting SMART goals and objectives and measuring
              output/performance regularly
              <br />
              2. Regular training need analysis and training
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. What methods can BFSI Leaders leverage in reaching the
                unbanked within the West African region effectively?
              </strong>
            </p>
            <p>
              The current client portfolios should be seen and deployed as a
              marketing resource
              <br />
              Cross selling opportunities
              <br />
              Organizational adoption as a prerequisite to customer adoption
              <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.What aspects of the Summit should industry peers be on the
                lookout for as they gear up for the Summit?
              </strong>
            </p>
            <p>
              Different perspectives from different countries
              <br />
              Insights based on extensive research and practical experience
              <br />
              Ideas and solutions to enable young leaders succeed <br />
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
