import React from 'react';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import AboutPage from 'components/AboutPage';

import {} from './content';

import ScrollUpButton from 'react-scroll-up-button';

function About() {
	return (
		<div id="outer-container">
			<Navbar />
			<ScrollUpButton />
			<ProfilePageHeader title="ABOUT US" />
			<AboutPage />
			<Footer />
		</div>
	);
}

export default About;
