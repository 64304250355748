import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col xs={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="align-self-center">
            <p>
              <strong>
                Rohit As the CIO for Bank of Kigali, Rohit’s focus has always
                been centred around the customer experience and simplification,
                both internal and external. He is passionate about new
                technology and how it can help create a positive impact and
                deliver on the ambitions of the bank. At BK we are challenging
                the norms and looking at balancing the rich legacy of the bank
                with innovations using technology.
                <br />
                <br />
                Rohit Dhawan will be speaking virtually at Finnovex East Africa
                Virtual Summit. Please have a look at his view on our upcoming
                The Leading Summit on Financial Services Innovation and
                Excellence to held Virtually on 9-10 February 2021 ( Attend from
                anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In post COVID-19 era, with the emergence and growth of
                FinTech and digitalization of banks what do you think would be
                potential opportunities of the banking industry?
              </strong>
            </p>
            <p>
              Due to COVID there has been an acceleration in the changes and
              digitalization of various verticals in the Financial industry.
              This has created both partnership and competition between the
              banks and Fintech’s. Banks now don’t see FinTechs as threat but an
              enabling organization. An example is that the Fintech’s onboard a
              customer in the lower segment of the value chain much more
              efficiently, and as the customer matures for the need of more
              services and broader financial needs, they switch to banks. While
              banks lose out on the first part of loyalty on the customer
              adoption and volume play, what they gain is the tremendous value
              of an educated and a demanding customer, seeking broader services.
              This need of boarded services and maturity of individuals and
              businesses from Fintech to Banks in a way enforces the need to
              both the segments to collaborate. Due to COVID both banks and
              FinTech’s have now realized that to create the right balance they
              will need to partner and deliver based on the customer demand.
            </p>

            <br />
            <br />
            <p>
              <strong>
                Q- Also, what would be concerns or challenges of the banking
                industry?
              </strong>
            </p>
            <p>
              The biggest challenges that the banks today have is technology and
              regulation. On technology we are competing with the technology
              companies, Fintech’s, services companies, almost anyone who has
              human resource demand. Acquiring and Retaining technology talent
              is difficult and is driving innovation in human capability
              management. This is causing a lot of focus to move away from
              traditional banking practices. The rules of engagement are
              changing across. With the regulators, banks are finding themselves
              having an expectation to reduce costs of digital services and
              channels. While the volumes are growing and changes how banks are
              approaching technology implementations. Regulators are sometimes
              catching up and the dialogue that is taking place sometimes is
              positive and sometimes it is disruptive. The regulators are taking
              a risk free approach and new regulations on banks are being
              amended with a focus on safeguarding the customer and the economy.
              This is forcing banks to have a very cautious approach to
              Innovation, that is a challenge when they are facing competition
              from Fintechs who enjoy a head-start.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What is the role of CIO from Developing policies to
                developing Infrastructure for Digital Transformation in the
                banking sector?
              </strong>
            </p>
            <p>
              Transformation of a bank today is not digital, but the medium
              through which the transformation is preferred to be delivered is
              Digital. As every bank looks at what it is doing and where it
              wants to be, it needs to change the mindset and bring about focus
              on the customer and market demand, with a digital mindset. The
              appetite to automate is increasing and so is the need to speed up
              services. This requires the banks to invest, not on the
              Infrastructure but also on the process excellence. As we review
              and rewrite the process transformed with a focus on the customer
              the policies and governance around the services across the bank
              undergo a rethink. Infrastructure is undergoing a paradigm shift
              with technologies allowing the banks to stop building monoliths
              and move to a much more hypervised environments, be it on premises
              or on cloud. The focus on being available, fast and able to
              analyse data are the new tenants of adopting technology.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- According to you, who leads the way in change and
                transformation -Banks or FinTechs? Who inspires, who follows
                trends and who brings customer needs into perspective?
              </strong>
            </p>

            <p>
              My response to this would be both, they both have customers needs
              at the heart.
            </p>
            <br />
            <p>
              What Fintechs are doing is they are targeting a unique segment
              which has a mass or niche requirement. They are transforming a
              segment of the customers by filling gaps in service areas that are
              deficient or noncompetitive. Banks are targeting a wholistic
              approach. They are providing the bedrock when seen as a larger
              picture. On trends, FinTech’s will always have an advantage as
              neither do they have the legacy or the attachments due to legacy,
              they can experiment. Banks are risk organizations, their appetite
              is lower of risk and hence, they will always remain cautious. But
              off late there are a few areas where banks have broken free of
              this limitation and are innovating. This is partly from the fact
              that banks are using the model of Fintech’s and creating small
              teams that are built to work as entrepreneurs and develop new
              strategies, products and services. Not as fast as a Fintech, but
              there is a definitive movement.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What steps would you take in your organization towards
                building a Cyber Resilient and Digitally Empowered Economy?
              </strong>
            </p>

            <p>
              WWe are already on a journey where we are treating API’s as the
              method to communicate. We invite partners and service providers to
              use our services through API’s. This allows us to provide and
              expose the bank as a service provider for various channels. We
              have a thriving Enterprise Service Bus that integrates the channel
              partners and make it independent for them to consume our modern
              and legacy systems. The partners do not have to explore how a core
              banking or accounting systems work at a bank. They get an API
              which is relevant to their purpose. They get exposed to is modern
              and performant system, which allows them the flexibility to
              develop and rollout services faster. And what banks retain is the
              ability to manage the ecosystem, without the complexity of
              directly exposing it to partners. We are working as an enabler in
              the Economy. We now provide not only banking services to Customers
              and Businesses, but also to companies that are expanding and
              innovating that are in need of transaction processing.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What would you like to say to your industry peers and banking
                professionals who are looking forward to attend this conference?
              </strong>
            </p>

            <p>
              As a banker we are tuned to measure everything on parameters of
              risk. I would urge all to look at the various innovations that are
              taking place and evaluate what works for you. All organizations
              and their needs are different, explore and experiment. Think out
              of the box and challenge the normal.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
