import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function Awards() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div
      style={{
        backgroundImage:
          "url(" +
          require(`assets/images/${
            size ? "awardsbackground.jpg" : "awardsbackgroundmobile.jpg"
          }`) +
          ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
      className="section"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={6} className="align-self-center">
            <img
              src={require(`assets/images/logo/awardslogo.png`)}
              width="90%"
              alt="awards"
            />
          </Col>
          <Col lg={6} className="align-self-center px-3 ">
            <h4 className="text-white text-500-a mt-5">
              Finnovex Awards cherish and celebrate outstanding achievements in
              the banking and finance industry. They are aimed at honouring
              organisations and individuals, that have consistently demonstrated
              exemplary performance. These awards set a standard of excellence
              that is held in high regard.
              <br />
              <br />
            </h4>
            <h3 className="text-white text-700-a ">
              100+ Companies and Individuals recognized across 75+ Categories
              through 4 Editions.
            </h3>
            <Button
              className="mt-5 text-center"
              color="primary"
              size="lg"
              href="/awards"
            >
              View More
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Awards;
