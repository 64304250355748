import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Aieti Kukava is the Founder and CEO of Alliance Group Holding,
                which operates companies in the following industries: Leasing,
                M&A, business information, real estate, renewable energy. Aieti
                Kukava is a managing partner of Alliance Group Capital (AGC).
                Established in 2006, AGC is a boutique investment bank, which
                offers M&A, strategic advisory and capital raising services to
                firms interested in growth opportunities and reaching optimum
                efficiency of operations.
                <br />
                Aieti Kukava is co-founder and chairman of Georgian Venture
                Capital Association. He established business information
                platform - BIA in 2002, the company is market leader for last 18
                years in terms of volume of data, database depth and market
                share.
                <br />
                MR Aieti Kukava will be speaking virtually at Finnovex Europe
                Summit. Please have a look at his view on our upcoming Leading
                Summit on Financial Services Innovation and Excellence held
                Virtually on 24th – 25th May 2021 ( Attend from anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In relation to your current role, how would you describe an
                ideal Agile Bank that is ready for the “New Normal”?
              </strong>
            </p>

            <p>
              “New Normal” in banking industry transforms banks into technology
              companies with financial services. For Agile Banks, two of the
              biggest products are software and data. Accordingly, “New Normal”
              demands different operating model, requalified personnel, and
              adjusted mindset of management team. Competition for number of
              Branches, in certain areas are left in the past. The “New Normal”
              requires transformed banking industry into more digital,
              branchless companies.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Do you think the Open Banking paradigm serves as a balance
                between maintaining regulatory compliance and customer
                experience?
              </strong>
            </p>
            <p>
              Open Banking enables consumers across Europe to give consent to
              banks to share their financial data securely with third parties,
              as it gives them convenience in many ways. Customers can benefit
              getting offered interesting products and services and utilize
              their account by conducting additional services. This is perfectly
              fine as long as consumers are happy.
              <br />
              Current regulations oblige biggest players to share some of the
              information, which numerous intermediaries use to the mutual
              benefit of their clientele as well. Expecting clear benefits these
              consumers are enabling access to their data.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What are the vital elements that organisations need to put in
                place to ensure they are ever-ready for an unpredictable world?
              </strong>
            </p>
            <p>
              Firstly, we have to learn to instill the culture of collaboration
              between people and machines (AI), as tomorrow’s AI is not
              considered a competitive advantage, but integral part of any
              business, products and services.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-TThe world as we know has become more technologically
                advanced, how can Banks cushion the effects of cyber threats
                while leveraging technologies to meet the needs of their
                customers?
              </strong>
            </p>
            <p>
              Modern AI technologies coupled with adequately qualified personnel
              (both in cyber security and AI) in managing AI, will play
              significant role in the prevention of the cyber threats by
              identifying emerging threats on the initial stages. Wide
              assortment of narrowly focused AI systems teamed up with cyber
              security specialists, data scientists, Artificial Intelligence
              Officers etc will increasingly bring benefits and make it hard for
              cyber criminals to threaten corporations.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Within your organisation, can you highlight 3 key steps that
                have been taken to ensure you remain an Agile Bank?
              </strong>
            </p>
            <p>
              For being Agile, every financial institution should strive to
              implement AI strategy. For us it is proper data management
              (classification, standardization, data security, allocation both
              local and remote data centers), new strategy and demands in hiring
              and training personnel (human and machine hybrid activities), and
              AI systems integration strategy across all departments (everything
              from data processing, accounting, analytics and finance, R&D,
              customer service, sales and marketing, etc). Currently, machine
              learning and AI are the key technologies every financial
              institution should be actively contemplating.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.Delegates are certainly getting ready to attend this
                conference, would you like to give any advice to them?
              </strong>
            </p>
            <p>
              Buzzwords like AI, Big Data, Fintech, Blockchain, Open banking,
              Robotic Advisors, Debranching Cybersecurity etc all are worthy to
              be explored in different contexts. Businesses should use the
              opportunities to hear more about them from their peers at
              interesting presentations. Of course, schedule many meetings and
              network more.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What is your expectation from the Technology solution
                providers/ Vendors in the Financial Industry? What would you
                like to hear from Fintech companies in terms of challenges faced
                by the Banking Industry?
              </strong>
            </p>
            <p>
              Technology solutions can assist financial industry to increase
              efficiency of operations, helping to serve more clients with less
              costs, risks and time, with increased convenience and satisfaction
              of consumers.
              <br />
              We would like to hear how Fintechs are identifying new and
              promising segments, with easy to implement and use solutions,
              which accelerate adaptation and smoothen transition to new
              disruptive tech.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
