import React from "react";

import { Container, Row, Col } from "reactstrap";

function Speaker({ speakers }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          // backgroundImage:
          // 	'linear-gradient(to bottom,#000000, #011122,#01162c,#001b37,#001f41,#00234c, #002654, #01285b,#042b63, #042e69,#053070, #063376,#07357d)',
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
          overflow: "hidden"
        }}
        className="section"
      >
        <Container
          fluid
          style={{
            // backgroundImage:
            //   "url(" + require("assets/images/graphic22.png") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <Container className="py-5">
            <Row>
              <Col lg={11}>
                <p className="text-white text-500">
                  The Central Bank of Kenya proposed the new guidelines for
                  cybersecurity standards last year in order to fight against
                  banking frauds and to develop ways to mitigate the new threats
                  that payment service providers are facing. According to the
                  new guidelines, banks and mobile payment operators are
                  required to file cybersecurity reports with the industry
                  regulator. The firms are asked to notify the Central Bank of
                  Kenya within 24 hours of any suspicious activity and also need
                  to submit a quarterly report with CBK on the incidents
                  experienced and how they were resolved. These guidelines on
                  payment services will help in curbing emerging threats in the
                  financial industry.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={11}>
                <h2 className="text-white text-primary text-700 mb-4">
                  SECURING FINANCIAL TRANSACTIONS TO ASSURE INFORMATION SECURITY
                  AND BUSINESS CONTINUITY
                </h2>
              </Col>
            </Row>
            <Row>
              <Col lg={11}>
                <p className="text-white text-500">
                  With this as a driver, Exibex, the team behind Finnovex Global
                  Series, is proud to present the FinnoSec Global Series. The
                  launch edition of this series, FinnoSec Africa, the Financial
                  Infrastructure Security Summit, will be held on the 8th and
                  9th of September at Nairobi, Kenya
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={11}>
                <p className="text-white text-500">
                  <br />
                  Through FinnoSec we aim at securing Africa’s Infrastructure
                  from Cyber Vulnerabilities, where more than 200 delegates from
                  across Africa and partners from across the world will come
                  together to discuss the prevalentsecurity issues faced by
                  banks and FIs, enhance continental cybersecurity strategies
                  and promote ICS cybersecurity-oriented initiatives that will
                  lay a roadmap to safeguard the BFSI Industry against the ever
                  evolving threats.
                </p>
              </Col>
            </Row>
          </Container>

          <Container className="py-5">
            <Row>
              {images.map((id, i) => (
                <Col lg={4} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w500&id=${id}`}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "1Rk2siLeY8Gn_G1zGUZPUtq5K9VPWAg2q",
  "1NdKjY9E68t-OrWu9yd_lw4T9zcCHYsUP",
  "1rXjklxF4K8ymF_2gHYqXnbOguCqBzama",
  "1UwuntBs3tpUzQrVTpGPiCgpKM93XqsXI",
  "1wCtjw_syn10YmQlZ7sNY7lLQQqckijB7",
  "199T2zecFIYZpOSVEy9GhxD2ifMf9dW6o",
  "1PlxL6chxobmxymswpRhDS3DKIzRUnW5K",
  "1IvXwv8LaW7gFNUSffhjACk4wYHHmT4VA",
  "1P10Opwmg3NUAAY4q0QBmK_2qFUY2XaCL"
];
