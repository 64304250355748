import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col xs={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="align-self-center">
            <p>
              <strong>
                Dr. Christian H. C. Ayiku, is currently Board Member/ Executive
                Finance Director at Novacom Atlas Mara. He is a seasoned
                financial management expert with 18 years of extensive
                experience in Strategy development and execution and Business
                Performance Management functions in the banking sector
                <br /><br />
                Dr. Christian H. C. Ayiku will be speaking virtually at Finnovex
                East Africa Virtual Summit. Please have a look at his view on
                our upcoming The Leading Summit on Financial Services Innovation
                and Excellence to held Virtually on 9-10 February 2021 ( Attend
                from anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In post COVID-19 era, with the emergence and growth of
                FinTech and digitalization of banks what do you think would be
                potential opportunities of the banking industry?
              </strong>
            </p>

            <p>
              Partnerships will continue to be key for both Fintechs and the
              Banking industry, however the below opportunities will be of high
              relevance to banks.
            </p>
            <ul>
              <li aria-level="1">Rethinking what drives brand loyalty</li>
              <li aria-level="1">
                Restructuring the addressable market to grow beyond the core
              </li>
              <li aria-level="1">Ability to reconstruct resiliency plans</li>
              <li aria-level="1">
                Ability to validate long-standing business assumptions
              </li>
            </ul>

            <br />
            <br />
            <p>
              <strong>
                Q- Also, what would be concerns or challenges of the banking
                industry?
              </strong>
            </p>

            <p>
              Challenges for most banks will be around being able to supply
              relevant products to customers, in return for Profitability,
              especially as most banks are still not able to harvest relevant
              customer behaviour from their digital delivery channels
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What is the role of CXO (
                <em>depending on speaker designation</em>) from Developing
                policies to developing Infrastructure for Digital Transformation
                in the banking sector?
              </strong>
            </p>

            <p>
              The role of the CXO is that of an innovator, that seeks to adapt
              new customer norms with new business models, resulting in business
              efficiency and excellence.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-According to you, who leads the way in change and
                transformation -Banks or FinTechs? Who inspires, who follows
                trends and who brings customer needs into perspective?
              </strong>
            </p>

            <p>
              {" "}
              It&rsquo;s a chicken and egg situation. Banks deliver products to
              customers but to do this well, they have to harvest customer
              behavior via FinTech platforms assuming banks are leveraging on
              the power of FinTech platforms. So, whereas FinTechs inspire
              opportunity, Banks follow the opportunity, and together, both
              Banks and FinTechs are able to bring customer needs into
              perspective.
            </p>
            <br />
            <br />

            <p>
              <strong>
                Q- What steps would you take in your organization towards
                building a Cyber Resilient and Digitally Empowered Economy?
              </strong>
            </p>

            <p>
              Take stock of best practice from a benchmarked perspective, assess
              where my organization is currently, and develop a roadmap for
              board approval and execution.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- I believe you had a chance to go through our brochure and
                agenda, what are your thought about this conference? Which
                session are you looking forward to attend?
              </strong>
            </p>

            <p>
              {" "}
              It&rsquo;s an excellent conference and, I intend to participate in
              all the sessions.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What would you like to say to your industry peers and banking
                professionals who are looking forward to attend this conference?
              </strong>
            </p>

            <p>
              {" "}
              &nbsp;EXIBEX conferences are best in class and you cannot afford
              to miss it. The network value is immense.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
