import React from 'react';
import { Row, Container, Col, Button } from 'reactstrap';

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white text-center">
      <Container fluid>
        <Row>
          <Col lg={4} className="px-5 pt-5 pb-3">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <a href="/">
                    <img src={require('assets/images/finnovexdark.png')} alt="vibe" width="70%" />
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            lg={4}
            className="text-center mx-auto px-5 py-3"
            style={{
              borderLeft: '1px dotted #fdc300',
              borderRight: '1px dotted #fdc300',
            }}
          >
            <h3 className="text-700">CONTACT US</h3>

            <a href="/" className="mx-auto">
              <img src={require('assets/images/logo/exibex.gif')} alt="vibe" width="50%" />
            </a>

            <h6 className="mx-auto p-0 mt-0">
              Exibex FZ LLE
              <br />
              Office 1309,13th Floor, Creative Tower, Fujairah
              <br />
              United Arab Emirates
              <br />
              +44 20 3290 7867 | +91 9980028128
              <br />
              Email: info@exibex.com
            </h6>
          </Col>
          <Col lg={4} className="px-5 py-3">
            <h3 className="text-700 pb-4">FOLLOW US</h3>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: '#151e2c', color: '#fff' }}
              href="https://www.linkedin.com/showcase/finnovex-middle-east/"
            >
              <i className="fa fa-linkedin" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: '#151e2c', color: '#fff' }}
              href="https://twitter.com/Finnovexlive"
            >
              <i className="fa fa-twitter" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: '#151e2c', color: '#fff' }}
              href="https://www.instagram.com/finnovex/"
            >
              <i className="fa fa-instagram" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: '#151e2c', color: '#fff' }}
              href="https://www.youtube.com/channel/UCfxEoE4GycX1m7YeHdOZQXQ"
            >
              <i className="fa fa-youtube" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon"
              style={{ backgroundColor: '#151e2c', color: '#fff' }}
              href="https://wa.me/971585518437?text=Hi"
            >
              <i className="fa fa-whatsapp" />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            className={'text-center pt-1 m-auto'}
            style={{ borderTop: '1px dotted #fdc300' }}
          >
            <h6>© 2023 Exibex FZ LLE. All Rights Reserved.</h6>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
