import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Stéphane Malrait is Managing Director and Global Head of market
                structure and innovation for Financial Markets at ING Bank.
                Stephane is working with advocacy groups, policy makers and
                regulators to follow market structure and industry challenges
                impacting the financial market industry. He is also leading
                innovation activities in the capital market space within ING and
                developing external partnership with Fintech companies. He has
                been with ING since 2015 till date.
                <br /> Before joining ING, Stephane had worked with Société
                Générale, JPMorgan Chase among other blue chip companies.
                <br />
                Stephane has a strong background in financial markets and
                understands the need for continued technological development to
                drive positive change in trading and market structure workflows.
                He is also a board member for several Fintech initiatives and
                trading associations.
                <br /> MR Stéphane Malrait will be speaking virtually at
                Finnovex Europe Summit. Please have a look at his view on our
                upcoming Leading Summit on Financial Services Innovation and
                Excellence held Virtually on 24th – 25th May 2021 ( Attend from
                anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- Currently, in what key process areas should innovation
                strategies be implemented to meet customer expectations?
              </strong>
            </p>
            Currently, in what key process areas should innovation strategies be
            implemented to meet customer expectations?
            <p>
              Within our bank market activity we see a few processes where
              innovation strategies could be explored and implemented. The main
              areas are using Artificial intelligence models to better
              understand client behaviors, using Natural Language processes to
              structure chat communication services with clients and using
              distributed ledger technologies to be able to create new financial
              products for clients. We also support co-creation of new
              innovation activities with clients to explore and share best
              practices.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- While implementing evolutionary innovation strategies, what
                are the 3 things to note to ensure overall operational
                excellence is maintained?
              </strong>
            </p>
            <p>
              We view innovation strategies as a way to improve operation
              excellence by leveraging new technology solution to strengthen our
              existing processes or be regulatory compliant by design when
              creating new innovation services. By involving the client in the
              design we also improve client relationship and evolve our
              communication toward a more data driven engagement reducing risks
              and improving satisfaction.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- The pandemic caught most of us unaware, during times of
                crisis what are the 3 major points to pay attention to while
                ensuring they meet customer expectations?
              </strong>
            </p>
            <p>
              What was key for us during the pandemic was to be able to continue
              the same level of service to our clients by implementing digital
              solutions for them, we also focused on making sure we had at least
              the same level of communication between the clients and the bank
              relationship managers and we also had to focus on our own internal
              employees to make sure they can continue to work and deliver the
              same level of services to their clients.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Being faced with disruption as a result of the pandemic,
                within your organisation how were you able to respond to the
                changing customer behaviours in a timely manner?
              </strong>
            </p>
            <p>
              Our bank market activity was already digital with some products
              more automated than others. The pandemic accelerated the move
              toward digital channels and got a rapid adoption for our
              organization as most of the systems already supported some digital
              interaction.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-As like minds like you are getting ready to attend the
                Conference, would you like to give any advice to them?
              </strong>
            </p>
            <p>
              What we learned from the pandemic is that it is even more
              important now than before to network, to explore new solutions and
              to learn from industry conference on the key trends. This helps us
              to adapt faster and prepare the design of our future products and
              services.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
