import React from 'react';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';

function WhoShouldAttend() {
	return (
		<div
			style={{
				backgroundColor: '#000',
			}}
			className="section p-5"
			data-parallax={true}
		>
			<Container>
				<Row>
					<Col lg={12}>
						<h3 className="text-white text-500 text-center mb-5">
							FinnoSec will include participants from
						</h3>
					</Col>
				</Row>
				<Row className="justify-content-center">
					{wsaContent1.map((data, index) => (
						<Col lg={4} key={index}>
							<Card className="border border-warning" style={{ backgroundColor: '#fdc300' }}>
								<CardBody>
									<Container>
										<Row>
											<Col lg={12}>
												<h4 className={'ml-auto mr-auto mt-2 text-info text-center'}>
													<b>{data.text}</b>
												</h4>
											</Col>
										</Row>
									</Container>
								</CardBody>
							</Card>
						</Col>
					))}
				</Row>
				<hr />
				<Row>
					<Col lg={12}>
						<h3 className="text-white text-500 text-center mb-5">
							Meet and Network with leaders and Security trailblazers from banking and FI industry
							involved in Financial Infrastructure Security. FinnoSec Attendees include:
						</h3>
					</Col>
				</Row>

				<Row className="justify-content-center">
					{wsaContent.map((data, index) => (
						<Col lg={4} key={index}>
							<Card className="border border-warning" style={{ backgroundColor: '#fdc300' }}>
								<CardBody>
									<Container>
										<Row>
											<Col lg={12}>
												<h4 className={'ml-auto mr-auto mt-2 text-info text-center'}>
													<b>{data.text}</b>
												</h4>
											</Col>
										</Row>
									</Container>
								</CardBody>
							</Card>
						</Col>
					))}
				</Row>
				<Row className="justify-content-center">
					<Button className="px-3 py-3" color="primary" size="lg" href="/register">
						<h5 className="m-0 text-700" style={{ color: '#000' }}>
							Register Now
						</h5>
					</Button>
				</Row>
			</Container>
		</div>
	);
}
export default WhoShouldAttend;

const wsaContent = [
	{ text: 'CISOs, CIOs, COOs, CEOs & CTOs' },
	{ text: 'Heads of IT & Information Security' },
	{ text: 'Heads of Data Management Centre' },
	{ text: 'Heads of Governance, Risk & Compliance' },
	{ text: 'Heads of Digital Banking / Transformation' },
	{ text: 'Heads of IT Support Services' },
	{ text: 'Heads of Network Security' },
	{ text: 'Heads of Fraud and AML' },
	{ text: 'Heads of IT Audit' },
	{ text: 'Heads of Application IT Infrastructure & Operational IT' },
];

const wsaContent1 = [
	{ text: 'Central Banks' },
	{ text: 'Commercial Banks' },
	{ text: 'Investment Banks' },
	{ text: 'Insurance Companies' },
	{ text: 'FinTech’s involved security solutions' },
];
