import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Mr Ndungu Thairu has a track record of efficiently implementing
                new business strategies, delivering innovative solutions, and
                facilitating business operations. He is adept in formulating
                processes for operational efficiency, costs reduction and
                enhance revenues in order to meet the company's fiscal goals and
                objectives.
                <br />
                <br />
                Mr Thairu will be speaking live at Finnovex West Africa Summit.
                <br />
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 26th –
                27th October 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- How would you describe the 3 ways of leveraging the right
                Innovative technologies for organisational success?
              </strong>
            </p>

            <p>
              The first means of leveraging innovative technology is to build a
              strong IT backbone to support the core business of the
              organization. In this ever-changing banking industry, you have to
              first build a strong technological infrastructure by utilizing
              current technologies such as cloud computing etc.
              <br />
              <br />
              After building a strong infrastructural base the next step for the
              business is to use innovative technologies to build products that
              meets the dynamic needs of the customer. This can be achieved
              internally or through partnerships with other institutions. In the
              banking sector these collaborations can be between banks or
              fintech companies.
              <br />
              <br />
              The third way of leveraging innovative technologies is better
              decision making. Innovative Technologies allows the mining of a
              huge array of information. Institutions that leverage on
              technologies such as Big Data have access to accurate and
              fascinating information. Access to precise information contributes
              significantly to making informed business choices. Businesses are
              able to leverage on Big Data to analyse complex data and predict
              future customer actions and behaviour.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What have been the key challenges and benefits to both banks
                and employees within the Banking and Financial Services
                Industry?
              </strong>
            </p>
            <p>
              Some of the challenges faced by Banking and Financial Services
              Industry in their digital transformation journey are impractical
              expectations on the side of some businesses who are overly
              optimistic by focusing just on digitalizing their businesses
              without looking at the transformation side. Like George Westerman
              said, “Digital transformation is less of a digital problem than it
              is a transformation problem”. There is also Lack of Talent,
              Digital Culture and Constant change in the expectations of
              customers.
              <br /> <br />
              Some of the benefit of leveraging digital technologies are speed,
              ability to seamlessly meet the dynamic expectations of the
              customer and innovative technologies that provide the avenues for
              employees to reskill etc.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How can teams, processes and operational structures be
                reformed as we are currently seeing a surge in outsourcing
                talents?
              </strong>
            </p>
            <p>
              Employees should be reskilled and trained both internally and
              externally. Training should be integrated into the daily workflows
              of employees to ensure adaptability.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you highlight some of the implications of balancing human
                expertise with automated processes as the hybrid future
                workforce await?
              </strong>
            </p>
            <p>
              Balancing human expertise with automation will bring process
              effectiveness and efficiency. Humans are generally good at
              handling tasks that uses cognitive skills whiles computers are
              good at handling the more routine tasks. Finding the right balance
              will help the organization to be more prepared for the future.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- How might traditional bank branches be re-purposed post-COVID
                and beyond?
              </strong>
            </p>
            <p>
              The branch has been going through a rapid change even before
              COVID. The new branch may not be for the usual daily banking
              needs. The branches should operate more around advisory and can
              co-locate with other industries like in a supermarket to give the
              customer more products offering. There will be the need for a
              human touch point of some sort in the future, but the purpose will
              change completely.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.Can you identify 3 essential steps to follow in rebuilding an
                organization to keep the pace with the next generation of
                customers?
              </strong>
            </p>
            <p>
              The next generation of customers have dynamic demands.
              <br />
              <br />
              The first step to keep pace with these demands is to make customer
              experience everyone’s responsibility in your organization. Get
              everyone in the company involved.
              <br />
              <br />
              Operationalize customer empathy. Build an organization that
              understands the customer. Understanding invigorate empathy, and
              empathy boost trust. Customers are more likely to be loyal to your
              business when they feel well understood and acknowledged.
              <br />
              <br />
              Establish good lines of communication within the organization.
              Make sure it’s easy for everyone to communicate and stay on the
              same page so that everyone in the organization will speak the same
              language to the customer.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. In leveraging advanced technologies, would you say it has
                promoted talent training?
              </strong>
            </p>
            <p>
              Leveraging advanced technologies like Big Data, AI, Machine
              Learning etc. has led to a shift in how we work. This has called
              for organizations and employees to reskill and adapt to the new
              ways of doing things. Banks are now sourcing new employees who are
              vexed in these new areas and some are also constantly training
              existing staff to utilize the benefits these technologies bring.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.As industry peers are getting ready to attend the conference,
                got any advice for them?
              </strong>
            </p>
            <p>
              My advice for industry leaders is, in as much as we are digitizing
              and integrating advanced technologies into our operations, we
              should not put a blind eye to investing in information security.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
