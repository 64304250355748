import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Speaker Interview Creative Ihab led the digital transformation
                delivery backbone in ADIB for both retail and wholesale
                businesses with focus on transforming ADIB’s capabilities across
                customer facing and fulfillment services. As the first wave of
                transformation was delivered, ADIB has embarked on a journey to
                truly become a data-driven organization, Ihab owns this mandate
                and has recently taken the role of the Chief Analytics Officer
                at Abu Dhabi Islamic Bank. As part of this new role, Ihab plans
                to embed the use of data and analytics in the delivery of the
                business strategy.
                <br />
                <br /> Ihab Ismail will be speaking live at Finnovex Middle East
                Summit. Please have a look at his view on our upcoming Leading
                Summit on Financial Services Innovation and Excellence held from
                7th – 8th September 2021
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As it relates to building a successful Customer Intelligence
                roadmap, what are the vital channels to be leveraged in
                collating and analysing data?
              </strong>
            </p>

            <p>
              Organizations need to consider both structured and unstructured
              data sources resulting from either internal channel (resulting
              from the different customer interactions) or external ones and
              potentially stitched/linked to a customer profile or persona. Top
              interaction channels are website, mobile, self-service, call
              center, branches, chat and social media.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-We are aware that by nature of operation, huge amount of
                customer data flows into your company via numerous channels,
                which include - customer activity on company websites, apps,
                communications initiated by customers etc. What steps can be
                considered in the moulding the building blocks for a successful
                customer intelligence implementation?
              </strong>
            </p>
            <p>
              As a start, creating a well-rounded customer 360 view is
              essential. As part of such view, one needs to consider different
              preferences, tendencies, clusters, demographics & behaviors. The
              organization should then start thinking about how it can apply
              advanced analytics to predict customer needs and expectations;
              this creates a wealth of valuable information that can become
              actionable. However, deciding the best course of action is equally
              a key differentiator to analytically driven organizations in
              today’s world.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- An Analytics Infrastructure is very essential for every
                organisation, what top 3 characteristics must reflect this?
              </strong>
            </p>
            <p>
              Answer: Organizations should have the correct investment appetite
              into building a top analytical infrastructure. Furthermore, the
              use of Cloud computing and building of the correct skill base are
              essential factors to have as part of establishing a strong and
              capable analytics infrastructure
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Regardless of the Security challenges tackled in an open
                banking process, do you think the applications of AI, Machine
                Learning and other Immersive Technologies have aided in
                combating this effectively?
              </strong>
            </p>
            <p>
              The application of Artificial Intelligence (AI) / Machine Learning
              (ML) has the potential to help combat such threats if used at
              different junctions of the customer journeys and across key such
              journeys. AI/ML capabilities can act as strong preventive agents
              over time and help steer human focus on smaller number of
              potential cases.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
