import React from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import Slider from 'react-slick';

function WhoSponsor({ whosponsor }) {
	const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;

	function NextArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				style={{
					...style,
					position: 'absolute',
					top: '50vh',
					right: 10,
					zIndex: 10,
				}}
				onClick={onClick}
			/>
		);
	}

	function PrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				style={{
					...style,
					display: 'block',
					position: 'absolute',
					top: '50vh',
					left: 10,
					zIndex: 10,
				}}
				onClick={onClick}
			/>
		);
	}

	const settings = {
		dots: true,
		autoplay: true,
		arrows: true,
		autoplaySpeed: 8000,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		appendDots: dots => (
			<ul
				className="slick-dots"
				style={{
					display: 'block',
					backgroundColor: 'rgba(255,255,255,0.8)',
					position: 'absolute',
					bottom: 20,
				}}
			>
				{dots}
			</ul>
		),
	};
	let pageHeader = React.createRef();

	return (
		<>
			<div
				style={{
					backgroundColor: '#000',
				}}
				className="section py-0"
				data-parallax={true}
				ref={pageHeader}
			>
				<Container>
					<Row>
						<Col lg={12} className="my-5 text-center ">
							<h3 className="text-center text-primary mt-0 text-700" style={{ color: '#003972' }}>
								The ONLY Summit Dedicated Focus on Financial Infrastructure Security.
							</h3>
							<br />
							{size ? (
								<p className="text-white text-300 mt-0">
									Because we know that each sponsor has a different message, business development goal
									and branding objectives, each sponsorship package is tailored to your corporate
									strategies. Though most sponsorship offers multiple levels and types of exposure,
									there are a few main ways for you to highlight your corporate strengths:
								</p>
							) : (
								<small className="text-white text-300 mt-0">
									Because we know that each sponsor has a different message, business development goal
									and branding objectives, each sponsorship package is tailored to your corporate
									strategies. Though most sponsorship offers multiple levels and types of exposure,
									there are a few main ways for you to highlight your corporate strengths:
								</small>
							)}
						</Col>
					</Row>
				</Container>
			</div>

			<Slider {...settings}>
				{whosponsor.map(w => (
					<Container fluid key={w.id} className="p-0">
						<div
							className="page-header"
							style={{
								backgroundImage:
									'url("https://drive.google.com/thumbnail?authuser=0&sz=w1000&id=' + w.image + '")',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
							}}
						>
							{size ? (
								<Container fluid key={w.id}>
									<Row className={w.location === 'left' ? '' : 'justify-content-end'}>
										<Col
											xs={5}
											className="py-5 px-5 my-auto"
											style={{
												backgroundColor: 'rgba(0,0,0,0.6)',
												height: '100%',
											}}
										>
											<h3
												className="text-primary whosponsor-title text-700 mt-0 text-uppercase"
												style={{ zIndex: 5 }}
											>
												{w.title}
											</h3>
											<br />
											{w.description.map((d, i) => (
												<p className="text-white text-500 mt-0">
													{d}
													<br />
												</p>
											))}
										</Col>
									</Row>
								</Container>
							) : (
								<Container
									fluid
									key={w.id}
									style={{
										position: 'absolute',
										bottom: 0,
									}}
								>
									<Row>
										<Col
											xs={12}
											className="py-5 px-5 my-auto"
											style={{
												backgroundColor: 'rgba(0,0,0,0.4)',
												height: '100%',
											}}
										>
											<p
												className="text-primary text-700 mt-0 text-uppercase"
												style={{ zIndex: 5 }}
											>
												{w.title}
											</p>
											<br />
											{w.description.map((d, i) => (
												<small className="text-white text-500 mt-0" key={i}>
													{d}
													<br />
												</small>
											))}
										</Col>
									</Row>
								</Container>
							)}
						</div>
					</Container>
				))}
			</Slider>
			<div
				style={{
					backgroundColor: '#000',
				}}
				className="section py-0"
				data-parallax={true}
				ref={pageHeader}
			>
				<Container>
					<Row>
						<Col lg={12} className="mt-5 text-center ">
							<Card style={{ borderRadius: '0' }} className="p-5">
								<h3 className="text-center text-primary mt-0 text-700" style={{ color: '#003972' }}>
									DO YOU HAVE A PRODUCT OR SERVICE THAT OUR SENIOR DECISION-MAKERS AND INFLUENCERS
									NEED?
								</h3>
								<br />
								{size ? (
									<p className="text-300 mt-0">
										FInnoSec offers you the perfect platform to showcase your solution to your
										target market and meet and network with senior-level decision makers who are
										leading the way in the industry We specialise in providing business development,
										marketing and sales solutions that are tailored to specifically deliver on your
										business objectives. We pay patient attention to what our exhibition and
										sponsorship customers want, expect, need and value. Every sponsor/Partners wants
										to create customers, develop qualified sales leads, convert leads into sales and
										retain customers. Our tailored sponsorship packages help you to achieve these
										objectives.If you would like more information on sponsorship andexhibition
										opportunities or to discuss which package willbest help you achieve your
										objectives, please contact Shabaz Ahmed on{' '}
										<a href="tel:+971 585518437" className="text-primary">
											+971 585518437
										</a>
										/
										<a href="tel:+91 9980028128" className="text-primary">
											+91 9980028128
										</a>{' '}
										or email{' '}
										<a href="mailto:info@exibex.com" className="text-primary">
											info@exibex.com
										</a>
									</p>
								) : (
									<small className=" text-300 mt-0">
										FInnoSec offers you the perfect platform to showcase your solution to your
										target market and meet and network with senior-level decision makers who are
										leading the way in the industry We specialise in providing business development,
										marketing and sales solutions that are tailored to specifically deliver on your
										business objectives. We pay patient attention to what our exhibition and
										sponsorship customers want, expect, need and value. Every sponsor/Partners wants
										to create customers, develop qualified sales leads, convert leads into sales and
										retain customers. Our tailored sponsorship packages help you to achieve these
										objectives.If you would like more information on sponsorship andexhibition
										opportunities or to discuss which package willbest help you achieve your
										objectives, please contact Shabaz Ahmed on +971 585518437/ +91 9980028128 + or
										email info@exibex.com
									</small>
								)}
								<br />
								<Container>
									<Row className="justify-content-center">
										<Col lg={4}>
											<Button
												href="/sponsors"
												className="btn my-2 text-center px-5"
												color="primary"
												size="lg"
												outline
											>
												Contact Us
											</Button>
										</Col>
									</Row>
								</Container>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default WhoSponsor;
