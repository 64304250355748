import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Munyaradzi Kavhu is the current Chief Operating Officer (COO),
                at Steward Bank which positions itself as a mass bank focused on
                providing banking solutions through the use of technology.
                <br />
                Munyaradzi Kavhu has been with Steward Bank for the past 10
                years and he is passionate about financial service. He also
                possess a vast amount of 16 years’ worth of experience in
                Banking and Financial Markets.
                <br />
                In his previous role where he led the Banking Services and
                Operations segment at the premier Ecobank, his mandate involved
                leading the Banking Services and Operations segment, driving
                business growth opportunities as well as ensuring compliance to
                internal policies and banking regulations.( Attend from anywhere
                💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As we are aware, Banking services and Financial Institutions
                will need to act fast to create a robust framework, to survive
                in the post COVID-19 era. What is your opinion on investing in a
                digital future and developing new technological solutions?
              </strong>
            </p>

            <p>
              The adoption of digital banking services platforms is an
              imperative in the new normal presented by the Covid 19 situation.
              The drive towards cashless, contactless and convenient banking
              solutions is a key strategic consideration.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Would you like to share any personalized experiences within
                your organization that has been improved by use of data and
                advanced analytics?
              </strong>
            </p>
            <p>
              Leveraging on data analytics has enabled the Bank to capture
              critical customer behaviour indicators around their financial
              profiling and spending patterns that fed into effective
              segmentation for tailor-made financial solutions.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Why do you think it is important to build and enforce a
                Cashless Economy where digital lending and online payments would
                be incorporated?
              </strong>
            </p>
            <p>
              A digital cashless economy improves the financial security
              aspects, which are of concern when handling huge sums of cash and
              assist in better dealing with anti-money laundering assessments
              for Banks. Digital financial services come handy in affording
              customers the capacity to handle their financial affairs remotely
              and at their convenience when looking at saving, investment,
              transactional and funding needs.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-We are aware that automation simplifies core-banking
                operations. However, does cyber treats hinder this in anyway?
              </strong>
            </p>
            <p>
              Cyber risks remain an inevitable concern in the provision of
              digital financial innovations. However, this is can be dealt with
              by scoping the level of risk profile inherent in an institution
              and its client pool from which mitigating measures are developed.
              Enhancing transaction security around passcodes and customer
              education is important.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What are the crucial roles of banking and nonbanking
                institutions, financial organizations, investment firms, fintech
                companies, and other stakeholders in developing inducements to
                the paradigm shift within the Southern African Region?
              </strong>
            </p>
            <p>
              The advancing of partnerships, provision of funding support to
              innovators and promotion of innovation hubs is key. Customer
              education and awareness for timely adoption of developed digital
              solutions also come into play. It is a combined responsibility of
              all financial markets stakeholders to promote Fintech innovations
              for the wider benefit of the regional economy.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. In the applications of AI, Machine Learning and other
                Immersive Technologies, can you state 3 limited factors within
                the Southern Africa?
              </strong>
            </p>
            <p>
              Cost considerations around implementation and wider scale
              application of AI and Machine Learning. Potential technology
              resistance due to concerns on machines replacing people. Limited
              creativity as AI solutions are built for predefined rule based
              situations.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Would you like to give any advice to industry peers getting
                ready to attend this conference?
              </strong>
            </p>
            <p>
              In light of the growing pressure for digital interactions between
              service providers and customers, the departure from traditional
              brick and mortar financial services models is a must do. This
              feeds into improved operating costs, better scale and scope
              through platform-based banking leading to enhanced customer
              convenience and efficient business performance.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
