import React from 'react';
import Navbar from 'components/Navbar';
import RegisterForm from 'components/Register';
import Footer from 'components/Footer';
function Register({ title }) {
	return (
		<>
			<Navbar />
			<RegisterForm title={title} />
			<Footer />
		</>
	);
}

export default Register;
