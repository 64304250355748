import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/css/bootstrap.min.css";
// import 'assets/scss/paper-kit.scss';
import "assets/css/paper-kit.min.css";
import "assets/demo/demo.css";
import Home from "views/home.js";
import Speakers from "views/speakers.js";
import Sponsors from "views/sponsors.js";
import About from "views/about.js";
import Delegates from "views/delegates.js";
import Award from "views/award.js";
import Register from "views/register.js";
import News from "views/news.js";
import Newsall from "views/newsall.js";
import DataProvider from "./DataContainer";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route path="/speakers" exact render={(props) => <Speakers {...props} />} />
        <Route path="/sponsors" exact render={(props) => <Sponsors {...props} />} />
        <Route path="/about" exact render={(props) => <About {...props} />} />
        <Route path="/delegates" exact render={(props) => <Delegates {...props} />} />
        <Route path="/awards" exact render={(props) => <Award {...props} />} />
        <Route path="/finnovexlive" exact render={(props) => <Newsall {...props} />} />
        <Route path="/finnovexlive/:id" exact render={(props) => <News {...props} />} />
        <Route path="/delegates/:id" exact render={(props) => <Delegates {...props} />} />
        <Route
          path="/register"
          exact
          render={(props) => <Register {...props} title="REGISTER" />}
        />
        <Route
          path="/request-brochure"
          exact
          render={(props) => <Register title="Request Brochure" {...props} />}
        />

        <Route
          path="/me"
          component={() => {
            window.location.href = "https://me.finnovex.com";
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
