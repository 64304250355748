import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Kerim Alain Bertrand is the Chief Sales Officer (CSO) in
                SmartMessage directing the Global Sales and Marketing teams.
                Prior to joining SmartMessage, Kerim worked for over twenty
                years for various technology companies, from fin-tech to
                mar-tech to prop-tech and now back to mar-tech again. He has
                worked at Doğuş Group, one of the largest Holding companies in
                Turkey as COO and CEO of two new technology investments, and has
                led the Turkish operations of two prominent constituents of the
                FTSE-250 index, Euromoney Plc, and UBM Plc. Kerim holds a
                Master’s degree in International Economics from the University
                Pantheon-Assas, Paris-France.
                <br />
                MR Kerim Alain Bertrand will be speaking virtually at Finnovex
                Europe Summit. Please have a look at his view on our upcoming
                Leading Summit on Financial Services Innovation and Excellence
                held Virtually on 24th – 25th May 2021 ( Attend from anywhere
                💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As a leader within your company, would you like to highlight
                some recent challenges you have faced in the course of aligning
                innovation strategies to meet customer expectations?
              </strong>
            </p>

            <p>
              As we are growing globally and expanding to new geographies, each
              new market has made us discover new potential partners to
              complement our capabilities and new competitors we had never heard
              of even after thorough market research. This is of course a good
              challenge to have, but it pushes us to continuously review our set
              of priorities in our product development roadmap, sometimes
              putting above our list features and capabilities which were not
              seen as priority previously. New markets mean new customers. New
              customers mean new expectations.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Do you think the European Banking industry is leveraging omni
                channel marketing to the fullest level? Any thoughts on how this
                can be better leveraged, while bracing themselves up for future
                disruptions?
              </strong>
            </p>
            <p>
              European Banking has rather embraced Multi-channel rather than
              Omni-channel as Automation and advanced segmentation is yet to be
              widely adopted to speak of a true Omni-channel experience. The
              most important obstacles to this transformation is the aging
              population in which only the younger customers are ready for
              increased digitalization and the second is the large investments
              in digital communication that have been made in the past which are
              now hard to decommission and many large conventional banks need to
              see through the lifespan of their investments before considering
              more sophisticated or advanced tools. The pandemic has in fact
              increased the need to further digitalize and educate the consumers
              as branches have lost their ability to effectively operate.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- We are aware that automation simplifies core banking
                operations. However, does cyberthreats hinder this in anyway?
              </strong>
            </p>
            <p>
              Automation requires the combination and use of customer data
              sparsely distributed in various legacy systems, technologies. This
              could be hindered by cyberthreats unless the solution for complex
              event-triggered automation, such as the one we provide as
              SmartMessage, is placed behind the firewalls and within the highly
              secured environment of the bank.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-In implementing evolutionary innovation strategies, what are
                the 3 major points to pay attention to while ensuring they meet
                customer expectations?
              </strong>
            </p>
            <p>
              1) Does this solution answer a critical and urgent need of the
              customers, will it make customers life easier?
              <br />
              2) Is it cost-effective? Would the benefits exceed the investment
              needed?
              <br />
              3) Does the company have the required resources and capabilities
              in order to implement the innovations in a timely manner to reap
              the rewards and maximise the ROI? When is the “go” too late?
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Being faced with disruption as a result of the pandemic, how
                can an organization constantly implement timely innovation
                strategies to meet customer expectations?
              </strong>
            </p>
            <p>
              The pandemic accelerated the need for transformation. However,
              rather than thinking primarily on what the new expectations/needs
              are today, the innovating company must not forget to look also at
              the horizon and forecast ahead the changes in behaviour and needs.
              The purpose should definitely not be to save the day but to build
              a healthy future.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How can risks be managed effectively within organisations
                using technologies to improve their overall business processes?
                How can the degree of any loss or harm be minimize?
              </strong>
            </p>
            <p>
              Every new technology brings with it benefits and also risks.
              Companies need to carefully mitigate what the benefits are in the
              long term while what could be the risks in the short term. As an
              example, a bank investing significantly in mobile banking may be
              actually excluding a portion of the population not yet onboarded
              or not at the age to adopt mobile banking. What would the bank
              offer to that segment of customers? What would be the new role of
              branches? Various technologies need to coexist in order to help
              the transition and avoid discrimination among customers and
              therefore minimize risks.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-In dealing with customers, can you identify some challenges
                they face across the entire value chain of the financial
                processes?
              </strong>
            </p>
            <p>
              Our customers are banks. We don’t deal directly with the
              end-customers. However, our solutions are there to help Banks
              engage more effectively their customers. What we find is that
              often, even when investing in advanced communication technologies,
              some banks miss the most important rule: KYC or Know Your
              Customer. Customers are getting more and more sophisticated. They
              see every day how ecommerce platforms do to track their behaviour,
              their purchasing habits, their gender etc. Everything banks do,
              every solution they implement, every new innovation they bring,
              they must first check whether it helps them to know better and
              therefore to service their customers better. Regardless of the
              investment size or the long list of technical capabilities they
              have acquired, Banks need to see whether they know their customers
              better.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Your industry peers and banking professionals are certainly
                getting ready to attend Finnovex Europe, got any advice to share
                with them as they prepare?
              </strong>
            </p>
            <p>
              To make the event even more successful, every banking professional
              should join the event with that main question in mind: “What can I
              do better or different, to better know my customers, and better
              service them?” and every industry peer should just listen, listen
              to what the banking professionals seek to accomplish. This should
              not be a platform where technical capabilities are not just listed
              but rather a platform where we answer how real problems of today
              and tomorrow can be solved with real technologies of today.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
