import React from 'react';
import { Container, Button, Row, Col, Card, CardBody, Collapse } from 'reactstrap';

function WhyAttend() {
	const [isOpen, setIsOpen] = React.useState(false);

	const toggle = i => setIsOpen(i);
	return (
		<div
			style={{
				backgroundColor: '#000',
			}}
			className="section p-5"
			data-parallax={true}
		>
			<Container>
				<Row className="justify-content-center">
					{waContent.map((data, index) => (
						<Col lg={6} key={index}>
							<Card>
								<CardBody>
									<Container>
										<Row>
											<Col lg={4} className={' mr-auto ml-auto mt-none text-center'}>
												<img
													src={require(`assets/images/icons/${data.image}.png`)}
													width="70%"
													alt="icon"
												/>
											</Col>
											<Col lg={8}>
												<h5 className={'ml-auto mr-auto mt-2 text-info text-500'}>
													<b>{data.text}</b>
												</h5>
											</Col>

											{index !== isOpen && (
												<Col lg={12} className="text-center">
													<Button
														color="link"
														className="m-auto"
														onClick={() => toggle(index)}
													>
														<i
															className="fa fa-caret-down text-warning"
															style={{ fontSize: '2rem' }}
														/>
													</Button>
												</Col>
											)}

											<Collapse isOpen={index === isOpen}>
												<Col lg={12}>
													<h5
														className={'ml-auto mr-auto mt-2 text-info'}
														style={{ fontSize: '1rem' }}
													>
														<b>{data.description}</b>
													</h5>
												</Col>
												<Col lg={12} className="text-center mt-2">
													<Button
														color="link"
														className="m-auto"
														onClick={() => toggle(false)}
													>
														<i
															className="fa fa-caret-up text-warning"
															style={{ fontSize: '2rem' }}
														/>
													</Button>
												</Col>
											</Collapse>
										</Row>
									</Container>
								</CardBody>
							</Card>
						</Col>
					))}
				</Row>
				<Row className="justify-content-center">
					<Button className="px-3 py-3" color="primary" size="lg" href="/register">
						<h5 className="m-0 text-700" style={{ color: '#000' }}>
							Register Now
						</h5>
					</Button>
				</Row>
			</Container>
		</div>
	);
}
export default WhyAttend;

const waContent = [
	{
		text: 'The latest financial security ideas, actionable insights and networking with peers!',
		description:
			'FinnoSec brings together specialists and experts in banking and financial services security, to discuss, deliberate and share the best practices prevalent in the industry',
		image: 4,
	},
	{
		text: 'Learn from the best in the business.',
		description:
			'Because that’s who you are going to meet at FinnoSec. Witness first-hand, the latest and cutting edge offering and solutions that will be showcased by our partners',
		image: 5,
	},
	{
		text: 'Get ahead of the competition.',
		description:
			'The security landscape is evolving, and your competitors are exploring new ideas and innovations that will crush the status quo. ICT and security leaders have to stay incisive to keep their institution secure and competitive in this fast-changing environment. Join us at Finnosec to learn what’s working, what not and how to transform your organizations strategy for sustained security. ',
		image: 6,
	},
];
