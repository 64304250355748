import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Alvin Alicevic (CRO and COO) is a member of the Management Board
                of Sparkasse Bank (Member of the Styrian Sparkasse and Erste
                Group), North Makedonija AD Skopje.
                <br />
                He is responsible for Risk Management, Organization and IT,
                legal affairs, Banking Operations, Compliance & Anti-money
                Laundering and Assets & Liabilities Management.
                <br />
                He currently serves as the president of the Risk Management
                Committee and IT Committee of the Sparkasse Bank and a member of
                the Supervisory Board of Sparkasse Leasing Skopje.
                <br />
                MR Alvin Alicevic will be speaking virtually at the Finnovex
                Europe Summit. Please have a look at his view on our upcoming
                Leading Summit on Financial Services Innovation and Excellence
                held Virtually on 24th – 25th May 2021 ( Attend from anywhere
                💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In relation to your current role, how would you describe an
                ideal Agile Bank that is ready for the “New Normal”?
              </strong>
            </p>

            <p>
              The “New normal“ has sped up the process of changes within the
              Banking industry, challenging the existing business models. It has
              affected the internal organization, access to clients, products
              and customer experience, operative’s efficiency and decreased
              revenue. An ideal Agile Bank is adaptive and able to respond to
              the challenges, quickly adapt to the busines model and meet
              customers and stakeholders’ expectations.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What are the vital elements that organizations need to put in
                place to ensure they are ever-ready for an unpredictable world?
              </strong>
            </p>
            <p>
              The Banking industry today is a typical VUCA environment. It is
              difficult to predict the behavior of the market and durability of
              existing business model. In order to be ready to operate in this
              environment, the Banks should leverage on technologies, be
              flexible for organizational changes and shape the culture open for
              innovation.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- The world as we know has become more technologically
                advanced, how can Banks cushion the effects of cyber threats
                while leveraging technologies to meet the needs of their
                customers?
              </strong>
            </p>
            <p>
              The world is becoming more technologically advanced in every
              aspects. Although this advancement is reflected in positive
              changes for the customers, it has also opened additional
              possibilities for cyber threats that are often “front runners” in
              utilizing new technology. In order to reduce negative effects,
              Banks should have a balanced approach in investing the resources
              in new technologies to meet the expectation of customers, as well
              as controlling cyber security risk which is becoming more and more
              important.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Within your organization, can you highlight 3 key steps that
                have been taken to ensure you remain an Agile Bank?
              </strong>
            </p>
            <p>
              We have two situations in the market. The FinTechs organizations
              which are founded as Agile Organizations are becoming the Banks,
              and traditional Banks which are competing with FinTechs are trying
              to transform into Agile Banks. For most of the Banks this
              transformation is a Journey that has started but not ended. So
              instead of highlighting 3 key steps to remain as an Agile Bank, I
              will highlight five key elements which should be tackled in this
              transformation process. These elements are: strategy, structure,
              process, people and technology. Missing of any of these elements
              will not lead to successful transformation and later to remain
              Agile Bank.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Delegates are certainly getting ready to attend this
                conference, would you like to give any advice to them?
              </strong>
            </p>
            <p>
              The content of the conference is very interesting and tackle
              current challenges that we are facing on the market. Participants
              should use opportunity to get a different view on the same
              challenges and utilize in their context.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What is your expectation from the Technology solution
                providers/ Vendors in the Financial Industry? What would you
                like to hear from Fintech companies in terms of challenges faced
                by the Banking Industry?
              </strong>
            </p>
            <p>
              My expectation goes directly to constant improvements of
              collaboration between Vendors and Financial Industries in order to
              provide best experience for our clients. For the Fintech
              companies, the challenges of business model in term of the
              profitability would be interested.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
