import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";

function Stats() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          background: "#fff",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <img
                src={require(`assets/images/Finnovex-Qatar-Infographics.png`)}
                width="100%"
                alt="icon"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Stats;

const content = [
  { title: "Editions", value: 9, image: 1 },
  { title: "Speakers ", value: 500, plus: true, image: 2 },
  { title: "Attendees", value: 10000, plus: true, image: 3 },
];

const content2 = [
  { title: "Participating Countries ", value: 50, image: 1 },
  { title: "Participating Companies ", value: 1000, plus: true, image: 2 },
  {
    title: "Awards",
    value: 500,
    plus: true,
    image: 3,
  },
];
