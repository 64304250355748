import React from 'react';
import { Link } from 'react-router-dom';

function ProfilePageHeader({ title }) {
	let pageHeader = React.createRef();
	return (
		<>
			<div
				style={{
					backgroundColor: '#030936',
				}}
				className="section"
				data-parallax={true}
				ref={pageHeader}
			>
				{title ? (
					<>
						<h1 className={'text-center text-white text-700 mt-5'} style={{ zIndex: 10 }}>
							<b>{title}</b>
						</h1>
						<h6 className={'text-center text-white text-700 mt-0'}>
							<Link to="/" className={'text-center text-white text-700 mt-0'}>HOME</Link> / {title}
						</h6>
					</>
				) : (
					''
				)}
			</div>
		</>
	);
}

export default ProfilePageHeader;
