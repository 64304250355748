import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Peter Rossiter is the Chief Risk Officer of Starling Bank’s
                international subsidiary based in Ireland. Peter has extensive
                risk management experience in both traditional and challenger
                bank environments and across several geographies.
                <br />
                These preceding roles include - Citi in UK, Belgium, Turkey,
                Russia and Poland, Group CRO of Allied Irish Bank and as CRO of
                Starling Bank in the UK and Anglo Gulf Trade Bank in Abu Dhabi
                in their start-up phases.
                <br />
                MR Peter Rossiter will be speaking virtually at Finnovex Europe
                Summit. Please have a look at his view on our upcoming Leading
                Summit on Financial Services Innovation and Excellence held
                Virtually on 24th – 25th May 2021 ( Attend from anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In relation to your current role, how would you describe an
                ideal Agile Bank that is ready for the “New Normal”?
              </strong>
            </p>

            <p>
              I’m not sure we can accurately determine what the ‘New Normal’
              will be let alone be fully ready for it. However, I’d describe an
              Agile Bank as one that is ready for the unexpected and can adapt
              quickly.
              <br />
              In practice, this means being able to divert product and
              technology resources to new challenges, in a safe and well
              governed way. For us at Starling Bank, we could very quickly, and
              I mean days rather than weeks or months, address emerging
              requirements in a COVID-19 world such as launching a carer card
              for shielding customers to give to helpers to shop for them, or to
              develop an automated loan origination and fulfilment platform to
              get much needed credit to small businesses under the Bounce Back
              Loan Scheme.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Do you think the Open Banking paradigm serves as a balance
                between maintaining regulatory compliance and customer
                experience?
              </strong>
            </p>
            <p>
              Broadly yes, but it’s still the early days of the Open Banking
              journey and it’s clear regulators would like to see greater
              adoption. As these helpful cases are emerging in facilitating
              credit affordability models, there still remain a few challenges,
              which include - legacy technology challenges, and in some cases
              cultural challenges, to seamlessly offering own and 3rd party
              services on the same platforms.
              <br />
              However, it becomes very interesting when other industries realise
              the potential of open banking solutions to embed financial
              services into their own customer journeys. This can be
              accommodated under Banking as a Service models from digital banks
              such as Starling.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What are the vital elements that organisations need to put in
                place to ensure they are ever-ready for an unpredictable world?
              </strong>
            </p>
            <p>
              I’d consider this under three headings, at least with respect to
              regulated financial services firms. These include - technology,
              governance and purpose.
              <br />
              The technology must be truly agile, meaning you can refocus
              engineering resources to new challenges and build functionality
              incrementally and frequently.
              <br />
              Secondly, governance must both meet regulatory expectations and be
              agile enough to consider new or amended products in a robustly
              controlled way.
              <br />
              Thirdly, whilst the products and services may be adapted to
              changing needs, there should be a strong set of underlying values
              to support the core vision and mission of the firm.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-The world as we know has become more technologically advanced,
                how can Bank’s cushion the effects of cyber threats while
                leveraging technologies to meet the needs of their customers?
              </strong>
            </p>
            <p>
              Cyber threats remain a prevalent risk that must be managed with
              comprehensive preventative and detective controls and robust
              governance that goes well beyond the IT function.
              <br />
              For banks, this goes beyond securing customers’ money and data
              through technology but also actively engaging with and educating
              customers in relation to identifying scams and frauds,
              understanding how their data is being processed; managing their
              data consents and so on.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Within your organisation, can you highlight 3 key steps that
                have been taken to ensure you remain an Agile Bank?
              </strong>
            </p>
            <p>
              Starling is founded on agile principles and if I was to highlight
              3 key steps to maintaining these, they would be keeping as much of
              the technology as possible under your own control, maintaining a
              sense of pace and ensuring very active engagement between the
              various functions that make up the bank.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-.Delegates are certainly getting ready to attend this
                conference, would you like to give any advice to them?
              </strong>
            </p>
            <p>Enjoy it, ask questions and challenge the speakers.</p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
