import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className='py-4'>
          <Col lg={6}>
            <div className='owner'>
              <div className='avatar-big text-center'>
                <img
                  alt=''
                  className='img-no-padding img-responsive img-rounded pt-2'
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className='align-self-center mt-3'>
            <p>
              <strong>
                Indranil Bandyopadhyay is an astute and a performance-driven
                professional with an extensive experience in leading and
                managing Global IT strategy and operations.
                <br />
                <br />
                He is highly skilled in using emerging technologies for solving
                business problems. His expertise lies in gaining insights from
                DATA and using various Machine Learning techniques. He is
                well-versed in leading IT teams spread across multiple
                geographies.
                <br />
                <br />
                Please have a look at his view on data collation, segmentation
                and consumption below!
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className='d-none d-xl-block d-lg-block d-md-block'></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color='neutral'
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className='fa fa-facebook-square float-left'
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color='neutral'
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className='fa fa-linkedin-square float-left'
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color='neutral'
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className='fa fa-twitter-square float-left'
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md='10'>
            <br />
            <br />
            <p>
              <strong>
                Q-What are the vital elements that make your data more
                accessible to provide deeper data insights?
              </strong>
            </p>

            <p>
              Democratizing data and deriving meaningful insights from it should
              be part of any organization’s strategy. There are a few steps to
              this dance.
              <br />
              One: provisioning of data from various sources
              <br />
              Two: ensuring that the data is stored in a meaningful way –
              centralized or decentralized DataMarts.
              <br />
              Three: the consumption piece – a standardized role-based process
              to consume data for analytics purposes. This can be achieved by
              using tools like Power BI that sits on the data repositories and
              makes it easier for the business users in deriving insights. Once
              the data is understood it can be extended to predictive and
              prescriptive analytics.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What measures would you take to break down operational and
                data silos across risk, finance, regulatory, customer support,
                and more?
              </strong>
            </p>
            <p>
              The people in the respective silos need to be compensated on the
              bases of effective organization wide data consumption. In the
              absence of that there will be always silo. From a data strategy
              point of view, one needs to create separate DataMarts for the
              operational silos with the ability to fetch fit for purpose data
              across the DataMarts/Data reservoir.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Can you highlight 5 innovation strategies to apply advanced
                analytics for integrated insights and visibility?
              </strong>
            </p>
            <p>
              I suggest the following considerations to enable a business to
              democratize advanced analytics:
              <ul>
                <li>
                  Data Quality management Strategy -- To define and monitor
                  KPIs, data profiling rules, metrics and monitoring tools
                </li>
                <li>
                  Metadata Strategy -- To implement metadata types, data
                  lineages, business data and dictionary
                </li>
                <li>
                  Data Architecture Strategy -- To define and manage standards
                  for data modelling, data warehousing and ETL tools
                </li>
                <li>
                  Technology architecture strategy -- To define and manage
                  system infrastructure and processes, commodity hardware, and
                  Data platforms
                </li>
                <li>
                  Data Tool strategy – To use tools that can put guard rails and
                  democratise data science by prescribing algorithms and hand
                  hold the analytics journey
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What are your thoughts on a cloud-based data architecture that
                makes your data more accessible? audience?
              </strong>
            </p>
            <p>
              I am supportive of the cloud-based data architecture because of
              the ease of storage, data accessibility and usage of standard APIs
              for advanced analytics. What worries me though is the cost of
              accessing data from the cloud. While it is relatively cheap to
              store data in the cloud, accessing the same (depending on size)
              can get very expensive.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What changes need to be made in embracing a data culture and
                adopting strategies for data collection and access to clean
                data?
              </strong>
            </p>
            <p>
              I will suggest the following:
              <br />
              <ul>
                <li>
                  Improving and leveraging data be an element of an
                  organisation’s strategy
                </li>
                <li>
                  Decision making on available data points – opinion without
                  data points is mostly invaluable
                </li>
                <li>
                  My experience says that the data consumption strategy lags the
                  data provisioning strategy. So, a strong data consumption
                  strategy execution helps detect the gaps in data collection
                  and quality of data.
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What initiatives and measures should the government and
                regulators be taking on developing reliable data on a national
                level?
              </strong>
            </p>
            <p>
              First is the political will to develop a reliable data framework
              <br />
              Second is keeping national interest in mind, the assistance from
              corporates to create this framework
              <br /> Third is the reduction of internet data cost Last but not
              the least putting capable people to lead the data transformation
              journey.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How can an organization standardize and verify actionable data
                that is critical to implement effective strategies?
              </strong>
            </p>
            <p>
              Strategically, effective data hosting and consumption needs to be
              top of mind for any organisation. Tactically, one can only get to
              actionable data by going through the discovery stage of what the
              available data is telling. This is followed by insights and trend
              analysis. Once all of this is done hopefully actionable data
              insights will be obtained. If not, go back to the first step.
              Unfortunately, there is no easy way in getting to actionable data.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
