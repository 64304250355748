import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Agrippa Mugwagwa possesses 28+ years of banking experience,
                taking on challenging roles and providing excellent bespoke
                solutions.
                <br />
                <br />
                His key areas of track record delivery include - Digital Banking
                & Marketing, Financial Technology, eCommerce, Commercial and
                Retail Banking, Strategic Marketing, New Business Development,
                Stakeholder Relationship Management, Asset Finance, Corporate
                Affairs and Change Management
                <br />
                <br />
                Agrippa Mugwagwa will be speaking live at Finnovex West Africa
                Summit.
                <br />
                Please have a look at his view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 26th –
                27th October 2021. Visit{" "}
                <a href="https://wa.finnovex.com">wa.finovex.com</a> for more
                information.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- In your line of duty, would you say data analytics and cloud
                offers the opportunity to synchronise the overall enterprise
                system?
              </strong>
            </p>

            <p>
              Data and analytics does not only enable the business to operate as
              a fully integrated real-time enterprise, but also gears it to
              scale and grow rapidly with agility.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How are the operational and data silos across - risk, finance,
                regulatory and customer support to be broken-down?
              </strong>
            </p>
            <p>
              Agile, integrated and micro-services platforms are key to breaking
              down operational and systems silos that are inefficient, costly
              and poorly aligned for customer value delivery.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How would you describe the creation of connected data sets to
                enable more sophisticated insights and analytics?
              </strong>
            </p>
            <p>
              A 360- or 720-degree view on a customer or issue is not possible
              with disparate data sets. Game-changing insights are possible
              where the data sets are seamlessly connected as this facilitates
              real-time analytics as speed is key to competitive action.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-What changes need to be made in managing transformation around
                digital marketing that inspires engages and captivates the
                audience?
              </strong>
            </p>
            <p>
              Digital marketing democratises audience engagement and creates
              more transparency in brand communication. Culturally the
              organisation needs to make a culture shift that embraces open
              communication and feedback, and digital marketing skills and tools
              enables that to happen cost effectively. If an organisation adopts
              digital marketing but remains uni-directional in philosophy it
              will not reap the benefits of going digital.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-To your Industry peers looking forward to attending the
                summit, what do have to say to them to prepare them for their
                attendance?
              </strong>
            </p>
            <p>
              Finnovex is a vibrant melting pot of thought leadership,
              networking to build future-fit business for our hugely transformed
              world. Finnovex West Africa could not have happened at a more
              opportune time, to prime business leaders for a post-Covid world.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
