import React from "react";

import { Container, Row, Col } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          background: "#fff",
        }}
        className="section"
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-400 text-center text-muted">
                Finnovex Awards 2020 Winners
              </h2>
            </Col>
          </Row>
          <Row className="pt-5">
            {content.map((data) => (
              <Col lg={3} key={data.image} className="mb-2">
                <img
                  alt="..."
                  className="img rounded-0 img-responsive"
                  width="100%"
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w540&id=${data.image}`}
                  style={{
                    borderRight: "10px solid #010322",
                    borderTop: "10px solid #010322",
                  }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  { image: "1xviFcLJib2JtwHZJDy9lrbjxqCJdrgPf" },
  { image: "19u6jsJL38euV6Al54GKUr8nnfuS15oD7" },
  { image: "1TZ03DTFR5PSIYw87KR2jd02irU5jJkRw" },
  { image: "12O_CP_x2-ONFVyTPJimkQtYXmtJQGNWP" },
  { image: "16DDj6ifzrJ5i3EGsDqTKMW85WvcEbOhV" },
  { image: "1oS8gW6vVigoI5bW63ObH4JqrjZ5WVFtr" },
  { image: "1w2CiulCeSgsKl132gOCIqYEhYz3BGnIM" },
  { image: "1iDzI_kZmVARffjIjhWEXPYbKEq6_q4Zp" },
  { image: "198MWozztKPkAuXS2naGDW38QAkG7zsXm" },
  { image: "1Aq9o5V6EfOmmYUnkVPnitAPzYU3VBm0q" },
  { image: "1wz4DrpEOdMWK1eTRNyyg5ez6m7GwwXHJ" },
  { image: "1JbRDGl9vyhs98BSRf3TPYNgmvB-Ye27h" },
  { image: "1yp5gmVgf1pTh7H_DKbJxc_mzcdPMzOEf" },
  { image: "1qEOiRR6eIBgt-i5pZkw8R4sJsrEOxiL0" },
  { image: "1ZUvJS-HoYaRlN1KA8Sy5PppEUGb3BhOJ" },
  { image: "1v7hoLyABxiTuyEQhl_NYbEspRPR0iXOh" },
  { image: "1dYJPR6GBp0_8eQnesD4P_IUKT1j2n_iO" },
  { image: "1ps-V86u15i68aoboK3UhA-Mq4n9nk_to" },
  { image: "1MObOCAoVXhpyvk-mfHyC4Z-arD6D0kGq" },
  { image: "1PHSUJY03ulKgD-1rp-HI8XK9E8gJ6wfs" },
  { image: "1WlmDk6I5xw51BNjEP7UIttn_P_Brv36v" },
  { image: "1SEE0N5l8AOWFvLBYYvMp1k8c_dYV_4YD" },
  { image: "1EtvwsEdYnIW0uQMTdYOvweENPlpPtu1O" },
];
