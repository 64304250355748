import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Mrs Jocelyne Chahwan is the current Deputy CEO- Head of Retail
                Banking at BLOM BANK s.a.l. Lebanon. She started her banking
                career in 1990 at the Bank of Montreal in Montreal.
                <br />
                <br />
                She was awarded the Retail Financial Services Person of the year
                in the Middle East and Africa - 2018 by The Asian Banker.
                <br />
                <br />
                Mrs Chahwan will be speaking live at Finnovex Middle East
                Summit.
                <br />
                <br />
                Please have a look at her view on our upcoming Leading Summit on
                Financial Services Innovation and Excellence held from 7th – 8th
                September 2021.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- What do you suggest should be the main goal for improving the
                overall customer experience?
              </strong>
            </p>

            <p>
              The main goal should be the continuous improvement of all the
              processes that impact the customer journey across all channels.
              The big players in payments such as Apple and Samsung have
              significantly improved the overall customer experience and
              introduced a new concept which is “partnership” instead of
              “competition”. We are also witnessing the same trend with Fintechs
              whereby they are partnering with financial institutions and adding
              a new flavour to the overall customer experience and this is
              resulting in adding the same models and services in a new approach
              that is fast, trendy and convenient. We adopted the partnership
              approach in improving our customer experience journey by
              partnering with the big players such as Visa & Mastercard and
              indirectly with Apple and Samsung; that is in addition to adopting
              the same approach by partnering with Fintechs as this will add
              value to our business model and customer experience journey.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-How can CX Leaders identify the need to upgrade the overall
                customer experience journey needs any form of improvement?
              </strong>
            </p>
            <p>
              One of the most important pillars of our customer experience
              journeys is data. We use data collected such as the adoption rate
              and drop off rate of a specific service in order to study the
              behaviour of our customers and identify areas for improvement. By
              following this strategy, we would be able to understand the
              customers’ perceptions and accordingly develop customer journeys
              that match their expectations. Additionally, it is important to
              always monitor the market trends in order to stay ahead of the
              competition.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What are the 4 most important components necessary for an
                outstanding customer experience journey?
              </strong>
            </p>
            <p>
              When we design our customer journeys, we follow our STEP
              methodology that we have developed and implemented across all new
              products and services to be launched:
              <br />
              <br />I think it is important to separate lending and payments as
              the former is moving at a different rate and is largely driven by
              the same business objective (RoI) than payments which incorporates
              all money movements.
              <br />
              <br />
              <ol>
                <li>
                  Secure – we are in the money business and our customers expect
                  the highest levels of security.
                </li>
                <li>Transparent – to enable trust and encourage usage.</li>
                <li>
                  Easy – our customers are used to being served in a simple
                  manner in our physical branches, we have to replicate this
                  simplicity across all platforms.
                </li>
                <li>Personal – to reflect a more tailor-made solution.</li>
              </ol>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- 4. What should be the systematic principles to implement in
                understanding the way that your customers perceive your
                institution?
              </strong>
            </p>
            <p>
              Previously, customers used to get in contact with the bank through
              limited channels (example: visiting the branch and calling
              customer service centre) then new channels were added such as
              internet banking mobile applications. Today, customers are
              becoming highly demanding and they are relying on social media
              channels in order to get in contact with the bank while also
              expecting to be assisted promptly; adding to all the above, the
              communication strategy related to the branding. That’s why it’s
              important to monitor customers’ feedback on all possible channels
              as this can give significant insights on areas that need to be
              upgraded and improved.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-We are strong advocates of fostering partnerships and
                collaborations between Fintechs and Banking Financial
                Institutions, what are 3 simple ways to leverage in identifying
                technology partners that will help you best meet your customer
                needs?
              </strong>
            </p>
            <p>
              Partnering with Fintechs is one of the important strategies that
              financial institutions should consider to adopt in order to be
              able to meet the ever- changing customer expectations and
              digitalisation trends. That’s why the 3 simple ways to leverage in
              identifying technology partners include choosing a partner that
              can offer:
              <br />
              1. Innovation
              <br />
              2. Fast to market
              <br />
              3. Agile in future enhancements and changes
              <br />
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. In engaging with your customers to acquire feedback and make
                informed product development decisions, what possible approaches
                can CXOs adapt to implement these decisions effectively?
              </strong>
            </p>
            <p>
              The approaches that Chief Experience Officers need to adapt
              include ensuring that customer journeys are being continually and
              properly implemented and improved. Additionally, employees are
              rewarded for their proactive initiatives and teams from different
              departments are participating in the implementation in an agile
              way.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. Customer Experience vs Customer Service – what makes them
                mutually exclusive?
              </strong>
            </p>
            <p>
              Customer Experience and Customer Service are two concepts that
              should not be mixed up and each should be handled deliberately
              because the value and impact that each one brings is different
              than the other. Customer experience is the full journey of
              interactions the customers with the bank and customer service is
              advice and support that is offered. Both should include a customer
              centric approach as they are at the core of doing business and
              delivery of service; however, the resources needed in each concept
              are not the same; that is in addition to the different evaluation
              metrics (KPIs) that are used to identify the areas for
              improvement.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-. What role should employees play in promoting an overall
                improved Customer Experience?
              </strong>
            </p>
            <p>
              Customer service is ensured by offering our products and services
              via competent and enthusiastic employees who are passionate about
              delivering an exceptional experience to the customers. In fact,
              employees have an important role by being proactive in drafting
              the customer experience journey and ensuring its proper
              implementation because everyone comes from a different background;
              and they can help a lot in improving the customer experience by
              providing input on the design, technical and product aspects and
              marketing approach. For instance, when launching a new service, we
              first introduce it to our employees through a soft-launch in order
              to gather feedback from them and get ideas on new initiatives
              before fully launching the service to clients. Employees’ input
              highly helps us prepare for a successful full official launch and
              implementation of an improved customer journey.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
