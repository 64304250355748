import React from 'react';
import { NavItem, Nav, Container, Row, Col } from 'reactstrap';
import Stats from './Stats';
import WhoShouldAttend from './WhoShouldAttend';
import WhyAttend from './WhyAttend';
import { Link } from 'react-router-dom';

function WhoAttend({ match }) {
	const activeTab = match.params.id ? parseInt(match.params.id) : 1;

	return (
		<>
			<div
				style={{
					backgroundColor: '#fff',
				}}
				className="section p-5"
				data-parallax={true}
			>
				<Container>
					<Row>
						<Col xs="12">
							<Nav id="tabs" role="tablist" justified card>
								<NavItem
									className={
										activeTab === 1
											? 'border border-info rounded-bottom bg-info my-auto'
											: 'border border-warning my-auto'
									}
								>
									<a
										className={activeTab === 1 ? 'active text-white' : 'text-dark'}
										href="/delegates"
									>
										<h5 className="p-1 my-4 text-uppercase">
											<b>Who Will You Meet</b>
										</h5>
									</a>
								</NavItem>
								<NavItem
									className={
										activeTab === 2
											? 'border border-info rounded-bottom bg-info'
											: 'border border-warning'
									}
								>
									<Link
										className={activeTab === 2 ? 'active text-white' : 'text-dark'}
										to="/delegates/2"
									>
										<h5 className="p-1 my-4 text-uppercase">
											<b>Who Should Attend</b>
										</h5>
									</Link>
								</NavItem>
								<NavItem
									className={
										activeTab === 3
											? 'border border-info rounded-bottom bg-info'
											: 'border border-warning'
									}
								>
									<Link
										className={activeTab === 3 ? 'active text-white' : 'text-dark'}
										to="/delegates/3"
									>
										<h5 className="p-1 my-4 text-uppercase">
											<b>Why Attend</b>
										</h5>
									</Link>
								</NavItem>
							</Nav>
						</Col>
					</Row>
				</Container>
			</div>
			{activeTab === 1 && <Stats />}
			{activeTab === 2 && <WhoShouldAttend />}
			{activeTab === 3 && <WhyAttend />}
		</>
	);
}
export default WhoAttend;
