import React from 'react';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import AwardPage from 'components/AwardPage';
import AwardWinners from 'components/AwardWinners';

import {} from './content';

import ScrollUpButton from 'react-scroll-up-button';

function About() {
	return (
		<div id="outer-container">
			<Navbar />
			<ScrollUpButton />
			<ProfilePageHeader title="FINNOVEX AWARDS" />
			<AwardPage />
			<AwardWinners />
			<Footer />
		</div>
	);
}

export default About;
