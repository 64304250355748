import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col xs={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="align-self-center">
            <p>
              <strong>
                Chresta is currently CRO for Atlas Mara. He is a seasoned
                financial management and risk management expert with extensive
                experience establishing and managing risk management functions
                in the banking sector having set up enterprise risk management
                frameworks and functions for First Capital Bank where he worked
                for slightly over a year and Cavmont Bank where he worked for
                four years. <br />
                <br />
                Chresta Kaluba will be speaking virtually at Finnovex East
                Africa Virtual Summit. Please have a look at his view on our
                upcoming The Leading Summit on Financial Services Innovation and
                Excellence to held Virtually on 9-10 February 2021 ( Attend from
                anywhere 💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="11">
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                In post COVID-19 era, with the emergence and growth of FinTech
                and digitalization of banks what do you think would be potential
                opportunities of the banking industry?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>

              <em>
                The greatest emerging opportunity is createdby theincrease in
                digital infrastructure and the cultural change around the use of
                technology. This creates an opportunity to scale up banking
                operations, product offerings and delivery channels.
              </em>
            </p>
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                Also, what would be concerns or challenges of the banking
                industry?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>
              The biggest threat is cybercrime. Fraudsters are also seeing the
              proliferationof the use of technology as an opportunity; and
              unlike physical cash, there&rsquo;s no limit to exposures in
              cybercrime.
            </p>
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                What is the role of CXO (depending on speaker designation) from
                Developing policies to developing Infrastructure for Digital
                Transformation in the banking sector?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>
              The biggest role of a CRO is providing risk leadership. Making
              sure the organizationis properly positioned to quickly identify
              opportunities and take advantage of them while also quickly
              identifying threatsand dealing with them. An effective CRO will
              help ensure that there&rsquo;s a balance in approach{" "}
            </p>
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                According to you, who leads the way in change and transformation
                -Banks or FinTechs? Who inspires, who follows trends and who
                brings customer needs into perspective?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>
              In the past, FinTechshave led the way. Banks have been quite slow
              in coming up with new innovations especially from a technological
              point of view. But that has now changed,and we are seeing Banks
              taking more of a lead.
            </p>
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                What steps would you take in your organization towards building
                a Cyber Resilient and Digitally Empowered Economy?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>
              Get the culture right. Make sure that all
              organizationalparticipants have the requisite knowledge and
              information and are sufficiently motivated to protect and grow the
              organization.
            </p>
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                I believe you had a chance to go through our brochure and
                agenda, what are your thought about this conference? Which
                session are you looking forward to attend?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>
              Very interesting conference; and very pertinent discussions for
              the time we are in. I&rsquo;m particularly looking forward to the
              session on Enabling Digital Transformation through Customer
              Centricity
            </p>
            <br />
            <br />
            <p>
              <strong>Q- </strong>
              <strong>
                What would you like to say to your industry peers and banking
                professionals who are looking forward to attend this conference?{" "}
              </strong>
            </p>
            <p>
              <strong>A- </strong>
              Attend with an open mind; an amazing collection of experts has
              been put together and the opportunity is learn from them and
              benefit from their knowledge, expertise and experience is a rare
              opportunity.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
