import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

function news({ news }) {
	const shareUrl = 'www.finnosec.com/finnoseclive/';
	console.log(news);
	return (
		<>
			<Container>
				<Row>
					<Col xs={12}>
						<div className="owner">
							<div className="avatar-big text-center">
								<img
									alt=""
									className="img-no-padding img-responsive img-rounded pt-2"
									src={require(`assets/images/news/main${news.image}`)}
								/>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className={'ml-auto mr-auto my-2'} md="10">
						<h6>
							Source :{' '}
							<a href="https://www.accaglobal.com/my/en/member/member/accounting-business/2019/02/insights/cyber-attacks.html">
								Kayode Yusuf FCCA, finance professional and journalist
							</a>
						</h6>
					</Col>
				</Row>
				<Row>
					<Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
					<FacebookShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
									marginLeft: '15px',
								}}
							>
								<span style={{ color: '#3b5998', display: 'inline' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-facebook-square float-left" />
								</span>
							</Button>
						}
					/>

					<LinkedinShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
								}}
							>
								<span style={{ color: '#0976b4' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-linkedin-square float-left" />
								</span>
							</Button>
						}
					/>
					<TwitterShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
								}}
							>
								<span style={{ color: '#55acee' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-twitter-square float-left" />
								</span>
							</Button>
						}
					/>
				</Row>

				<Row>
					<Col className={'ml-auto mr-auto text-justify'} md="10">
						<br />
						<p>
							In their rush to embrace the digital future, African entities are leaving themselves
							dangerously exposed to cyber attacks. They should join forces to counter the threat
						</p>
						<br />
						<p>
							Cyber attacks on businesses and governments are on the rise the world over, and the scale of
							the problem will only increase as computers and other digital gadgets become more
							widespread. For African businesses, the risk is potentially even higher. Unfortunately, the
							rapid increase in internet penetration and digital connectivity, and the enthusiastic
							embracing of new technologies on the continent, has not been matched by an equivalent
							commitment to cybersecurity.
						</p>
						<br />
						<p>
							A report published in April last year by IT services and consulting firm Serianu estimated
							the loss to African businesses from cyber crime at US$3.5bn, up from US$2bn the previous
							year. Nigeria was the hardest hit with losses of US$649m, followed by Kenya with US$210m and
							Tanzania with US$99m. Meanwhile, more than 95% of public and private organisations across
							the continent spent less than US$1,500 a year on cybersecurity measures, with SMEs in
							particular failing to invest, according to the report.
						</p>
						<br />

						<p>
							Attacks range from simple email scams to large-scale theft of customer data using malware,
							ransom attacks and disinformation or fake news. These can have wide-ranging effects,
							including financial losses, reputational damage, and disruption of business and government
							operations.
						</p>
						<br />

						<p>
							In 2017, for example, several African countries were affected by the global WannaCry
							ransomware attack, which used a ‘crypto worm’ to encrypt data on computers running the
							Windows operating system and demand ransom payments in the Bitcoin cryptocurrency to unlock
							the data. Businesses of varying sizes in Nigeria, Egypt, Angola and South Africa were stung.
						</p>
						<br />

						<p>
							In South Africa last year, Johannesburg-based insurance company Liberty Holdings was the
							victim of a ransom attack. Criminals gained access to the company’s data and demanded
							payment from the firm for its return. Even though Liberty did not make any payment and the
							data was limited to emails, the reputational damage was considerable and the company’s share
							price fell 5% on the heels of the attack.
						</p>
						<br />

						<p>
							Also in South Africa, a website for viewing traffic fines known as ViewFines experienced a
							data breach. Almost a million records containing sensitive personal data such as phone
							numbers and government-issued IDs were exposed. Shockingly, it emerged that passwords were
							stored by the company in plain text rather than being encrypted. Research carried out by the
							Ponemon Institute puts the average cost of a data breach in South Africa in 2018 at R36.5m,
							up from R32m the previous year.
						</p>
						<br />

						<p>
							These incidents are just the tip of the iceberg. According to Serianu’s Africa Cyber
							Security Report 2017, as many as 96% of cybersecurity incidents in Africa go unreported or
							unsolved.
						</p>
						<br />

						<p>
							The vulnerability of African businesses to cyber crime was acknowledged at the Africa Cyber
							Security Conference held in Abidjan, Ivory Coast last October. Michel Bobillier, programme
							director of IBM’s security business unit, pointed out that ‘cyber threats have no more
							borders and data pirates attack anything that moves’. In the same month the African Union
							held its first African Forum on Cybercrime in Ethiopia’s capital Addis Ababa. The forum
							brought together governments and regional and international organisations to discuss
							national policies with a view to international cooperation.
						</p>
						<br />

						<h3>
							<b>Mounting concern</b>
						</h3>

						<p>
							So what should businesses be doing to tackle this problem? The big players, particularly in
							the banking sector, are leading the way, aligning cybersecurity objectives with their
							strategic goals by investing in innovative new technologies that can help protect data
							integrity and address security threats. For example, Ecobank, a leading pan-African bank
							with operations in 36 countries across the continent, redesigned its network in 2017, using
							NASDAQ-quoted cybersecurity solutions provider Radware to help protect its infrastructure
							from cyber attacks.
						</p>
						<br />

						<p>
							Other businesses are strengthening their cyber risk protection systems by investing in
							technology that can identify vulnerabilities in their IT infrastructure before cyber
							criminals strike. This risk-based approach protects information assets in advance,
							preventing damage to company reputation, customer confidence and the economy at large.
						</p>
						<br />

						<p>
							Investment in surveillance technologies is a start, but cybersecurity plans should also
							include a structured process to manage cyber crises. African businesses need to collaborate
							to exchange information on cyber threats to enable them to respond faster and more
							effectively to incidents.
						</p>
						<br />

						<p>
							These measures require professionals who specialise in this field but such experts are in
							short supply. Speaking at the Cloud and Security Summit 2018 in the Rwandan capital Kigali,
							Innocent Muhizi, CEO of Rwanda Information Society Authority (RISA), said: ‘African
							economies are not yet ready to avert cyber threats due to a lack of adequate expertise to
							contain these attacks.’
						</p>
						<br />

						<p>
							This view is backed up by the Serianu report, which says there are just 10,000 certified
							cybersecurity experts across the continent. To reverse this shortage, businesses must
							develop skills-building and retention strategies to attract and keep professionals who can
							help implement their cyber strategy. Governments also need to play a part by developing
							initiatives to reverse the brain drain that has led to the exodus of many talented
							cybersecurity experts to Europe and North America.
						</p>
						<br />

						<p>
							African governments can do more to combat this emerging threat. The continent lags behind
							other parts of the world in the area of regulation and legislation related to information
							security and data protection. The African Union adopted a Convention on Cyber Security and
							Personal Data Protection in 2014, but to date only 11 of the 55 member states have signed
							it. Meanwhile, a United Nations Conference on Trade and Development survey of cybercrime
							legislation reported that of 54 African countries studied, 28 have legislation and 11 draft
							legislation, while 15, including Mozambique, Mali, Sierra Leone and Chad, have no
							legislation.
						</p>
						<br />

						<h3>
							<b>Raising awareness</b>
						</h3>

						<p>
							African businesses must integrate cybersecurity into their long-term strategies and invest
							in modern surveillance technologies to detect and rapidly respond to security breaches.
							However, they also need to do more to upskill personnel across all levels in the area of
							information security management. According to Baidy Sy, digital transformation and
							cybersecurity lead at Finetech Group: ‘African countries should invest more in raising
							awareness and training end users who are as always the weakest link in the chain.’
						</p>
						<br />
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default news;
