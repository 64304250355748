import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              <strong>
                Dr. George Mulamula is on the board of NMB Bank Plc and Demeter
                Group and Member of Ict Think Tank and Member of Financial
                Sector Deepening Trust. He previously held the position of
                Deputy Chief Executive Officer at Rwanda Development Board. He
                received a doctorate and a graduate degree from New York
                University.
                <br />
                <br />
                Dr. George Mulamula will be speaking virtually at Finnovex
                Europe Summit. Please have a look at his view on our upcoming
                Leading Summit on Financial Services Innovation and Excellence
                held Virtually on 24th – 25th May 2021 ( Attend from anywhere
                💻🖥📱 )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As a leader within your company, would you like to highlight
                some recent challenges you have faced in the course of aligning
                innovation strategies to meet customer expectations?
              </strong>
            </p>

            <p>
              Mainly trying to make the different business units of the bank not
              to work vertically in silos but horizontally and in a
              collaborative manner to innovate and scale up products for our
              customers.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- In implementing evolutionary innovation strategies, what are
                the 3 major points to pay attention to while ensuring they meet
                customer expectations?
              </strong>
            </p>
            <p>
              <ul>
                <li>
                  Innovative products go to solve pain points of customers
                </li>
                <li>
                  Innovative strategies should align to medium and long-term
                  plans of bank
                </li>
                <li>
                  Repetitive education be given to customers on bank products
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Being faced with disruption as a result of the pandemic, how
                is your organisation constantly implementing timely innovation
                strategies to meet customer expectations?
              </strong>
            </p>
            <p>
              Innovation strategies are being developed in a modular fashion
              with teams working on-line from home and physically in shifts
              within the office.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-As we are aware, Banking services and Financial Institutions
                will need to act fast to create a robust framework, to survive
                in the post COVID-19 era. What is your opinion on investing in a
                digital future and developing new technological solutions?
              </strong>
            </p>
            <p>
              The Post COVID-19 area will require digital tools and some work to
              be done on-line. So investment in developing technological
              solutions will have to be done, though in Tanzania this started
              even before the COVID-19 pandemic, as we have to reach the
              unbanked people in the rural areas.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
