import React from 'react';

import { Container, Row, Col, Card, Button } from 'reactstrap';

function SpeakerCard({ speaker, index, setOverlay, overlay }) {
	const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? '21rem' : '21rem';
	return (
		<Card
			style={{
				maxWidth: 'none',
				minHeight: size,
				maxHeight: size,
			}}
			onMouseEnter={() => setOverlay([speaker, index, true])}
			onMouseLeave={() => setOverlay([speaker, index, false])}
		>
			<div style={{ position: 'absolute', top: '5px', right: '-45px' }}>
				<Button
					className="btn-neutral btn-just-icon mr-5"
					style={{ backgroundColor: '#fdc300', color: '#000' }}
					href={speaker.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fa fa-linkedin" />
				</Button>
			</div>
			<div
				className="overlay-speaker"
				style={{
					display: overlay[1] === index && overlay[2] ? 'none' : 'block',
				}}
			/>
			<Container fluid className="p-0">
				<img
					alt="..."
					className="img-rounded img-responsive"
					width="100%"
					src={require(`assets/images/speakers/${speaker.image}`)}
				/>
			</Container>
			<Container
				fluid
				style={{
					borderRadius: '0 0 10px 10px',
					background: '#fff',
					zIndex: 10,
					minHeight: '100px',
					position: 'absolute',
					bottom: 0,
				}}
			>
				<Row>
					{speaker.companyImage ? (
						<>
							<Col xs={4} className="p-0 pl-2">
								<img
									alt="..."
									className="img-circle border border-warning img-no-padding img-responsive"
									src={require(`assets/images/speakers/${speaker.companyImage}`)}
									width="80rem"
									style={{
										marginTop: '-40px',
									}}
								/>
							</Col>
							<Col xs={8} className="mt-2">
								<h6 className="d-inline" style={{ fontSize: '0.9rem' }}>
									{speaker.name}
								</h6>
							</Col>
						</>
					) : (
						<Col xs={12} className="mt-2 text-center">
							<h6 className="d-inline" style={{ fontSize: '0.9rem' }}>
								{speaker.name}
							</h6>
						</Col>
					)}
				</Row>
				<Row>
					<Col lg={12} className="p-0">
						<h6 className="m-2 text-center">
							{speaker.title}
							<br />
							{speaker.company && (
								<span
									className="p-1"
									style={{ borderRadius: '0 0 5px 5px', backgroundColor: '#fdc300' }}
								>
									{speaker.company}
								</span>
							)}
						</h6>
					</Col>
				</Row>
			</Container>
		</Card>
	);
}

function Speaker({ speakers }) {
	const [overlay, setOverlay] = React.useState([speakers[0], 0, false]);
	return (
		<>
			<div
				style={{
					backgroundColor: '#000',
					overflow: 'hidden',
				}}
				className="section"
			>
				<Container
					fluid
					// style={{
					// 	backgroundImage: 'url(' + require('assets/images/graphic22.png') + ')',
					// 	backgroundRepeat: 'no-repeat',
					// 	backgroundSize: 'cover',
					// }}
				>
					<Container>
						<Row>
							<Col lg={12} className={'mx-auto'}>
								<Container>
									<Row>
										<Col lg={12}>
											<Container fluid>
												<Row className="justify-content-center">
													{speakers.map((s, i) => (
														<Col lg={3} sm={6} xs={12} key={i} className="">
															<SpeakerCard
																speaker={s}
																index={i}
																overlay={overlay}
																setOverlay={setOverlay}
															/>
														</Col>
													))}
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Container>
			</div>
		</>
	);
}

export default Speaker;
