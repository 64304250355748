import React from 'react';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import WhySponsor from 'components/WhySponsor';
import Sponsor from 'components/SponsorAll';

import { whosponsor, sponsorsPage } from './content';

import ScrollUpButton from 'react-scroll-up-button';

function Sponsors() {
	return (
		<div id="outer-container">
			<Navbar />
			<ScrollUpButton />
			<ProfilePageHeader title="SPONSORS AND PARTNERS" />
			<WhySponsor whosponsor={whosponsor} />
			<Sponsor sponsors={sponsorsPage} title={'PAST SPONSORS & PARTNERS'} />
			<Footer />
		</div>
	);
}

export default Sponsors;
