import React from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function LiveCard({ news, overlay, setOverlay }) {
  const size = "27rem";
  return (
    <Link to={`finnovexlive/${news.link}/`}>
      <Card
        style={{
          maxWidth: "none",
          minHeight: size,
          maxHeight: size,
        }}
        onMouseEnter={() => setOverlay([news, true])}
        onMouseLeave={() => setOverlay([news, false])}
      >
        <div
          className="overlay-speaker"
          style={{
            display: overlay[0].id === news.id && overlay[1] ? "none" : "block",
          }}
        />
        <Container fluid className="p-0">
          <img
            alt="..."
            className="img-rounded img-responsive"
            width="100%"
            src={require(`assets/images/news/${news.image}`)}
          />
        </Container>
        <Container
          fluid
          style={{
            borderRadius: "0 0 10px 10px",
            background: "#fff",
            zIndex: 10,
            minHeight: "100px",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Row>
            <>
              <Col xs={12} className="mt-2 text-center">
                <h5 className="d-inline text-500">{news.description}</h5>
              </Col>
            </>
          </Row>
          <Row>
            <Col lg={12} className="p-0">
              <p
                className="m-2 text-center text-muted"
                style={{ textTransform: "none", lineHeight: "20px" }}
              >
                {news.content}...
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
    </Link>
  );
}

function Live({ live }) {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  const [overlay, setOverlay] = React.useState([live[0], false]);
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",

        backgroundImage:
          "linear-gradient(to bottom,#000000, #011122,#01162c,#001b37,#001f41,#00234c, #002654, #01285b,#042b63, #042e69,#053070, #063376,#07357d)",
        zIndex: 5,
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h1 className="text-center text-warning text-bold mb-0 pb-0 text-uppercase">
              <b>#FINNOVEXLIVE</b>
            </h1>
            <h5
              className="text-center text-white pb-5 text-uppercase"
              style={{ marginTop: 0 }}
            >
              <b>LATEST NEWS & ARTICLES</b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {size ? (
            live.map((l, i) => (
              <Col lg={4} key={i}>
                <LiveCard news={l} overlay={overlay} setOverlay={setOverlay} />
              </Col>
            ))
          ) : (
            <Col sm={8} xs={12} className={"ml-auto mr-auto"}>
              <Slider {...settings}>
                {live.map((l) => (
                  <LiveCard
                    news={l}
                    key={l.id}
                    overlay={overlay}
                    setOverlay={setOverlay}
                  />
                ))}
              </Slider>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12} className="text-center">
            <Button
              href="/finnovexlive"
              className="btn my-2 text-center px-5"
              color="info"
              size="lg"
            >
              View All
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Live;
